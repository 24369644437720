import React, { useCallback, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import tasksSelectJson from 'config/therapies/tasks-select.json';
import { getTasksOfATherapy, getReasonsByStatus, fetchGpis } from 'services/utils/therapy-service';
import { convertMapToList } from 'reducers/helper-reducer';
import { useTypedSelector } from 'hooks/use-typed-selector';
import {
  getPendingTasks,
  getStatusNameByStatusId,
  getTaskName,
  getTaskNameLine2,
  getNeedsByDateOfTask,
  addTaskOldValues,
} from 'services/utils/task-service';
import { INT, FC, CSL } from 'constants/index';
import { Typography, Tooltip, Grid, Divider } from '@mui/material';
import { convertToArborDate } from 'models/time/arbor-date';
import { Info as InfoIcon } from '@mui/icons-material';
import { renderDatePicker as RenderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  renderDropdown as RenderDropdown,
  renderTextField as RenderTextField,
} from 'components/form/field/redux-field';
import AutoCompleteMiniGrid from 'components/form/field/render-minigrid-autocomplete';
import { IDrugs } from 'models/application-manager/cpmp/IGetGpiProtocolsResponse';
import { CircleIndicatorIcon } from 'components/icons/icons';
import { debounce } from 'lodash';
import { copiedTherapyUpdate } from 'actions/action-therapies';
import moment from 'moment-timezone';
import { SpecialtyDrug, DispensingStatusValue, ClinicalSupportStatusValue } from 'constants/enums';
import { editTasks, fetchTask, addTaskToState } from 'actions/action-tasks';
import ModalForm from '../../../components/dynamic-form/modal-form';
import { defaultTheme as theme } from '../../../lib/themes/index';
import { MedSyncTooltipIcon } from '../med-sync/components/medSyncIcon';
import { useMedSyncIconState } from '../med-sync/med-sync-state';
import { ITask } from '../../../interfaces/redux/ITasks';
import { logger } from '../../../winston-logger';
import HTTP from '../../../services/http';
import { notifyError, notifySuccess } from '../../../actions/action-notifications';

const copyTherapyInfoMessage = `This feature is meant for dose titrations or other
situations where the GPI10 is equivalent. Arbor will automatically discontinue the
original therapy card for the reason the user selects. Keeping a task checked below
will migrate the task chains (expiration dates, due dates, etc) to the new therapy.
FC and CSL (if they exist), will automatically move back to FC#1 and initial CSL (and
thus are greyed out).`;

interface ICopyTherapyProps {
  therapyIds: number[];
  filterTasks: string[] | undefined;
  onClose: () => void;
  classes: any;
}

const CopyTherapy: React.FC<any> = (props: any): JSX.Element => {
  const {
    therapyIds,
    filterTasks,
    taskStatuses,
    allStatuses,
    fillCycles,
    therapy,
    medication,
    patient,
    listOfTasks,
    classes,
    onClose,
  } = props;

  const administrationStatus = 'Discontinued';
  const requiredErrorMsg = 'Required';
  const DEBOUNCE_TIME = 500;
  const therapyErrorMessage = useRef('');
  const errorMessage = 'Select an equivalent or create a new therapy.';
  const dispatch = useDispatch();

  const MedSyncIcon = () => <MedSyncTooltipIcon {...useMedSyncIconState()} size="lg" />;
  const inMedSync = Boolean(therapy.in_med_sync);
  const anchorDate = convertToArborDate(patient.anchor_date, true).getUtcDate() ?? '';
  let nextSyncDate = '';
  if (anchorDate) {
    const tempDate = new Date(anchorDate);
    nextSyncDate =
      convertToArborDate(
        new Date(tempDate.setUTCDate(tempDate.getUTCDate() + patient.sync_time_frame ?? 0)),
        true,
      ).getUtcDate() ?? '';
  }

  const [open, setOpen] = React.useState<boolean>(true);
  const tasks = useTypedSelector(state => state.tasks.data);
  const therapyStatuses = useTypedSelector(state => state.therapyStatuses);
  const [discontinuedDate, setDiscontinuedDate] = React.useState<Date | null>(null);
  const [selectedReason, setSelectedReason] = React.useState<string>('');
  const [drug, setDrug] = React.useState<IDrugs | null>(null);
  const [displayErrors, setDisplayErrors] = React.useState<boolean>(false);

  const taskList = convertMapToList(tasks);
  const { therapyAdministrationStatuses } = therapyStatuses;
  const reasonDropdown = getReasonsByStatus(therapyAdministrationStatuses, administrationStatus);
  const [otherReason, setOtherReason] = React.useState<string | null>(null);
  const [displayOtherReason, setDisplayOtherReason] = React.useState<boolean>(false);

  const filtered = therapyIds.reduce((acc: any[], therapyId: number) => {
    const therapyTasks = getPendingTasks(
      getTasksOfATherapy(taskList, therapyId, true).concat(
        taskList.filter(
          t => t.taskType && t.taskType === INT && t.therapy_id === null && t.therapy_ids === null,
        ),
      ),
    ).filter((task: any) => !filterTasks || filterTasks.includes(task.taskType));
    Array.prototype.push.apply(acc, therapyTasks);
    return acc;
  }, []);

  const handleFetchGpisOptions = (searchText: string) =>
    // eslint-disable-next-line no-confusing-arrow
    fetchGpis(searchText).then((result: any) =>
      result.data
        ? result.data.drugs
            .filter((t: any) => t.gpi.slice(0, 10) === therapy.gpi.slice(0, 10))
            .map((therapy: any) => ({
              ...therapy,
              id: therapy.ndc,
              label: therapy.drug_info,
            }))
        : [],
    );

  const handleOnDrugChange = (value: IDrugs) => {
    setDrug(value);
    if (Object.keys(value).length && value.gpi.slice(0, 10) !== therapy.gpi.slice(0, 10)) {
      therapyErrorMessage.current = errorMessage;
    } else if (therapyErrorMessage.current) {
      therapyErrorMessage.current = '';
    }
  };

  const headers = [
    {
      label: 'Task',
      id: 'task',
      getValue: (task: ITask) => (
        <>
          <Typography variant="body2">{getTaskName(task)}</Typography>
          <Typography variant="body2">{getTaskNameLine2(task, allStatuses)}</Typography>
        </>
      ),
    },
    {
      label: 'Status',
      id: 'status',
      getValue: (task: ITask) => getStatusNameByStatusId(task.status_id, taskStatuses),
    },
    {
      label: 'NBD',
      id: 'nbd',
      getValue: (task: ITask) => getNeedsByDateOfTask(fillCycles, task),
    },
    {
      label: 'Follow Up Date',
      id: 'followup',
      getValue: (task: ITask) => {
        const date = task.followup_dt;
        return date ? convertToArborDate(task.followup_dt, true).getCustomerDate(true) : '-';
      },
    },
  ];

  const renderInfoIcon = (): JSX.Element => {
    return (
      <Tooltip
        title={
          <Typography className={classes.infoIconToolTip}>{copyTherapyInfoMessage}</Typography>
        }
        placement="bottom-start"
      >
        <InfoIcon className={classes.titleIconStyle} />
      </Tooltip>
    );
  };

  const reasonInput = {
    onChange: (v: string) => {
      setSelectedReason(v);
      if (v === 'Other') {
        setDisplayOtherReason(true);
      } else if (displayOtherReason) {
        setDisplayOtherReason(false);
      }
    },
    value: selectedReason,
  };

  const handleOtherReasonInput = useCallback(
    debounce((e: any) => {
      setOtherReason(e.target.value.trim().length ? e.target.value.trim() : null);
    }, DEBOUNCE_TIME),
    [],
  );

  const otherReasonInput = {
    onChange: (v: any) => {
      handleOtherReasonInput(v);
    },
  };

  const renderInstructions = (): JSX.Element => {
    return (
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <RenderTextField
            width="90%"
            label="Administration Status"
            disabled
            input={{
              value: 'Discontinued',
            }}
            meta={{}}
          />
        </Grid>
        <Grid item xs={3} className={classes.administrationField}>
          <RenderDatePicker
            label="Discontinued Date *"
            input={{
              value: discontinuedDate,
              onChange: (value: any) => {
                if (value) {
                  setDiscontinuedDate(value);
                }
              },
            }}
            name="discontinued_date"
            id="discontinued_date"
            meta={{
              touched: !!(displayErrors && discontinuedDate === null),
              error: requiredErrorMsg,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <RenderDropdown
            className
            label="Reason *"
            name="reason"
            input={reasonInput}
            fields={reasonDropdown}
            meta={{ touched: !!(displayErrors && selectedReason === ''), error: requiredErrorMsg }}
          />
        </Grid>
        {displayOtherReason && (
          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <RenderTextField
              width="100%"
              label="Other Reason *"
              name="otherReason"
              input={otherReasonInput}
              meta={{
                touched: !!(displayErrors && otherReason === null && selectedReason === 'Other'),
                error: requiredErrorMsg,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ marginTop: '15px' }}>
          <AutoCompleteMiniGrid
            name="new_therapy"
            label="New Therapy"
            labelField="medicationType"
            hint="Search by Therapy or GPI or NDC"
            fetchOptions={handleFetchGpisOptions}
            columnsToShow={{
              gpi: 'GPI',
              drug_name: 'Drug Name',
              dose: 'Dose',
              specialty_type: 'Specialty Type',
              ndc: 'NDC',
            }}
            input={{
              onChange: handleOnDrugChange,
              value: drug?.medicationType ? { medicationType: drug.medicationType } : '',
            }}
            onChange={handleOnDrugChange}
            meta={{ touched: !!(displayErrors && drug === null), error: requiredErrorMsg }}
          />
        </Grid>
        {therapyErrorMessage.current && (
          <Typography className={classes.therapyErrorMsg}>{therapyErrorMessage.current}</Typography>
        )}
        <Grid item container xs={12} className={classes.infoSection}>
          <Grid container xs={12} className={classes.dispensingSection}>
            <Grid item xs={4}>
              <Typography className={classes.dispensingSectionText}>
                Administration:
                <Typography component="span" className={classes.dispensingSectionValues}>
                  <CircleIndicatorIcon
                    size={20}
                    color={
                      (theme.palette.map.therapyAdministration as any)[
                        therapy.administration_status
                      ]
                    }
                  />
                  <Typography component="span" className={classes.dispensingSectionValues}>
                    {therapy.administration_status}
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.dispensingSectionText}>
                Dispensing:
                <Typography component="span" className={classes.dispensingSectionValues}>
                  <CircleIndicatorIcon
                    size={20}
                    color={(theme.palette.map.therapyEnrollment as any)[therapy.dispensing_status]}
                  />
                  <Typography component="span" className={classes.dispensingSectionValues}>
                    {therapy.dispensing_status}
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.dispensingSectionText}>
                Clinical Support:
                <Typography component="span" className={classes.dispensingSectionValues}>
                  <CircleIndicatorIcon
                    size={20}
                    color={
                      (theme.palette.map.therapyEnrollment as any)[therapy.clinical_support_status]
                    }
                  />
                  <Typography component="span" className={classes.dispensingSectionValues}>
                    {therapy.clinical_support_status}
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.copyTherapyDivider} />
            </Grid>
            <Grid item xs="auto" direction="row" columnSpacing={1}>
              <Grid item xs="auto" alignItems="flex-end" className={classes.medSyncIconPadding}>
                <MedSyncIcon />
              </Grid>
            </Grid>
            <Grid item xs={11} className={classes.medSyncDateWrapper}>
              <Typography component="span" className={classes.dispensingSectionText}>
                Anchor Date:
                <Typography component="span" className={classes.dispensingSectionValues}>
                  {inMedSync ? anchorDate : ''}
                </Typography>
                <Typography component="span" className={classes.pipeSeparator}>
                  |
                </Typography>
                <Typography component="span" className={classes.dispensingSectionText}>
                  Next Sync Date:
                  <Typography component="span" className={classes.dispensingSectionValues}>
                    {inMedSync ? nextSyncDate : ''}
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.copyTherapyDivider} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getCanceledStatusId = (taskStatuses: any, task: any) => {
    const statuses = taskStatuses[task.taskType.toLowerCase()] || [];
    const status = statuses.find((it: any) => it.status === 'Canceled' && it.reason === null);
    return status ? status.id : task.status_id;
  };

  const submit = async (values: any) => {
    try {
      if (discontinuedDate && selectedReason && drug && !therapyErrorMessage.current) {
        if (selectedReason === 'Other' && !otherReason) {
          setDisplayErrors(true);
        } else {
          const administrationStatusId = reasonDropdown.find(
            (t: any) => t.label === selectedReason,
          )?.id;
          const tasksToCopy = values.task_ids.map((t: any) => {
            return listOfTasks[t];
          });
          const tasksToCancel = (
            tasksToCopy.length
              ? filtered.filter(
                  (task: any) =>
                    [FC, CSL].includes(task.taskType) ||
                    !tasksToCopy.some((t: any) => t.id === task.id && t.taskType === task.taskType),
                )
              : filtered
          ).map((task: any) => {
            const arborDate = convertToArborDate(moment(), true);
            const newTask: any = {
              id: task.id,
              taskType: task.taskType,
              therapy_id: task.therapy_id,
              status_id: getCanceledStatusId(taskStatuses, task),
              // some task types will use cancel_dt, and some others cancel_date
              canceled_dt: arborDate.getCustomerDatetime(),
              canceled_date: arborDate.getCustomerDate(),
              discharged_date: arborDate.getCustomerDate(),
              status_id_prior_to_discharge: task.status_id,
            };
            if (selectedReason) {
              // some task types will use additional_reason, and some others canceled_reason
              newTask.additional_reason = selectedReason;
              newTask.canceled_reason = selectedReason;
            }
            return addTaskOldValues(newTask, task);
          });

          const sourceTherapyPayload = {
            administration_status_additional_reason: otherReason,
            administration_status_id: administrationStatusId,
            discontinued_date: moment(discontinuedDate).format('YYYY/MM/DD'),
            medication_id: medication?.id ?? null,
            ndc: therapy.ndc,
            no_go_date: null,
            patient_id: patient.id,
            recheck_date: null,
            restart_date: null,
            therapy_id: therapy.id,
          };

          const copyTherapyPayload = {
            newTherapy: {
              ndc: drug.ndc,
              gpi: drug.gpi,
              is_specialty: drug.specialty_type === SpecialtyDrug.Yes ? 1 : 0,
              canceledTasks: tasksToCancel,
              tasksToCopy:
                tasksToCancel && tasksToCancel.length
                  ? tasksToCopy.reduce((acc: any, item: any) => {
                      if (
                        !tasksToCancel.some(
                          (t: any) => t.id === item.id && t.taskType === item.taskType,
                        )
                      ) {
                        acc.push(item);
                      }
                      return acc;
                    }, [] as any[])
                  : tasksToCopy,
            },
          };

          const therapyUrl = `/patients/${patient.id}/therapies/${therapy.id}`;
          const discontinueResponse = await HTTP.patch(therapyUrl, sourceTherapyPayload, {});
          if (
            discontinueResponse &&
            discontinueResponse.status === 200 &&
            discontinueResponse.data.therapy &&
            discontinueResponse.data.therapy.length
          ) {
            const discontinuedTherapy = {
              data: {
                therapy: discontinueResponse.data.therapy[0],
              },
            };
            if (tasksToCancel.length) {
              await dispatch(editTasks(patient.id, tasksToCancel));
            }
            const copyTherapyUrl = `/patient/${patient.id}/sourcetherapy/${therapy.id}/copyTherapy`;
            const copyResponse = await HTTP.post(copyTherapyUrl, copyTherapyPayload, {});
            if (copyResponse && copyResponse.status === 200) {
              if (copyResponse.data.error) {
                throw new Error(`${copyResponse.data.error}`);
              }
              await Promise.all(
                [copiedTherapyUpdate(discontinuedTherapy), copiedTherapyUpdate(copyResponse)].map(
                  dispatch,
                ),
              );

              const createdUpdatedTasks = copyResponse.data.createdTasks
                .concat(copyResponse.data.updatedTasks)
                .map((t: any) => {
                  return addTaskToState(t.id, t.taskType, t);
                });
              if (createdUpdatedTasks && createdUpdatedTasks.length) {
                await Promise.all(createdUpdatedTasks.map(dispatch));
              }
              if (tasksToCancel.length) {
                tasksToCancel.map((t: any) => {
                  dispatch(fetchTask(t.taskType, t.id));
                });
              }
              return true;
            }
          } else {
            throw new Error('There was an error while discontinuing the source therapy.');
          }
        }
      } else {
        setDisplayErrors(true);
      }
      return false;
    } catch (err) {
      dispatch(notifyError(`${err}`));
      setOpen(false);
      onClose();
      setDisplayErrors(false);
      return false;
    }
  };

  const getDisableList = (filtered: any[], therapy: any) => {
    let disableList = filtered.filter((task: any) => [INT].includes(task.taskType));
    if (therapy.dispensing_status_id === DispensingStatusValue.OptIn) {
      disableList = disableList.concat(
        filtered.filter((task: any) => [FC].includes(task.taskType)),
      );
    }
    if (therapy.clinical_support_status_id === ClinicalSupportStatusValue.OptIn) {
      disableList = disableList.concat(
        filtered.filter((task: any) => [CSL].includes(task.taskType)),
      );
    }
    return disableList;
  };

  return (
    <ModalForm
      title={`${therapy?.drug_name ?? ''} - Copy Therapy `}
      instructions={renderInstructions()}
      open={open}
      disableSubmit={false}
      jsonForm={tasksSelectJson}
      formId={`copy-therapy-${therapyIds.join('')}`}
      formName="select"
      data={{
        task_ids: filtered.map((task: ITask) => `${task.taskType}${task.id}`),
      }}
      providers={{
        table: {
          headers,
          data: filtered.map((task: ITask) => ({
            ...task,
            id: `${task.taskType}${task.id}`,
          })),
          disableList: getDisableList(filtered, therapy).map(
            (task: any) => `${task.taskType}${task.id}`,
          ),
        },
      }}
      submitButtonText="Confirm"
      cancelButtonText="Cancel"
      onSubmit={(values: any) => {
        (async () => {
          const result = await submit(values);
          if (result) {
            dispatch(notifySuccess('Therapy was copied successfully'));
            setOpen(false);
            onClose();
            setDisplayErrors(false);
          }
          return result;
        })();
        return true;
      }}
      onCancel={() => {
        setOpen(false);
        onClose();
        setDisplayErrors(false);
      }}
      marginTop={0}
      alwaysEnabled
      titleIcon={renderInfoIcon()}
    />
  );
};

function mapStateToProps(state: any, props: any) {
  const {
    tasks,
    therapies,
    selectedPatientId,
    taskStatuses,
    fillCycles,
    therapyStatuses,
    medications,
  } = state;
  const { medicationGroups } = medications;
  const therapy = therapies.data[props.therapyIds[0]] ?? {};
  const medication = Array.isArray(medicationGroups)
    ? medicationGroups.find(m => m.id === therapy.medication_id)
    : null;
  const taskKeys = Object.keys(tasks.data);
  taskKeys.map(tk => {
    if (
      tasks.data[`${tk}`].taskType &&
      tasks.data[`${tk}`].taskType === INT &&
      tasks.data[`${tk}`].therapy_id === null &&
      tasks.data[`${tk}`].therapy_ids !== null
    ) {
      tasks.data[`${tk}`].therapy_id = parseInt(tasks.data[`${tk}`].therapy_ids.split(',')[0], 10);
    }
  });

  return {
    tasks: tasks.data,
    therapies: therapies.data,
    selectedPatientId,
    taskStatuses: taskStatuses.statuses,
    allStatuses: taskStatuses,
    fillCycles,
    therapyStatuses,
    therapy: therapy,
    medication,
    patient: state.patient,
    listOfTasks: tasks.data,
  };
}

export default connect(mapStateToProps)(CopyTherapy);
