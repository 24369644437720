import { Button, Grid, Typography, Tooltip } from '@mui/material';
import { Info as InfoIcon, Loop as LoopIcon } from '@mui/icons-material';
import withStyles from '@mui/styles/withStyles';
import { setTherapyId, fetchTaskCounts, editTasks } from 'actions/action-tasks';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { reduxForm, Field, formValueSelector, change as changeActionCreator } from 'redux-form';
import {
  EDIT_THERAPY_ENROLLMENT,
  THERAPY_ENROLLMENT_STATUS_REFERRAL_ID,
  THERAPY_ENROLLMENT_STATUS_ENROLLED_ID,
  THERAPY_ENROLLMENT_STATUS_UNENROLLED_ID,
  THERAPY_ENROLLMENT_OPTOUT,
  THERAPY_DISPENSING_OPTOUT,
  PRESCRIBING_CLINIC_METHOD,
  SERVICE_ENROLLMENT_SHOULD_BE_SET,
  DISPENSING_STATUSES,
  RS,
  TASK_INTERVENTIONS,
} from 'constants/index';
import {
  userNotSelectableTherapyClinicalSupportReasons,
  getUserSelectableTherapyClinicalDispensingReasons,
  ExternalDispensingPharmacyReasons,
  staticPharmacies,
} from 'constants/lists';
import {
  therapyServiceEnrollmentAuditDetector,
  resolveAuditDefinition,
} from 'services/utils/audit-service';
import { resolveAudit } from 'actions/action-audit';

import AutocompleteLabel from 'components/form/field/autocomplete-label';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import {
  fetchInternalPharmacies,
  fetchExternalPharmacies,
  fetchTransferPharmacies,
  fetchPharmaciesNoDebounce,
  fetchExternalPharmaciesNoDebounce,
  getTherapyReasonById,
  getTherapyStatuses,
  statusArrayForDispensingEdit,
  getReasonsByStatus,
  getTherapyStatusId,
  getInitialPayerLockout,
  getSubmittingPayerLockout,
} from 'services/utils/therapy-service';
import ReactSelectForRedux, { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import Divider from 'components/divider';
import {
  renderRadioGroup,
  renderTextField,
  renderDropdown,
  renderDropdownNonSimple,
  renderMultipleDropdown,
  renderCheckbox,
  renderNumberField,
  renderReactRadioGroup,
} from 'components/form/field/redux-field';
import {
  getMedicalInsuranceHeader,
  getPharmacyInsuranceHeader,
} from 'services/utils/financials-service';
import { required, validateDate } from 'components/form/validation/validation';
import { getDispensingIcon, getClinicalSupportIcon, SmallSearch } from 'components/icons/icons';
import { editTherapy } from 'actions/action-therapies';
import { updateLocalUserPreferencesRecentPharmacies } from 'actions/action-user';
import { workListChanged } from 'actions/action-patient';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { convertToArborDate } from 'models/time/arbor-date';
import { convertMapToList } from 'reducers/helper-reducer';
import {
  getInitialManagingClinicForReactSelect,
  getCombinedTasks,
  hasTaskWithTypeAndNoneWithStatus,
  hasNoTasksOfTypeForTherapy,
  getTherapyFillCycleReasonOptions,
} from 'services/utils/task-service';
import TasksCancelForm from 'components/dynamic-form/therapy-forms/tasks-cancel-form';
import { notifyError } from 'actions/action-notifications';
import {
  ClinicalSupportStatusLabel,
  TherapyWorkflowLabel,
  SpecialtyTherapiesWf,
  DispensingStatusValue,
} from 'constants/enums';
import { RiskStratStatus } from 'interfaces/enums/TaskStatuses/RiskStratStatus';
import { TherapyUtil } from 'utils/therapy-util';
import { windowFeatureIsEnabled } from 'config/window-features';
import { styles } from './therapy-detail.styles';
import NpiLookup from './npi-lookup';
import NeedsByDateReasonDialog from './needs-by-date-reason-dialog';
import renderPrescribingClinicField from './fields/prescribing-clinic-field';
import renderPrescriberField from './fields/prescriber-field';
import { CdmProgramClient } from '../../../clients/cdm-program';
import { logger } from '../../../winston-logger';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';
import { isValidJson } from '../tasks-new/util';

const getReasonNames = (selectedReason, selectedAdditionalReason, list) =>
  ((Array.isArray(selectedReason) ? selectedReason.join() : selectedReason) || '')
    .split(',')
    .map(id => (list.find(r => r.value === Number(id)) || {}).label)
    .map(v => {
      if (v === 'Other' && selectedAdditionalReason) {
        return selectedAdditionalReason;
      }
      return v;
    })
    .filter(v => v);

const inputLabelStyling = {
  marginBottom: -30,
  minHeight: 28,
};

class EditEnrollment extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.submit = this.submit.bind(this);
    this.filterTasks = [];
    const { therapy, selectedDispensingPharmacyOptin, therapyLabel } = props;

    const externalReasonIds = therapy.external_dispensing_reason?.split(',');

    this.state = {
      npiLookup: false,
      transferNpiLookup: false,
      showDispensingAdditionalReason:
        externalReasonIds &&
        (externalReasonIds.includes(`${ExternalDispensingPharmacyReasons.other}`) ||
          externalReasonIds.includes(`${ExternalDispensingPharmacyReasons.patientChoiceOther}`)),
      showClinicalAdditionalReason: false,
      openFillCycleModal: false,
      payload: null,
      tasksCancel: false,
      cdmProgramName: undefined,
      daysSupplyInConflict: false,
      daysSupplyToolTip: '',
      optInDispPharmacy: selectedDispensingPharmacyOptin,
      showOptOutStates:
        externalReasonIds &&
        externalReasonIds.includes(`${ExternalDispensingPharmacyReasons.pharmacyNotLicensed}`) &&
        therapy.optout_states_ids?.length > 0,
    };
    this.handleChangeOptInPharmacy = this.handleChangeOptInPharmacy.bind(this);
    this.handleDispensingReason = this.handleDispensingReason.bind(this);
    this.handleClinicalReason = this.handleClinicalReason.bind(this);
    this.handleSaveColumnPharmacy = this.handleSaveColumnPharmacy.bind(this);
    this.handleSaveColumnTransferPharmacy = this.handleSaveColumnTransferPharmacy.bind(this);
    this.handleNeedsByDateChange = this.handleNeedsByDateChange.bind(this);
    this.handleCloseFillCycleModal = this.handleCloseFillCycleModal.bind(this);
    this.handleDaysSupplyConflict = this.handleDaysSupplyConflict.bind(this);
    this.handleFetchInternalPharmacyOptions = this.handleFetchInternalPharmacyOptions.bind(this);
    this.handleFetchExtDispPharmOptions = this.handleFetchExtDispPharmOptions.bind(this);
    this.handleFetchTransferPharmacyOptions = this.handleFetchTransferPharmacyOptions.bind(this);
    this.customerId = props.therapy?.customer_id; // eslint-disable-line
    this.internalDispensingPharmaciesForDropdown = [];
    this.internalPharmacies = props.internalPharmacies.slice() || [];
  }

  async componentDidMount() {
    const { therapy, initialValues, change } = this.props;
    const { clinical_support_additional_reason: clinicalSupportAdditionalReason } = therapy;
    const fetchAndSetCdmTherapyName = async () => {
      const { patient_id: patientId, diagnosis_code: diagnosisCode } = therapy;
      const { cdmProgramName } = this.state;
      if (cdmProgramName === undefined && patientId && diagnosisCode) {
        try {
          const response = await CdmProgramClient.fetch(patientId, diagnosisCode);
          const cdmPrograms = response.data;
          const cdmProgramNameResult = cdmPrograms[0]?.specialtyDiagnosis?.name;
          this.setState({ cdmProgramName: cdmProgramNameResult ?? null });
        } catch (error) {
          logger.error(error);
          const { notifyError: notifyErrorAction } = this.props;
          notifyErrorAction('Unable to fetch CDM program data.');
          this.setState({ cdmProgramName: null });
        }
      }
    };

    const dropDownResult = await this.handleFetchInternalPharmacyOptions();
    const dropDownFields = dropDownResult.map(item => ({
      ...item,
      label: item.label,
      value: item,
    }));
    this.internalDispensingPharmaciesForDropdown = dropDownFields;

    fetchAndSetCdmTherapyName();

    if (
      initialValues.dispensing_pharmacy_npi &&
      staticPharmacies.some(pharmacy => pharmacy.npi === initialValues.dispensing_pharmacy_npi)
    ) {
      const staticPharmacy = staticPharmacies.find(
        pharmacy => pharmacy.npi === initialValues.dispensing_pharmacy_npi,
      );
      if (staticPharmacy) {
        if (initialValues.dispensing_status === DISPENSING_STATUSES.OPT_OUT) {
          change('dispensing_pharmacy_opt_out', {
            ...staticPharmacy,
            label: staticPharmacy.name,
            description: staticPharmacy.address,
          });
        } else {
          change('dispensing_pharmacy_opt_in', {
            ...staticPharmacy,
            label: staticPharmacy.name,
          });
        }
      }
    } else if (
      initialValues.dispensing_pharmacy_npi &&
      initialValues.dispensing_status === DISPENSING_STATUSES.OPT_OUT
    ) {
      fetchExternalPharmaciesNoDebounce(
        initialValues.dispensing_pharmacy_npi,
        this.customerId,
      ).then(result => {
        const pharmacy = result.data && result.data.pharmacies[0];
        if (pharmacy) {
          change('dispensing_pharmacy_opt_out', {
            ...pharmacy,
            label: pharmacy.name,
            description: pharmacy.address,
          });
        }
      });
    }

    if (
      initialValues.transfer_pharmacy_npi &&
      staticPharmacies.some(pharmacy => pharmacy.npi === initialValues.transfer_pharmacy_npi)
    ) {
      const staticPharmacy = staticPharmacies.find(
        pharmacy => pharmacy.npi === initialValues.transfer_pharmacy_npi,
      );
      if (staticPharmacy) {
        change('transfer_pharmacy', {
          ...staticPharmacy,
          label: staticPharmacy.name,
          description: staticPharmacy.address,
        });
      }
    }

    if (initialValues.transfer_pharmacy_npi) {
      fetchPharmaciesNoDebounce(initialValues.transfer_pharmacy_npi).then(result => {
        const pharmacy = result.data && result.data.pharmacies[0];
        if (pharmacy) {
          change('transfer_pharmacy', {
            ...pharmacy,
            label: pharmacy.name,
            description: pharmacy.address,
          });
        }
      });
    }

    if (clinicalSupportAdditionalReason) {
      this.setState({ showClinicalAdditionalReason: true });
    } else {
      this.setState({ showClinicalAdditionalReason: false });
    }
    this.handleDaysSupplyConflict(therapy.days_supply);
  }

  shouldPromptToCancelInt = (therapy, links) => {
    if (links?.data && links.data.length) {
      const intLinkData = links.data.find(
        t =>
          t.resource_two_type === TASK_INTERVENTIONS &&
          t.meta &&
          typeof t.meta === 'string' &&
          isValidJson(t.meta) &&
          JSON.parse(t.meta)?.therapy_id &&
          JSON.parse(t.meta)?.therapy_id === therapy.id,
      );
      if (intLinkData && Object.keys(intLinkData).length) {
        return true;
      }
    }
    return false;
  };

  async getTaskTypesToCancelByStatus() {
    const { selectedDispensingStatus, selectedClinicalStatus, therapy, links } = this.props;
    const filterTasks = [];
    if (
      [DISPENSING_STATUSES.OPT_OUT, DISPENSING_STATUSES.OPT_IN].includes(selectedDispensingStatus)
    ) {
      if (selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT) {
        filterTasks.push('FC');
      }
      const response = await PatientTherapiesClient.getTherapyWorkflow(
        therapy.diagnosis_code,
        therapy?.specialty_type ?? null,
      );
      const workflowLabel = TherapyWorkflowLabel[response.data?.therapyWorkflow] ?? '';
      if (
        workflowLabel === SpecialtyTherapiesWf.SP &&
        windowFeatureIsEnabled('task_firing_based_on_dispensing_status') &&
        selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT &&
        [
          DispensingStatusValue.UndecidedNotYetOffered,
          DispensingStatusValue.UndecidedDeferredDecision,
          DispensingStatusValue.OptIn,
        ].includes(therapy.dispensing_status_id)
      ) {
        const completedDc = await PatientTherapiesClient.therapyHasCompletedTask(therapy.id, 'DC');
        if (completedDc?.data?.response?.result) {
          filterTasks.push('DC');
          if (this.shouldPromptToCancelInt(therapy, links)) {
            filterTasks.push('INT');
          }
        }
        const completedCsl = await PatientTherapiesClient.therapyHasCompletedTask(
          therapy.id,
          'CSL',
        );
        if (completedCsl?.data?.response?.result) {
          filterTasks.push('CSL');
        }
      }
      if (
        workflowLabel === SpecialtyTherapiesWf.SP &&
        windowFeatureIsEnabled('task_firing_based_on_dispensing_status') &&
        selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN &&
        therapy.clinical_support_status === ClinicalSupportStatusLabel.OptIn &&
        selectedClinicalStatus === ClinicalSupportStatusLabel.OptOut
      ) {
        filterTasks.push('DC');
        if (this.shouldPromptToCancelInt(therapy, links)) {
          filterTasks.push('INT');
        }
      }
    }
    const { cdmProgramName } = this.state;
    // If this therapy is part of a CDM program, do not show the CSL tasks to cancel.
    // They will be automatically canceled as part of opting out of the entire CDM program.
    if (selectedClinicalStatus === ClinicalSupportStatusLabel.OptOut && !cdmProgramName) {
      filterTasks.push('CSL');
      filterTasks.push('DC');
    }

    return filterTasks;
  }

  getReasonToCancelByStatus() {
    const {
      selectedDispensingStatus,
      selectedClinicalStatus,
      selectedReason,
      selectedAdditionalReason,
      selectedClinicalOptoutReason,
      selectedClinicalOptoutAdditionalReason,
      therapyDispensingPharmacyReasons,
    } = this.props;

    const reasons = [];
    if (selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT) {
      const dispensingReasons = getReasonNames(
        selectedReason,
        selectedAdditionalReason,
        therapyDispensingPharmacyReasons,
      );
      reasons.push(dispensingReasons.join('. '));
    }
    if (selectedClinicalStatus === ClinicalSupportStatusLabel.OptOut) {
      const clinicalReasons = getReasonNames(
        selectedClinicalOptoutReason,
        selectedClinicalOptoutAdditionalReason,
        userNotSelectableTherapyClinicalSupportReasons,
      );
      reasons.push(clinicalReasons.join('. '));
    }
    return reasons.join('. ');
  }

  determineDispensingPharmacy(values) {
    const {
      therapy: { is_specialty: isSpecialty },
    } = this.props;
    if (values.dispensing_status === DISPENSING_STATUSES.OPT_IN) {
      return {
        npi: values.dispensing_pharmacy_opt_in?.npi,
        name: values.dispensing_pharmacy_opt_in?.name,
        doing_business_as: values.dispensing_pharmacy_opt_in?.doing_business_as, // eslint-disable-line
      };
    }
    if (isSpecialty && values.dispensing_status === DISPENSING_STATUSES.OPT_OUT) {
      return {
        npi: values.dispensing_pharmacy_opt_out.npi,
        name: values.dispensing_pharmacy_opt_out.name,
        doing_business_as: values.dispensing_pharmacy_opt_out.doing_business_as,
      };
    }
    return {
      npi: null,
      name: null,
      doing_business_as: null,
    };
  }

  determineTransferPharmacy(values) {
    if (values.dispensing_status === DISPENSING_STATUSES.OPT_IN) {
      return {
        npi: values.transfer_pharmacy?.npi || null,
        name: values.transfer_pharmacy?.name || null,
        doing_business_as: values.transfer_pharmacy?.doing_business_as || null, // eslint-disable-line
      };
    }
    return {
      npi: null,
      name: null,
      doing_business_as: null,
    };
  }

  handleCloseFillCycleModal() {
    this.setState({ openFillCycleModal: false });
  }

  handleNeedsByDateChange() {
    const { selectedNeedsByDate, therapy } = this.props;
    const hasChanged =
      selectedNeedsByDate &&
      !(
        convertToArborDate(selectedNeedsByDate).getUtcDate() ===
        convertToArborDate(therapy.needsby_date, true).getUtcDate()
      );
    if (therapy.fill_cycle && hasChanged) {
      this.setState({
        openFillCycleModal: true,
      });
    }
  }

  handleSubmit(values) {
    const {
      patient,
      enrollmentStatuses,
      therapy,
      selectedReason,
      initialValues,
      auditState,
      resolveAudit, // eslint-disable-line
    } = this.props;
    const { showClinicalAdditionalReason } = this.state;
    if (values.clinical_support_status !== ClinicalSupportStatusLabel.Undecided) {
      values.clinical_support_undecided_reason = null;
    }
    if (values.dispensing_status !== DISPENSING_STATUSES.UNDECIDED) {
      values.dispensing_undecided_reason = null;
    }
    const clinicalSupportStatusId = getTherapyStatusId(
      enrollmentStatuses,
      values.clinical_support_status,
      values.clinical_support_undecided_reason,
    );
    const dispensingStatusId = getTherapyStatusId(
      enrollmentStatuses,
      values.dispensing_status,
      values.dispensing_undecided_reason,
    );
    let enrollmentStatusId;
    if (clinicalSupportStatusId === 1 && dispensingStatusId === 1) {
      enrollmentStatusId = THERAPY_ENROLLMENT_STATUS_REFERRAL_ID;
    } else if (clinicalSupportStatusId === 3 && dispensingStatusId === 3) {
      enrollmentStatusId = THERAPY_ENROLLMENT_STATUS_UNENROLLED_ID;
    } else {
      enrollmentStatusId = THERAPY_ENROLLMENT_STATUS_ENROLLED_ID;
    }

    const prescribingClinic =
      values.prescribing_method_id === 'useSame'
        ? {
            id: values.managing_clinic.value,
            name: values.managing_clinic.label,
            npi: values.managing_clinic.npi,
          }
        : values.referring_clinic;

    const dispensingPharmacy = this.determineDispensingPharmacy(values);
    const transferPharmacy = this.determineTransferPharmacy(values);

    const payload = {
      category_id: values.category_id,
      patient_id: patient.id,
      therapy_id: therapy.id,
      service_group_id: values.service_group_id,
      needsby_date: convertToArborDate(values.needsby_date).getUtcDate(),
      days_supply: values.days_supply,
      is_needsby_per_protocol: values.is_needsby_per_protocol,
      fill_cycle_reason_id: values.fill_cycle_reason_id,
      clinical_support_status_id: clinicalSupportStatusId,
      dispensing_status_id: dispensingStatusId,
      enrollment_status_id: enrollmentStatusId,
      old_dispensing_status_id: therapy.dispensing_status_id,
      medication_id: therapy.medication_id,
      dispensing_pharmacy_npi: dispensingPharmacy.npi,
      dispensing_pharmacy_name: dispensingPharmacy.name,
      dispensing_pharmacy_doing_business_as: dispensingPharmacy.doing_business_as,
      transfer_pharmacy_npi: transferPharmacy.npi,
      transfer_pharmacy_name: transferPharmacy.name,
      transfer_pharmacy_doing_business_as: transferPharmacy.doing_business_as,
      external_dispensing_reason:
        dispensingStatusId === 3
          ? (Array.isArray(values.external_dispensing_reason) &&
              values.external_dispensing_reason.join(',')) ||
            values.external_dispensing_reason
          : null,
      optout_states_ids: values.external_dispensing_optout_states,
      clinical_support_status_reason:
        clinicalSupportStatusId === 3
          ? (Array.isArray(values.clinical_support_status_reason) &&
              values.clinical_support_status_reason.join(',')) ||
            values.clinical_support_status_reason
          : null,
      clinical_support_additional_reason:
        clinicalSupportStatusId === 3 && showClinicalAdditionalReason
          ? values.clinical_support_additional_reason
          : null,
      external_dispensing_additional_reason: values.external_dispensing_additional_reason,
      outreach_followup_dt: convertToArborDate(values.outreach_followup_dt).getUtcDatetime(),
      medical_insurance:
        selectedReason && selectedReason.split(',') && selectedReason.split(',').includes('3')
          ? getSubmittingPayerLockout(values.medical_insurance)
          : null,
      pbm_insurance:
        selectedReason && selectedReason.split(',') && selectedReason.split(',').includes('3')
          ? getSubmittingPayerLockout(values.pbm_insurance)
          : null,
      referring_clinic_npi: prescribingClinic ? prescribingClinic.npi : null,
      referring_clinic_name: prescribingClinic ? prescribingClinic.name : null,
      referring_clinic_internal_id: prescribingClinic ? prescribingClinic.id : null,
      one_time_fill: values.one_time_fill ? 1 : 0,
      prescribing_physician_npi: values.prescribing_physician
        ? values.prescribing_physician.npi
        : null,
      prescribing_physician_name: values.prescribing_physician
        ? values.prescribing_physician.name
        : null,
      managing_clinic_id: values.managing_clinic.value,
      managing_clinic_name: values.managing_clinic.label,
    };

    if (therapyServiceEnrollmentAuditDetector(auditState, patient, initialValues, payload)) {
      resolveAuditDefinition({
        auditDefinitionType: SERVICE_ENROLLMENT_SHOULD_BE_SET,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }

    // If drug is non-sp it may not have nbd at initial state.
    // Need to insert a new fill cycle if they set a nbd in the edit form
    if (!therapy.fill_cycle && values.needsby_date) {
      payload.first_fill_cycle = true;
    }

    if (
      clinicalSupportStatusId === THERAPY_ENROLLMENT_OPTOUT ||
      dispensingStatusId === THERAPY_DISPENSING_OPTOUT ||
      dispensingStatusId === DispensingStatusValue.OptIn
    ) {
      if (
        dispensingStatusId === DispensingStatusValue.OptIn &&
        windowFeatureIsEnabled('task_firing_based_on_dispensing_status')
      ) {
        PatientTherapiesClient.getTherapyWorkflow(
          therapy.diagnosis_code,
          therapy?.specialty_type ?? null,
        ).then(response => {
          const workflowLabel = TherapyWorkflowLabel[response.data?.therapyWorkflow] ?? '';
          if (
            workflowLabel === SpecialtyTherapiesWf.SP &&
            therapy.clinical_support_status_id === 2 &&
            clinicalSupportStatusId === 3
          ) {
            return this.showTasksCancel(payload);
          }
          return new Promise(resolve => {
            this.setState({ payload }, () => {
              this.submit([]).then(resolve);
            });
          });
        });
      } else {
        if (
          dispensingStatusId === THERAPY_DISPENSING_OPTOUT ||
          clinicalSupportStatusId === THERAPY_ENROLLMENT_OPTOUT
        ) {
          return this.showTasksCancel(payload);
        }
        return new Promise(resolve => {
          this.setState({ payload }, () => {
            this.submit([]).then(resolve);
          });
        });
      }
    } else {
      return new Promise(resolve => {
        this.setState({ payload }, () => {
          this.submit([]).then(resolve);
        });
      });
    }
  }

  submit(tasksToCancel) {
    const {
      editTherapy, // eslint-disable-line no-shadow
      editTasks, // eslint-disable-line no-shadow
      workListChanged, // eslint-disable-line no-shadow
      fetchTaskCounts, // eslint-disable-line no-shadow
    } = this.props;
    let { payload } = this.state;
    payload = {
      ...payload,
      tasksToCancel: tasksToCancel,
    };
    return new Promise((resolve, reject) => {
      editTherapy(
        payload,
        () => {
          resolve();
          if (tasksToCancel.length) {
            editTasks(payload.patient_id, tasksToCancel);
          }
          workListChanged();
          fetchTaskCounts();
          this.handleCancel();
        },
        reject,
      );
    });
  }

  async showTasksCancel(payload) {
    const listOfFilteredTasks = await this.getTaskTypesToCancelByStatus();
    return new Promise(resolve => {
      this.setState({
        payload,
        tasksCancel: true,
        filterTasks: listOfFilteredTasks,
        tasksCancelOnComplete: resolve,
      });
    });
  }

  handleCancel() {
    const { reset, toggleCallback } = this.props;
    reset();
    toggleCallback();
  }

  handleSaveColumnPharmacy(callbackValue) {
    const {
      updateLocalUserPreferencesRecentPharmacies, // eslint-disable-line no-shadow
    } = this.props;
    updateLocalUserPreferencesRecentPharmacies(callbackValue);
  }

  handleSaveColumnTransferPharmacy(callbackValue) {
    const {
      updateLocalUserPreferencesRecentPharmacies, // eslint-disable-line no-shadow
    } = this.props;
    updateLocalUserPreferencesRecentPharmacies(callbackValue);
  }

  handleDispensingReason(e, val) {
    const optOutReasonesIds = val?.split(',').map(x => Number(x));

    this.setState({
      showDispensingAdditionalReason:
        optOutReasonesIds &&
        (optOutReasonesIds.includes(ExternalDispensingPharmacyReasons.patientChoiceOther) ||
          optOutReasonesIds.includes(ExternalDispensingPharmacyReasons.other)),
      showOptOutStates:
        optOutReasonesIds &&
        !!optOutReasonesIds.includes(ExternalDispensingPharmacyReasons.pharmacyNotLicensed),
    });
  }

  handleClinicalReason(e, val) {
    if (val && val.split(',') && val.split(',').includes('5')) {
      this.setState({ showClinicalAdditionalReason: true });
    } else {
      this.setState({ showClinicalAdditionalReason: false });
    }
  }

  async handleFetchInternalPharmacyOptions() {
    const internalPharmaciesFromLookup = Array.isArray(this.internalPharmacies)
      ? this.internalPharmacies
      : false;
    const pharmacies =
      internalPharmaciesFromLookup ||
      (await fetchInternalPharmacies(this.customerId)).data?.pharmacies ||
      [];
    this.internalPharmacies = pharmacies;
    const sortedPharmacies = pharmacies.sort((a, b) =>
      (a.name || ' ').localeCompare(b.name || ' '),
    );
    return sortedPharmacies.map(pharmacy => ({
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    }));
  }

  async handleFetchExtDispPharmOptions(searchText) {
    const fetchResults = await fetchExternalPharmacies(searchText);
    const pharmacies = fetchResults.data ? fetchResults.data.pharmacies : [];
    return [...staticPharmacies, ...pharmacies].map(pharmacy => ({
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    }));
  }

  async handleFetchTransferPharmacyOptions(searchText) {
    const fetchResults = await fetchTransferPharmacies(searchText, this.customerId);
    const pharmacies = fetchResults.data ? fetchResults.data.pharmacies : [];
    return [...staticPharmacies, ...pharmacies].map(pharmacy => ({
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    }));
  }

  handlePharmacyNpiChange(pharmacy) {
    const { change } = this.props; //eslint-disable-line
    change('dispensing_pharmacy_opt_out', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnPharmacy(pharmacy);
  }

  handleTransferPharmacyNpiChange(pharmacy) {
    const { change } = this.props; //eslint-disable-line
    change('transfer_pharmacy', {
      ...pharmacy,
      label: pharmacy.name,
      description: pharmacy.address,
    });
    this.handleSaveColumnTransferPharmacy(pharmacy);
  }

  handleChangeOptInPharmacy(pharmacy) {
    this.setState({ optInDispPharmacy: pharmacy });
  }

  handleDaysSupplyConflict(daysSupplyValue) {
    const { therapy, patient } = this.props;
    const daysSupplyChecking = TherapyUtil.checkDaysSupply(daysSupplyValue, therapy, patient);
    this.setState({
      daysSupplyInConflict: daysSupplyChecking.daysSupplyInConflict,
      daysSupplyToolTip: daysSupplyChecking.daysSupplyToolTip,
    });
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      classes,
      selectedDispensingStatus,
      selectedClinicalStatus,
      therapy,
      selectedDispensingPharmacyOptout,
      selectedTransferPharmacy,
      selectedReason,
      enrollmentStatuses,
      selectedDispensingReason,
      selectedClinicalReason,
      lookups,
      change, //eslint-disable-line
      medicalInsurances,
      pbmInsurances,
      prescribingMethodId,
      recentPharmacies,
      therapyDispensingPharmacyReasons,
      therapyFillCycleReasonOptions,
      states,
      current_dispensing_status,
      current_clinical_support_status,
      therapyLabel,
    } = this.props;

    const {
      showDispensingAdditionalReason,
      showClinicalAdditionalReason,
      npiLookup,
      transferNpiLookup,
      openFillCycleModal,
      tasksCancel,
      tasksCancelOnComplete,
      daysSupplyInConflict,
      daysSupplyToolTip,
      optInDispPharmacy,
      showOptOutStates,
    } = this.state;

    const therapyClinicalDispensingStatuses = statusArrayForDispensingEdit(
      getTherapyStatuses(enrollmentStatuses),
      current_dispensing_status,
    );
    const therapyClinicalSupportStatuses = statusArrayForDispensingEdit(
      getTherapyStatuses(enrollmentStatuses),
      current_clinical_support_status,
    );
    const isSpecialty = Boolean(therapy.is_specialty);
    const { cdmProgramName } = this.state;
    const combinedTasks = getCombinedTasks(therapy, true);

    const disableClinicalSupportStatusForCDMTherapy = therapyLabel === RS;

    const { selectedClinicalOptoutReason } = this.props;
    const clinicalSupportOptions = getUserSelectableTherapyClinicalDispensingReasons(
      selectedClinicalOptoutReason,
    );

    const shouldDisplayDispensingPharmacyDropdown =
      selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN;
    const shouldDisplayDispensingPharmacySearch =
      isSpecialty && selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT;

    return (
      <Grid container spacing={7}>
        {tasksCancel && (
          <TasksCancelForm
            title={`Cancel tasks associated with enrollment of ${therapy.drug_name}?`}
            instructions="Before opting-out, please select which tasks you want to cancel:"
            therapyIds={[therapy.id]}
            onClose={() => {
              this.setState({
                tasksCancel: false,
              });
              this.handleCancel();
              if (tasksCancelOnComplete) {
                tasksCancelOnComplete();
              }
            }}
            reason={this.getReasonToCancelByStatus()}
            onSubmit={tasksToCancel =>
              this.submit(tasksToCancel).then(() => {
                if (tasksCancelOnComplete) {
                  tasksCancelOnComplete();
                }
              })
            }
            filterTasks={this.state.filterTasks}
          />
        )}
        <Grid item xs={12}>
          {!!npiLookup && (
            <NpiLookup
              open
              title="Dispensing Pharmacy"
              values={{
                taxonomy: 'pharmacy',
                organization: selectedDispensingPharmacyOptout.name,
              }}
              handleChange={item => {
                this.handlePharmacyNpiChange(item);
              }}
              handleClose={() => {
                this.setState({ npiLookup: false });
              }}
            />
          )}
          {!!transferNpiLookup && (
            <NpiLookup
              open
              title="Transfer From Pharmacy"
              values={{
                taxonomy: 'pharmacy',
                organization: selectedTransferPharmacy.name,
              }}
              handleChange={item => {
                this.handleTransferPharmacyNpiChange(item);
              }}
              handleClose={() => {
                this.setState({ transferNpiLookup: false });
              }}
            />
          )}
          <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
            <Grid container>
              <Grid item xs={2}>
                <div>
                  {getDispensingIcon(therapy.dispensing_status_id)}
                  <Typography variant="caption">Dispensing</Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={10}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Field
                  name="dispensing_status"
                  radioMap={therapyClinicalDispensingStatuses}
                  component={renderRadioGroup}
                  className={classes.input}
                  validate={[required]}
                />
              </Grid>
              {isSpecialty && selectedDispensingStatus === DISPENSING_STATUSES.UNDECIDED && (
                <Grid container spacing={1}>
                  <Grid item xs={7}>
                    <Field
                      name="dispensing_undecided_reason"
                      label="Dispensing Undecided Reason"
                      width="90"
                      component={renderDropdown}
                      fields={getReasonsByStatus(enrollmentStatuses, selectedDispensingStatus)}
                      validate={[required]}
                    />
                  </Grid>
                  {selectedDispensingReason === 'Patient deferred decision' &&
                    therapy.dispensing_status_id !== 4 && (
                      <Grid item xs={4}>
                        <Field
                          name="outreach_followup_dt"
                          component={renderDatePicker}
                          label="Follow up Date *"
                          validate={[validateDate, required]}
                        />
                      </Grid>
                    )}
                </Grid>
              )}
              {selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN && (
                <Grid container spacing={1}>
                  <Grid item xs={7} className={classes.npiLookupButtonContainer}>
                    <Field
                      name="transfer_pharmacy"
                      label="Transfer From Pharmacy"
                      component={AutocompleteMinigrid}
                      fetchOptions={this.handleFetchTransferPharmacyOptions}
                      recentOptions={(recentPharmacies || []).map(icd10 => ({
                        ...icd10,
                        id: icd10.npi,
                        label: icd10.name,
                        description: icd10.address,
                      }))}
                      hint="Search by Pharmacy Name or NPI"
                      onChange={this.handleSaveColumnTransferPharmacy}
                      simplistic
                      hasMinSearchLength
                    />
                    <Button
                      className={classes.npiLookupButton}
                      onClick={() => this.setState({ transferNpiLookup: true })}
                    >
                      <SmallSearch />
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <AutocompleteLabel
                      idValue={selectedTransferPharmacy.npi}
                      defaultIdValue={therapy.transfer_pharmacy_npi}
                      label="Pharmacy NPI"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <AutocompleteLabel
                      fieldWidth="100%"
                      idValue={selectedTransferPharmacy.doing_business_as}
                      label="Doing Business As"
                    />
                  </Grid>
                </Grid>
              )}
              {shouldDisplayDispensingPharmacySearch && (
                <Grid container spacing={1}>
                  <Grid item xs={7} className={classes.npiLookupButtonContainer}>
                    <Field
                      name="dispensing_pharmacy_opt_out"
                      label="Dispensing Pharmacy *"
                      component={AutocompleteMinigrid}
                      fetchOptions={this.handleFetchExtDispPharmOptions}
                      recentOptions={(recentPharmacies || [])
                        .filter(item => item.is_internal !== 'Yes')
                        .map(icd10 => ({
                          ...icd10,
                          id: icd10.npi,
                          label: icd10.name,
                          description: icd10.address,
                        }))}
                      hint="Search by Pharmacy Name or NPI"
                      onChange={this.handleSaveColumnPharmacy}
                      simplistic
                      hasMinSearchLength
                      validate={[required]}
                    />
                    <Button
                      className={classes.npiLookupButton}
                      onClick={() => this.setState({ npiLookup: true })}
                    >
                      <SmallSearch />
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <AutocompleteLabel
                      idValue={selectedDispensingPharmacyOptout.npi}
                      defaultIdValue={therapy.dispensing_pharmacy_npi}
                      label="Pharmacy NPI"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <AutocompleteLabel
                      fieldWidth="100%"
                      idValue={selectedDispensingPharmacyOptout.doing_business_as}
                      label="Doing Business As"
                    />
                  </Grid>
                </Grid>
              )}
              {shouldDisplayDispensingPharmacyDropdown && (
                <Grid container spacing={1}>
                  <Grid item xs={6} className={classes.npiLookupButtonContainer}>
                    <Field
                      name="dispensing_pharmacy_opt_in"
                      label="Dispensing Pharmacy *"
                      component={renderDropdownNonSimple}
                      fields={this.internalDispensingPharmaciesForDropdown}
                      validate={[required]}
                      inputLabelStyle={inputLabelStyling}
                      onChange={this.handleChangeOptInPharmacy}
                      data-qa-id={`dispensing_pharmacy_opt_in_therapy_${therapy.ndc}`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <AutocompleteLabel
                      idValue={optInDispPharmacy?.npi}
                      defaultIdValue={optInDispPharmacy?.npi}
                      label="Pharmacy NPI"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <AutocompleteLabel
                      fieldWidth="100%"
                      idValue={optInDispPharmacy?.doing_business_as}
                      label="Doing Business As"
                    />
                  </Grid>
                </Grid>
              )}
              {selectedDispensingStatus === DISPENSING_STATUSES.OPT_OUT &&
                isSpecialty &&
                therapyDispensingPharmacyReasons && (
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <Field
                        name="external_dispensing_reason"
                        label="Outside Dispensing Pharmacy Reason *"
                        onChange={this.handleDispensingReason}
                        multiple
                        width="90"
                        component={renderMultipleDropdown}
                        fields={therapyDispensingPharmacyReasons}
                        validate={[required]}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Field
                        name="external_dispensing_additional_reason"
                        label="Outside Dispensing Pharmacy Reason Details"
                        component={renderTextField}
                        multiline
                        rows={3}
                      />
                    </Grid>
                    {!!showOptOutStates && (
                      <Grid item xs={5}>
                        <Field
                          name="external_dispensing_optout_states"
                          label="State *"
                          multiple
                          width="90"
                          component={renderMultipleDropdown}
                          fields={states}
                          validate={[required]}
                        />
                      </Grid>
                    )}
                    {selectedReason &&
                      selectedReason.split(',') &&
                      selectedReason.split(',').includes('3') && (
                        <Grid container>
                          {!!medicalInsurances.length && (
                            <Grid item xs={8}>
                              <Field
                                name="medical_insurance"
                                label="Medical Insurance *"
                                validate={[required]}
                                component={ReactSelectForRedux}
                                fields={medicalInsurances.map(insurance => ({
                                  label: getMedicalInsuranceHeader(insurance),
                                  value: insurance.id,
                                }))}
                              />
                            </Grid>
                          )}
                          {!!pbmInsurances.length && (
                            <Grid item xs={8}>
                              <Field
                                name="pbm_insurance"
                                label="Pharmacy Insurance *"
                                validate={[required]}
                                component={ReactSelectForRedux}
                                fields={pbmInsurances.map(insurance => ({
                                  label: getPharmacyInsuranceHeader(insurance),
                                  value: insurance.id,
                                }))}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                  </Grid>
                )}
              {selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN && (
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={2}>
                    <Field
                      label="Needs By Date *"
                      name="needsby_date"
                      component={renderDatePicker}
                      validate={[required, validateDate]}
                      onBlur={this.handleNeedsByDateChange}
                    />
                  </Grid>
                  <NeedsByDateReasonDialog
                    open={openFillCycleModal}
                    handleClose={this.handleCloseFillCycleModal}
                    change={change}
                    options={therapyFillCycleReasonOptions}
                    getInitialValue={prop => {
                      const { initialValues } = this.props;
                      return initialValues[prop];
                    }}
                  />
                  <Grid item xs={2}>
                    <Field
                      name="is_needsby_per_protocol"
                      component={renderCheckbox}
                      label="Per Protocol"
                    />
                  </Grid>
                  {!isSpecialty && (
                    <Grid item xs={2}>
                      <Field
                        name="one_time_fill"
                        label="One-Time Fill"
                        component={renderCheckbox}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={2}
                    className={
                      windowFeatureIsEnabled('med_sync') && daysSupplyInConflict
                        ? classes.daySupplyConflict
                        : ''
                    }
                  >
                    <Field
                      label="Days Supply *"
                      name="days_supply"
                      validate={[required]}
                      component={renderNumberField}
                      onChange={e => this.handleDaysSupplyConflict(e.target.value)}
                    />
                    {windowFeatureIsEnabled('med_sync') && daysSupplyToolTip.length > 0 && (
                      <Tooltip title={daysSupplyToolTip} placement="right-start">
                        {daysSupplyInConflict ? (
                          <InfoIcon className={classes.daysSupplyInfo} />
                        ) : (
                          <LoopIcon className={classes.daysSupplyInfo} />
                        )}
                      </Tooltip>
                    )}
                  </Grid>
                  {!therapy.service_group_id && (
                    <Grid item xs={3}>
                      <Field
                        name="service_group_id"
                        label="Service Group *"
                        validate={[required]}
                        component={renderDropdown}
                        fields={lookups.serviceGroups.map(int => ({
                          label: int.display_name,
                          value: int.id,
                        }))}
                      />
                    </Grid>
                  )}
                  {!therapy.managing_clinic_id && (
                    <Grid item xs={3}>
                      <Field
                        name="managing_clinic"
                        label="Managing Clinic *"
                        validate={[required]}
                        component={ReactSelectForReduxSingle}
                        fields={lookups.customerClinics.map(int => ({
                          label: int.name,
                          value: int.id,
                          npi: int.npi,
                          isDisabled: !int.active,
                        }))}
                      />
                    </Grid>
                  )}
                  {!therapy.referring_clinic_npi && (
                    <>
                      <Grid item xs={12}>
                        <Field
                          name="prescribing_method_id"
                          label="Prescribing Clinic"
                          component={renderReactRadioGroup}
                          radioMap={[
                            {
                              value: PRESCRIBING_CLINIC_METHOD.USE_SAME,
                              label: 'Same as Managing Clinic',
                            },
                            { value: PRESCRIBING_CLINIC_METHOD.USE_LOOKUP, label: 'Other' },
                            { value: PRESCRIBING_CLINIC_METHOD.USE_NULL, label: 'Unknown' },
                          ]}
                        />
                      </Grid>
                      {prescribingMethodId === PRESCRIBING_CLINIC_METHOD.USE_LOOKUP && (
                        <Grid item xs={12}>
                          <Field
                            name="referring_clinic"
                            label="Prescribing Clinic"
                            component={renderPrescribingClinicField}
                            therapy={therapy}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                  {!therapy.prescribing_physician_npi && (
                    <Grid item xs={12}>
                      <Field
                        name="prescribing_physician"
                        label="Prescriber"
                        component={renderPrescriberField}
                        therapy={therapy}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            {isSpecialty && (
              <>
                <Divider />
                {therapy.dispensing_status !== DISPENSING_STATUSES.OPT_IN &&
                  selectedDispensingStatus === DISPENSING_STATUSES.OPT_IN &&
                  selectedClinicalStatus !== ClinicalSupportStatusLabel.OptIn && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.clinicalOptInPrompt}>
                        <div>
                          <Typography>
                            Is the patient ready to Opt In for Clinical Support?
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <div>
                      {getClinicalSupportIcon(therapy.clinical_support_status_id)}
                      <Typography variant="caption">Clinical Support</Typography>
                    </div>
                  </Grid>

                  {!disableClinicalSupportStatusForCDMTherapy ? (
                    <Grid
                      item
                      xs={10}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <Field
                        name="clinical_support_status"
                        radioMap={therapyClinicalSupportStatuses}
                        component={renderRadioGroup}
                        className={classes.input}
                        validate={[required]}
                        qaId="clinical_support_status"
                      />
                    </Grid>
                  ) : (
                    <Tooltip
                      title="There is not yet an accepted Risk Strat task for this disease state"
                      placement="top-start"
                    >
                      <Grid
                        item
                        xs={10}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <Field
                          name="clinical_support_status"
                          radioMap={therapyClinicalSupportStatuses}
                          component={renderRadioGroup}
                          className={classes.input}
                          validate={[required]}
                          qaId="clinical_support_status"
                          disabled
                        />
                      </Grid>
                    </Tooltip>
                  )}
                  {selectedClinicalStatus === ClinicalSupportStatusLabel.Undecided && (
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <Field
                          name="clinical_support_undecided_reason"
                          label="Clinical Support Undecided Reason *"
                          width="90"
                          data-qa-id="clinical_support_undecided_reason_select"
                          component={renderDropdown}
                          fields={getReasonsByStatus(enrollmentStatuses, selectedClinicalStatus)}
                          validate={[required]}
                          disabled={disableClinicalSupportStatusForCDMTherapy}
                        />
                      </Grid>
                      {selectedClinicalReason === 'Patient deferred decision' &&
                        therapy.clinical_support_status_id !== 4 && (
                          <Grid item xs={4}>
                            <Field
                              name="outreach_followup_dt"
                              component={renderDatePicker}
                              label="Follow up Date *"
                              validate={[validateDate, required]}
                            />
                          </Grid>
                        )}
                    </Grid>
                  )}
                  {selectedClinicalStatus === ClinicalSupportStatusLabel.OptOut && (
                    <Grid container spacing={1}>
                      <Grid item xs={7}>
                        <Field
                          name="clinical_support_status_reason"
                          data-qa-id="clinical_support_opt_out_reason_select"
                          label="Reason *"
                          validate={[required]}
                          width="90"
                          onChange={this.handleClinicalReason}
                          multiple
                          initialValues={selectedClinicalOptoutReason || null}
                          component={renderMultipleDropdown}
                          fields={clinicalSupportOptions}
                          disabled={disableClinicalSupportStatusForCDMTherapy}
                        />
                      </Grid>
                      {showClinicalAdditionalReason && (
                        <Grid item xs={5}>
                          <Field
                            name="clinical_support_additional_reason"
                            label="Other Reason *"
                            validate={[required]}
                            component={renderTextField}
                            data-qa-id="clinical_support_other_reason"
                            disabled={disableClinicalSupportStatusForCDMTherapy}
                          />
                        </Grid>
                      )}
                      {cdmProgramName && (
                        <Grid item xs={7}>
                          <Typography className={classes.optOutMessage}>
                            {`Opting out from this therapy will affect clinical support enrollment \
                            of all therapies that are part of the CDM Program \
                            "${cdmProgramName}".`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <ConfirmationPanel
                cancelButtonName="edit_enrollment_cancel_button"
                submitButtonName="edit_enrollment_submit_button"
                handleCancel={this.handleCancel}
                disableSubmit={pristine || submitting}
              />
            </Grid>
          </form>
        </Grid>
      </Grid>
    );
  }
}

function handleFormName(therapy) {
  let formName = `${EDIT_THERAPY_ENROLLMENT}_status`;
  if (therapy) {
    formName += `_${therapy.patient_id}_${therapy.id}`;
  }

  return formName;
}

function mapStateToProps(state, props) {
  const { patient, pharmacies, therapyStatuses, financials, lookups, audit, links } = state;
  const { therapy } = props;
  const { pharmacies: recentPharmacies } = state.userPreferences.recent_items;
  const preferredRxDeliveryPharmacyId = patient.preferred_rx_delivery_pharmacy_id;
  const preferredRxDeliveryPharmacy = preferredRxDeliveryPharmacyId
    ? pharmacies?.pharmacies.find(pharm => pharm.id === preferredRxDeliveryPharmacyId)
    : null;
  const initialOptInPharmacyFromTherapy = pharmacies.pharmacies.find(
    pharm => pharm.npi === therapy.dispensing_pharmacy_npi,
  );
  const singlePharmacyOption =
    pharmacies?.pharmacies.length === 1 ? pharmacies.pharmacies[0] : undefined;
  const initialOptInPharmacy =
    initialOptInPharmacyFromTherapy || preferredRxDeliveryPharmacy || singlePharmacyOption || null;
  const { enrollmentStatuses, therapyDispensingPharmacyReasons, states } = lookups;
  const formName = handleFormName(therapy);
  const selector = formValueSelector(formName);
  const selectedReason = selector(state, 'external_dispensing_reason');
  const selectedAdditionalReason = selector(state, 'external_dispensing_additional_reason');
  const selectedClinicalOptoutReason = selector(state, 'clinical_support_status_reason');
  const selectedClinicalOptoutAdditionalReason = selector(
    state,
    'clinical_support_additional_reason',
  );
  const selectedDispensingStatus = selector(state, 'dispensing_status');
  const selectedDispensingReason = selector(state, 'dispensing_undecided_reason');
  const selectedClinicalStatus = selector(state, 'clinical_support_status');
  const selectedClinicalReason = selector(state, 'clinical_support_undecided_reason');
  const selectedDispensingPharmacyOptin = initialOptInPharmacy
    ? {
        ...initialOptInPharmacy,
        label: initialOptInPharmacy.name,
      }
    : null;
  const selectedDispensingPharmacyOptout = selector(state, 'dispensing_pharmacy_opt_out') || {};
  const selectedTransferPharmacy = selector(state, 'transfer_pharmacy') || {};
  const selectedNeedsByDate = selector(state, 'needsby_date');
  const prescribingMethodId = selector(state, 'prescribing_method_id');
  const medicalInsurances = convertMapToList(financials.medical_insurances.data);
  const pbmInsurances = convertMapToList(financials.pbm_insurances.data);
  const clinicDefinedInTherapy = {
    label: therapy?.managing_clinic_name, // eslint-disable-line
    value: therapy?.managing_clinic_id, // eslint-disable-line
    npi: therapy?.managing_clinic_npi, // eslint-disable-line
  };
  const serviceEnrollmentUndecided = therapy.dispensing_status === DISPENSING_STATUSES.UNDECIDED;

  return {
    auditState: audit,
    patient,
    financials,
    medicalInsurances,
    recentPharmacies,
    internalPharmacies: pharmacies?.pharmacies || [],
    pbmInsurances,
    preferredRxDeliveryPharmacyId,
    preferredRxDeliveryPharmacy,
    therapyStatuses,
    form: formName,
    selectedDispensingStatus,
    selectedClinicalStatus,
    selectedDispensingPharmacyOptin,
    selectedDispensingPharmacyOptout,
    selectedTransferPharmacy,
    selectedReason,
    selectedAdditionalReason,
    selectedDispensingReason,
    selectedClinicalReason,
    selectedClinicalOptoutReason,
    selectedClinicalOptoutAdditionalReason,
    selectedNeedsByDate,
    enrollmentStatuses,
    links,
    therapyDispensingPharmacyReasons,
    states: states.map(({ id, name }) => ({ value: id, label: name })),
    lookups,
    prescribingMethodId,
    therapyFillCycleReasonOptions: getTherapyFillCycleReasonOptions(
      lookups.therapyFillCycleReasons,
      therapy,
    ),
    current_dispensing_status: `${therapy.dispensing_status}`,
    current_clinical_support_status: `${therapy.clinical_support_status}`,
    initialValues: {
      category_id: therapy.category_id,
      clinical_support_status: `${therapy.clinical_support_status}`,
      clinical_support_undecided_reason: getTherapyReasonById(
        enrollmentStatuses,
        therapy.clinical_support_status_id,
      ),
      dispensing_status: `${therapy.dispensing_status}`,
      dispensing_undecided_reason: getTherapyReasonById(
        enrollmentStatuses,
        therapy.dispensing_status_id,
      ),
      clinical_support_status_reason: therapy.clinical_support_status_reason,
      clinical_support_additional_reason: therapy.clinical_support_additional_reason,
      external_dispensing_reason: therapy.external_dispensing_reason,
      external_dispensing_additional_reason: therapy.external_dispensing_additional_reason,
      dispensing_pharmacy_npi: serviceEnrollmentUndecided ? null : therapy.dispensing_pharmacy_npi,
      dispensing_pharmacy_name: serviceEnrollmentUndecided
        ? null
        : therapy.dispensing_pharmacy_name,
      dispensing_pharmacy_opt_in: selectedDispensingPharmacyOptin,
      transfer_pharmacy_npi: therapy.transfer_pharmacy_npi,
      transfer_pharmacy_name: therapy.transfer_pharmacy_name,
      medical_insurance: therapy.medical_insurance
        ? getInitialPayerLockout(therapy.medical_insurance, 'medical', medicalInsurances)
        : getInitialPayerLockout(null, 'medical', medicalInsurances),
      pbm_insurance: therapy.pharmacy_insurance
        ? getInitialPayerLockout(therapy.pharmacy_insurance, 'pbm', pbmInsurances)
        : getInitialPayerLockout(null, 'pbm', pbmInsurances),
      needsby_date: convertToArborDate(therapy.needsby_date, true).getUtcDate(true),
      days_supply: therapy.days_supply,
      is_needsby_per_protocol: therapy.is_needsby_per_protocol,
      one_time_fill: !!therapy.one_time_fill,
      fill_cycle_reason_id: 1,
      service_group_id: therapy.service_group_id,
      referring_clinic: {
        npi: therapy.referring_clinic_npi,
        name: therapy.referring_clinic_name,
        id: therapy.referring_clinic_internal_id,
      },
      prescribing_physician: {
        npi: therapy.prescribing_physician_npi,
        name: therapy.prescribing_physician_name,
      },
      managing_clinic: therapy.managing_clinic_id
        ? getInitialManagingClinicForReactSelect(
            therapy.managing_clinic_id,
            lookups.customerClinics,
            clinicDefinedInTherapy,
          )
        : '',
      prescribing_method_id: !therapy.referring_clinic_npi
        ? PRESCRIBING_CLINIC_METHOD.USE_NULL
        : PRESCRIBING_CLINIC_METHOD.USE_LOOKUP,
      external_dispensing_optout_states: states
        .filter(({ id }) =>
          therapy.optout_states_ids
            ?.split(',')
            ?.map(x => Number(x))
            .includes(id),
        )
        .map(({ id, name }) => ({ value: id, label: name })),
    },
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editTherapy,
    editTasks,
    setTherapyId,
    change: changeActionCreator,
    workListChanged,
    fetchTaskCounts,
    updateLocalUserPreferencesRecentPharmacies,
    resolveAudit,
    notifyError,
  }),
)(reduxForm({})(EditEnrollment));
