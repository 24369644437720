import React, { useCallback, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Typography, Popover, Checkbox, Dialog } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import compose from 'recompose/compose';
import { connect, useSelector } from 'react-redux';
import { change as changeActionCreator } from 'redux-form';
import { goToAnchor } from 'utils/react-scrollable-anchor';
// eslint-disable-next-line max-len
import AddPatientTherapy from 'containers/patient/therapy/add-patient-therapy/add-patient-therapy-AR';
import { LinkIcon, EditAdd, EditClose, EditCheck, EditRemove } from 'components/icons/icons';
import { selectPatientTab } from 'actions/action-patient';
import {
  THERAPY_LEAD_SOURCE_EMR_LEAD,
  ADD_PATIENT_THERAPY_FORM,
  THERAPY_AS_NEEDED_PROPERTY,
  THERAPY_ONE_TIME_FILL_PROPERTY,
  THERAPY_MEDICATION_STATUS_NEW,
  APPOINTMENT_REFERRAL_VALIDATE_ID,
  EXCLUDE_AR_THERAPY_CDM_DISPENSING_OPT_OUT,
} from 'constants/index';
import { ModalWithTabs } from 'components/modal-with-tabs/modal-with-tabs';
import { PATIENT_ENROLLMENT_TAB_ID } from 'components/patient-enrollment-tab/index';
import { ADD_TAB_ID } from 'components/modal-with-tabs/types';
import DrugCopySelector from 'containers/patient/therapy/fields/drug-copy-selector';

import { Add as AddIcon } from '@mui/icons-material';
import { uniqueId } from 'lodash';
import { styles } from './schedule-outreach-details-styles';
import ScheduleOutreachTherapyLinking from './schedule-outreach-therapy-linking';
import ScheduleOutreachTherapyExcluding from './schedule-outreach-therapy-excluding';
import ScheduleOutreachTherapyDispenseOptOut from './schedule-outreach-therapy-dispensing-opt-out';
import ScheduleOutreachTherapyAdding from './schedule-outreach-therapy-adding';
import { withCustomer } from '../../../../helpers/router';
import store from '../../../../store';
import { windowFeatureIsEnabled } from '../../../../config/window-features';

const ADD_TAB = {
  id: ADD_TAB_ID,
  label: '',
  icon: <AddIcon />,
};
export const PATIENT_ENROLLMENT_TAB = {
  id: PATIENT_ENROLLMENT_TAB_ID,
  label: 'Patient Enrollment',
};
function ScheduleOutreachTherapies(props) {
  const {
    classes,
    patientId,
    scheduleOutreach,
    setTaskStatus,
    managingClinics,
    history,
    change,
    therapiesKeys,
  } = props;
  const { drugs } = scheduleOutreach;

  const therapyDispensingPharmacyReasons = useSelector(
    state => state.lookups.therapyDispensingPharmacyReasons,
  );

  const [isTherapyModalOpen, setIsTherapyModalOpen] = useState(false);
  const [isTargetModalOpen, setIsTargetModalOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const [excludeDrugAnchorEl, setExcludeDrugAnchorEl] = useState(null);
  const [dispensingOptOutDrugAnchorEl, setDispensingOptOutDrugAnchorEl] = useState(null);

  const [checkedDrugs, setCheckedDrugs] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(null);
  const [drugCopyFrom, setDrugCopyFrom] = useState(null);
  const [tabs, setTabs] = useState([]);
  const tabsRef = useRef(null);

  const [selectedExcludeDrugs, setSelectedExcludeDrugs] = useState([]);
  const [selectedDispenseOptOutDrugs, setSelectedDispenseOptOutDrugs] = useState([]);

  const toggleAddTherapyModal = () => {
    setIsTherapyModalOpen(!isTherapyModalOpen);
    // eslint-disable-next-line no-unused-expressions
    tabsRef.current?.setSelectedTab(0);
  };
  const toggleAddTargetModal = () => setIsTargetModalOpen(!isTargetModalOpen);

  const handleAddTherapy = (_, drug) => {
    setSelectedDrug(drug);
    setTabs([
      PATIENT_ENROLLMENT_TAB,
      {
        label: drug.drug_name,
        id: drug.id,
      },
      ADD_TAB,
    ]);
    setDrugCopyFrom(drug);
    toggleAddTherapyModal();
  };

  const handleExcludeDrug = (event, drug) => {
    setExcludeDrugAnchorEl(event.currentTarget);
    setSelectedExcludeDrugs([drug]);
  };

  const handleDrugDispenseOptOut = (event, drug) => {
    setDispensingOptOutDrugAnchorEl(event.currentTarget);
    setSelectedDispenseOptOutDrugs([drug]);
  };

  const handleLinkTherapy = (event, drug) => {
    setSelectedDrug(drug);
    setAnchorEl(event.currentTarget);
  };

  const handleRedirectToTherapy = therapyId => {
    const redirectLinkToTherapy = `/patients/${patientId}/therapies?sidebar=tasks`;
    history.push(withCustomer(redirectLinkToTherapy));
    goToAnchor(`THERAPY_${therapyId}`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setExcludeDrugAnchorEl(null);
    setDispensingOptOutDrugAnchorEl(null);
  };

  const getDrugById = id =>
    drugs.find(drug => drug.id === id) || { schedule_outreach_id: scheduleOutreach.id };
  const pendingDrugs = drugs.filter(drug => !drug.is_excluded && !drug.therapy_id);
  const renderDrugs = (drug, index) => {
    let linkOptions;
    const indexDrugIdPrefix = `${index}`;
    const specialtyType = drug.specialty_type ? `- ${drug.specialty_type}` : '';
    if (drug.is_excluded === EXCLUDE_AR_THERAPY_CDM_DISPENSING_OPT_OUT) {
      const drugDispensingOptOutReasonsArr = drug.excluded_reason
        .split(',')
        .map(val => Number(val));
      const drugDispensingOptOutReasonsStr = drugDispensingOptOutReasonsArr
        .map(v => therapyDispensingPharmacyReasons.find(reason => reason.value === v))
        .map(r => r?.label)
        .join(', ');
      const excludedStr = `CDM Dispense Opt out: ${drugDispensingOptOutReasonsStr}`;
      linkOptions = (
        <Grid item xs align="right">
          <Typography className={classes.excludeWording} variant="h6" color="error">
            {excludedStr}
          </Typography>
        </Grid>
      );
    } else if (drug.is_excluded) {
      const excludedStr = `Excluded: ${drug.excluded_reason}`;
      linkOptions = (
        <Grid item xs align="right">
          <Typography className={classes.excludeWording} variant="h6" color="error">
            {excludedStr}
          </Typography>
        </Grid>
      );
    } else if (
      !drug.therapy_id &&
      (String(drug.specialty_type).toLowerCase().includes('cdm') ||
        String(drug.is_specialty_drug).toLowerCase() === 'n') &&
      scheduleOutreach.status_id === APPOINTMENT_REFERRAL_VALIDATE_ID
    ) {
      linkOptions = (
        <Grid item xs align="right">
          <Button
            onClick={event => handleDrugDispenseOptOut(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_link_button`}
          >
            <EditClose style={{ color: 'white' }} />
          </Button>
          <Button
            onClick={event => handleAddTherapy(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_add_button`}
          >
            <EditAdd style={{ color: 'white' }} />
          </Button>
          <Button
            onClick={event => handleExcludeDrug(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_minus_button`}
          >
            <EditRemove style={{ color: 'white' }} />
          </Button>
          <Button
            onClick={event => handleLinkTherapy(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_link_button`}
            disabled={!therapiesKeys.length}
          >
            <LinkIcon style={{ color: 'white' }} />
          </Button>
        </Grid>
      );
    } else if (!drug.therapy_id) {
      linkOptions = (
        <Grid item xs align="right">
          <Button
            onClick={event => handleAddTherapy(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_add_button`}
          >
            <EditAdd style={{ color: 'white' }} />
          </Button>
          <Button
            onClick={event => handleExcludeDrug(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_minus_button`}
          >
            <EditRemove style={{ color: 'white' }} />
          </Button>
          <Button
            onClick={event => handleLinkTherapy(event, drug)}
            variant="contained"
            className={classes.drugButton}
            id={`${indexDrugIdPrefix}_link_button`}
            disabled={!therapiesKeys.length}
          >
            <LinkIcon style={{ color: 'white' }} />
          </Button>
        </Grid>
      );
    } else {
      linkOptions = (
        <Grid item xs align="right">
          <Button
            onClick={() => handleRedirectToTherapy(drug.therapy_id)}
            variant="contained"
            className={classes.linkedDrugButton}
          >
            <LinkIcon />
            <span className={classes.linkOptionsSpan}>
              {`${drug.drug_name} ${drug.strength || ''} ${specialtyType}`}
            </span>
          </Button>
        </Grid>
      );
    }

    const handleCheckboxOnChange = e => {
      if (e.target.checked) {
        setCheckedDrugs([...checkedDrugs, drug.id]);
      } else {
        setCheckedDrugs(checkedDrugs.filter(drugId => drugId !== drug.id));
      }
    };

    return (
      <div className={classes.drugRow} key={index}>
        <Grid container spacing={7} alignItems="center">
          <Grid item>
            <Typography
              className={classes.selectAllTherapies}
              id={`${indexDrugIdPrefix}_drug_name`}
            >
              {!drug.is_excluded && !drug.therapy_id && (
                <Checkbox
                  checked={checkedDrugs.includes(drug.id)}
                  color="primary"
                  onChange={handleCheckboxOnChange}
                />
              )}
              {`${drug.drug_name} ${drug.strength || ''} ${specialtyType}`}
            </Typography>
          </Grid>
          {linkOptions}
        </Grid>
      </div>
    );
  };

  const open = Boolean(anchorEl);
  const excludeDrugOpen = Boolean(excludeDrugAnchorEl);
  const dispensingOptOutDrugOpen = Boolean(dispensingOptOutDrugAnchorEl);

  const initialTherapyValues = {
    serviceGroupId: scheduleOutreach.service_group_id,
    rxTypeId: `${THERAPY_MEDICATION_STATUS_NEW}`,
    leadSourceId: THERAPY_LEAD_SOURCE_EMR_LEAD,
    managingClinicNpi: managingClinics.find(
      clinic => clinic.name === scheduleOutreach.source_facility_id,
    )
      ? managingClinics.find(clinic => clinic.name === scheduleOutreach.source_facility_id).npi
      : null,
    managingClinicName: scheduleOutreach.source_facility_id,
    referringClinicInternalId: managingClinics.find(
      clinic => clinic.name === scheduleOutreach.source_facility_id,
    )
      ? managingClinics.find(clinic => clinic.name === scheduleOutreach.source_facility_id).id
      : null,
    referringClinicName: scheduleOutreach.source_facility_id,
    managingClinicNameAndId: managingClinics.find(
      clinic => clinic.name === scheduleOutreach.source_facility_id,
    )
      ? managingClinics
          .map(clinic => ({
            label: clinic.name,
            value: clinic.id,
            npi: clinic.npi,
          }))
          .find(clinic => clinic.label === scheduleOutreach.source_facility_id)
      : null,
    clinicianName: scheduleOutreach.clinician_name,
  };

  const handleSelectAllTherapiesOnChange = useCallback(
    e => {
      if (e.target.checked) {
        setCheckedDrugs(pendingDrugs.map(d => d.id));
      } else {
        setCheckedDrugs([]);
      }
    },
    [checkedDrugs],
  );

  const handleValueChange = (drug, field, value) => {
    if (drugCopyFrom && drug.id === drugCopyFrom.id) {
      tabs.forEach(tab => {
        const formId = `${ADD_PATIENT_THERAPY_FORM}-${tab.id}`;
        if (![THERAPY_AS_NEEDED_PROPERTY, THERAPY_ONE_TIME_FILL_PROPERTY].includes(field)) {
          change(formId, field, value);
        }
      });
    }
  };

  const handleCloseTab = closingTab => {
    const tabsIds = tabs.map(tab => tab.id);
    const idx = tabsIds.indexOf(closingTab.id);
    const newTabs = [...tabs.filter(tab => tab.id !== closingTab.id)];

    setTabs(newTabs);

    if (newTabs.length === 2) {
      toggleAddTherapyModal();
    }

    const newSelectedTab = newTabs[idx];
    if (newSelectedTab?.id === ADD_TAB_ID) {
      // eslint-disable-next-line no-unused-expressions
      tabsRef.current?.setSelectedTab(idx - 1);
    }
  };

  const getNewMedTab = () => ({
    id: uniqueId(ADD_TAB_ID),
    label: `New medication #${tabs.length - checkedDrugs.length}`,
  });

  const handleTabChange = tab => {
    if (tab.id !== ADD_TAB_ID) {
      return;
    }

    const newTab = getNewMedTab();
    const newTabs = [
      PATIENT_ENROLLMENT_TAB,
      ...tabs.filter(_tab => _tab.id !== ADD_TAB_ID && _tab.id !== PATIENT_ENROLLMENT_TAB_ID),
      newTab,
      ADD_TAB,
    ];

    setTabs(newTabs);
    // eslint-disable-next-line no-unused-expressions
    tabsRef.current?.setSelectedTab(newTabs.length - 2);

    if (drugCopyFrom) {
      const sourceFormId = `${ADD_PATIENT_THERAPY_FORM}-${drugCopyFrom.id}`;
      const sourceForm = store.getState().form[sourceFormId];
      const targetFormId = `${ADD_PATIENT_THERAPY_FORM}-${newTab.id}`;
      const dontCopyFromFields = [
        'source',
        'tertiary_diagnosis',
        'secondary_diagnosis',
        'rx_type_id',
        'diagnosis',
        'start_date',
        'end_date',
        'emr_status',
        'therapy',
        'is_documenting_only',
        'days_supply',
      ];
      setTimeout(() => {
        Object.keys(sourceForm.values)
          .filter(field => !dontCopyFromFields.includes(field))
          .forEach(field => {
            change(targetFormId, field, sourceForm.values[field]);
          });
      }, 1000);
      // do this to trigger onChange action to all fields and fill the new form
    }
  };

  return (
    <Grid container alignItems="center">
      {pendingDrugs.length > 0 && (
        <>
          <Grid item xs={6}>
            <Typography className={classes.selectAllTherapies}>
              <Checkbox
                checked={checkedDrugs.length === pendingDrugs.length}
                color="primary"
                onChange={handleSelectAllTherapiesOnChange}
              />
              Select All Therapies
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            {checkedDrugs.length > 0 && (
              <>
                <Button
                  id="validate_outcomes_button"
                  className={classes.outcomeButton}
                  onClick={() => {
                    setSelectedDrug(null);
                    // Filter the selected drugs to only include those that are still pending
                    const selectedDrugs = pendingDrugs
                      .map(pd => pd.id)
                      .filter(pendingId => checkedDrugs.includes(pendingId));
                    setCheckedDrugs(selectedDrugs);
                    const tbs = selectedDrugs.map(drugId => ({
                      id: drugId,
                      label: getDrugById(drugId).drug_name,
                    }));
                    setTabs([PATIENT_ENROLLMENT_TAB, ...tbs, ADD_TAB]);
                    setDrugCopyFrom(getDrugById(selectedDrugs[0]));
                    toggleAddTherapyModal();
                  }}
                >
                  VALIDATE
                </Button>
                <Button
                  id="decline_outcomes_button"
                  className={classes.outcomeButton}
                  onClick={event => {
                    setSelectedExcludeDrugs(pendingDrugs.filter(d => checkedDrugs.includes(d.id)));
                    setExcludeDrugAnchorEl(event.currentTarget);
                  }}
                >
                  DECLINE
                </Button>
              </>
            )}
            <Button
              id="add_target_button"
              className={classes.outcomeButton}
              onClick={() => {
                toggleAddTargetModal();
              }}
            >
              ADD TARGET
            </Button>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        {drugs && drugs.map((drug, index) => renderDrugs(drug, index))}
      </Grid>

      <ModalWithTabs
        open={isTherapyModalOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            toggleAddTherapyModal();
          }
        }}
        tabs={tabs}
        ref={tabsRef}
        onTabClose={handleCloseTab}
        onTabChange={handleTabChange}
        tabPropsGetter={tab => ({
          selectedDrug: getDrugById(tab.id),
          handleCloseAddForm: () => handleCloseTab(tab),
          initialTherapyValues: {
            ...initialTherapyValues,
            initialTherapyName: getDrugById(tab.id).drug_name,
          },
          id: tab.id,
          disableTherapyInput: !String(tab.id).startsWith(ADD_TAB_ID),
        })}
        instructions={
          (selectedDrug || checkedDrugs.length > 1) && (
            <DrugCopySelector
              drugs={selectedDrug ? [selectedDrug] : checkedDrugs.map(id => getDrugById(id))}
              onChange={setDrugCopyFrom}
            />
          )
        }
      >
        <AddPatientTherapy
          arDrug
          patientId={patientId}
          onValueChange={handleValueChange}
          arTask={scheduleOutreach}
        />
      </ModalWithTabs>

      <Popover
        id="link-therapy-popover"
        classes={{
          paper: classes.popoverWithOverflow,
        }}
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ScheduleOutreachTherapyLinking
          scheduleOutreachId={scheduleOutreach.id}
          drug={selectedDrug}
          classes={classes}
          handleClose={() => setAnchorEl(null)}
        />
      </Popover>
      <Dialog id="exclude-drug-popover" open={!!excludeDrugOpen} onClose={handlePopoverClose}>
        <ScheduleOutreachTherapyExcluding
          scheduleOutreachId={scheduleOutreach.id}
          selectedDrugs={selectedExcludeDrugs}
          drugs={drugs}
          handleClose={() => setExcludeDrugAnchorEl(null)}
          setTaskStatus={setTaskStatus}
        />
      </Dialog>
      <Dialog
        id="dispensing-opt-out-drug-popover"
        open={!!dispensingOptOutDrugOpen}
        onClose={handlePopoverClose}
      >
        <ScheduleOutreachTherapyDispenseOptOut
          scheduleOutreachId={scheduleOutreach.id}
          selectedDrugs={selectedDispenseOptOutDrugs}
          drugs={drugs}
          handleClose={() => setDispensingOptOutDrugAnchorEl(null)}
          setTaskStatus={setTaskStatus}
        />
      </Dialog>
      <Dialog
        id="add-patient-popover"
        open={!!isTargetModalOpen}
        onClose={toggleAddTargetModal}
        maxWidth="lg"
      >
        <ScheduleOutreachTherapyAdding
          taskId={scheduleOutreach.id}
          handleClose={toggleAddTargetModal}
        />
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = state => {
  // Have to add scheduleOutreachList here to re-render if schedule outreach reducer state changed,
  // Otherwise this children component won't re-render if the parent's props changed
  const { patient, lookups, scheduleOutreachList, therapies } = state;
  return {
    patientId: patient.id,
    managingClinics: lookups.customerClinics,
    scheduleOutreachList,
    therapiesKeys: Object.keys(therapies.data),
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { selectPatientTab, change: changeActionCreator }),
  withRouter,
)(ScheduleOutreachTherapies);
