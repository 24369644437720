/* eslint-disable @typescript-eslint/ban-types */
import moment from 'moment';
import { Close as CloseIcon, Info as InfoIcon, Warning as WarningIcon } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { Grid, Link, Button, Typography, Tooltip, Box } from '@mui/material';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import cx from 'classnames';
import _, { isEmpty } from 'lodash';
import DebouncedNumericField from 'components/form/field/debounced-numeric-field';
import FieldLabel from 'components/form/field/field-label';
import FieldTitle from 'components/form/field/field-title';
import MedicationList from 'containers/patient/clinical/medication-list/medication-list';
import NoteArea from 'components/note/NoteArea';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import renderLocation from 'components/dynamic-form/renders/render-location';
import renderWeather from 'components/dynamic-form/renders/render-weather';
import { addAddressModalActions } from 'actions/action-add-address-modal';
import { addEmailModalActions } from 'actions/action-add-email-modal';
import { addPaymentMethodModalActions } from 'actions/action-add-payment-method-modal';
import { addPhoneModalActions } from 'actions/action-add-phone-modal';
import { fetchNotes } from 'actions/action-notes';
import { StringUtils } from 'utils/string-utils';
import {
  AncillarySupplyValues,
  DeliveryMethodLabel,
  DeliveryMethodValue,
  FCStatus,
  MobilePhoneSmsEnum,
} from 'constants/enums';
import { buildCausedByOptions } from 'containers/tasks/common/utils/intervention-utils';
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import {
  CAIcon,
  DSIcon,
  EditAdd,
  PillIcon,
  PRNIcon,
  APIcon,
  getCMIcon,
  MedicarePartBIcon,
} from 'components/icons/icons';
import { PhoneUsage } from 'interfaces/redux/IPhone';
import { FillCoordinationStatusMap } from 'constants/task-statuses';
import { getAllergiesOrderedBySeverity } from 'services/utils/allergy-service';
import { getGroupedMedications } from 'hooks/getGroupedMedications';
import { getReasonsByStatus, isTaskCompleted } from 'services/utils/task-service';
import { IFillCoordinationTask } from 'interfaces/redux/task-types/IFillCoordinationTask';
import { ITaskStatus } from 'interfaces/ITaskStatus';
import { ITherapy } from 'interfaces/redux/ITherapy';
import Communication from 'components/note/communication';
import Intervention from 'components/note/intervention';
import Incident from 'components/note/incident';
import SingleValueCheckboxGroup from 'components/form/field/single-field-checkbox-group/single-field-checkbox-group';
import { linkTherapiesMedications, sortMedications } from 'services/utils/medication-service';
import { nameOfFactory } from 'utils/types-util';
import { NumberOption } from 'interfaces/forms/types';
import { ReactSelectForReduxSingle } from 'components/form/field/react-select';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { staticProviders } from 'components/dynamic-form/providers/common-provider';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import ProblemsList from 'components/problems/container';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';
import { ArborDate, convertToArborDate } from 'models/time/arbor-date';
import { ViewModeEnum } from 'containers/add-payment-method/add-payment-method-modal';
import {
  PATIENT_ALLERGY_STATUS_ACTIVE,
  MEDICATION_THERAPY_STATUS_ACTIVE,
  PATIENT_RX_DELIVERY_PREFERENCES,
  taskAbbreviationMap,
  FC,
  THERAPY_ADHERENCE_PACKAGE,
  THERAPY_MED_SYNC,
  OPT_IN,
  SET_SHOW_SET_SELECTED_TASKS,
  UPDATE_VALIDATED_ADDRESS,
} from 'constants/index';

import {
  getFullAddressList,
  getFullEmailList,
  getFullPhoneList,
} from 'services/utils/demographic-service';
import {
  renderDropdown,
  renderReactRadioGroupFC,
  renderDebouncedTextField,
  renderMoneyTextField,
  renderMultipleDropdownAsync,
  renderCheckboxInline,
  renderCheckbox,
  renderReactRadioGroup as RenderRadioGroup,
  renderDropdown as RenderDropdown,
  renderTextField as RenderTextField,
  renderTextField,
} from 'components/form/field/redux-field';
import {
  validateDateTime,
  validateDate,
  defined,
  definedBoolean,
  required,
  optionSelected,
  noDrugNameValidation,
} from 'components/form/validation/validation';
import {
  methodOfDelivery,
  methodOptions,
  ancillarySupplies,
  deliveryShippingMethodMap,
  defaultPreferencesAncillarySupplies,
  fcInProgressReasons,
  noteTagTypes,
} from 'constants/lists';

import PatientAllergiesList, {
  RENDERING_SECTIONS,
} from 'containers/patient/clinical/allergies/patient-allergies-list';
import { buildQaId } from 'utils/build-qa-id';
import { formatTherapyNameLong } from 'services/utils/therapy-service';
import { dosageFormToUIDisplay } from 'services/utils/dosageFormToUIDisplay';
import { InterventionTypeId } from 'interfaces/enums/InterventionType';
import { TherapyUtil } from 'utils/therapy-util';
import { windowFeatureIsEnabled } from 'config/window-features';
import {
  MedSyncStatusIcon,
  MedSync as MedSyncIcon,
} from 'containers/patient/med-sync/components/medSyncIcon';
import {
  FillDeliveryConfirmationStatus as FDCStatus,
  FillDeliveryConfirmationKeys as FDCKeys,
} from 'interfaces/enums/TaskStatuses/FillDeliveryConfirmationStatus';
import { integrateUpdatedTasks, selectTaskKey } from 'actions/action-tasks';
import { selectTherapies } from 'containers/patient/med-sync/med-sync-state';
import { useDeepDiffSelector } from 'utils/deep-diff-props';
import NinetyDaysBanner from 'components/ninety-days-banner';
import { HospitalVisit } from './clinical-fields/hospital-visit';
import DeliveryIntegrationWarning from './components/delivery-integration';
import HTTP from '../../../services/http';
import { notifyError, notifySuccess } from '../../../actions/action-notifications';
import IconWithTooltip from '../../../indicators/indicators';
import { BiForTherapy } from './bi-sections/bi-for-therapy';
import { NoBiForTherapy } from './bi-sections/no-bi-for-therapy';
import { NoPbmInsurance } from './bi-sections/no-pbm-insurance';
import {
  IFillCoordinationEditFormFields,
  IProps,
  SideEffectType,
  EditComponentMode,
} from './types';
import {
  buildFieldSettings,
  concatWithDefinedValidator,
  getDefaultSideEffectsInt,
  buildDetails,
  getDispensingPharmacyOptions,
  filterIntsBySideEffectType,
  getDeliveryMethodLogo,
  isAddressRequired,
  coldChainDisabled,
  getPreferredRxDeliveryAddress,
  deliveryMethod,
} from './fc-util';
import { styles } from './fill-coordination.styles';
import { AreaShippingClient } from '../../../clients/area-shipping-client';
import { AddressValidationClient } from '../../../clients/address-validation-client';
import AddressVaildationIcon from './components/address-validation-icon';
import AddressValidationModal from './components/address-validation-modal';
import NoteFieldCharacterLimitMessage from '../../../components/note-field-character-notice/note-field-character-notice';
import { SecureFCClient } from '../../../clients/secure-fc-client';
import PaymentMethodsSelectSection from './payment-methods/payment-methods-section';
import SecureLinkClinicalResponse from './components/secure-link-clinical-response';
import { IAddress } from '../../../interfaces/redux/IAddress';
import { IState } from '../../../interfaces/redux/IState';
import ConfirmationDialogPanel from '../../../components/form/confirmation/confirmation-dialog-panel';
import { MedSyncDisplayStatus } from '../../patient/med-sync/common';
import { getLinkedFCTaskForId, getTaskKey } from '../../patient/tasks-new/tasks-table/utils';
import { benefitsInvestigationSubmissionFormActions } from '../../../slices/benefits-investigation-modal-slice';
import { usePrevious } from '../../../hooks/usePrevious';
import { fetchHiddenQuestions, refreshBiHistory } from '../../../actions/action-patient';

const OVERRIDE_PATIENT_PREFERENCES_MESSAGE = "This is not the patient's preference";
const RESEND_BUTTON_TEXT = 'Resend Link';
const ORDER_NOTES_LIMIT = 50;

const nameOfFormFields = nameOfFactory<IFillCoordinationEditFormFields>();
const getFieldNamePerTask = (
  fieldName: keyof IFillCoordinationEditFormFields,
  task: IFillCoordinationTask,
) => `${fieldName}.${task.id}`;

const getStatus = (statusId: number): ITaskStatus | undefined => {
  const matchingStatus = FillCoordinationStatusMap[statusId];
  return matchingStatus;
};

const controlledSubstanceList = [2, 3, 4, 5];
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' || // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' || // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const noOp = () => {};

type IReduxFormProps = InjectedFormProps<IFillCoordinationEditFormFields>;

type Props = IProps &
  IReduxFormProps & {
    collection_method_changed: Function;
    fcTaskIds: number[];
    changeSelectedStatus: Function;
    closeTab?: Function;
    handleOnSelectedRow: Function;
    setRemovedFCIds: Function;
    removedFCIds: number[];
    setShowSendSecureLinkWarning: Function;
  } & WithStyles<typeof styles>;

const missing = () => 'Required';
const qaIdBuilder = buildQaId('dosing-regimen', '.');

const missingReactionValidation = (_value: string): string => {
  return 'Specify Reaction(s)';
};
const missingSuspectedCausedByValidation = (_value: string): string => {
  return 'Specify Suspected Caused By';
};
const missingMedicationValidation = (_value: string): string => {
  return 'Specify Medication(s)';
};
const missingDetailsValidation = (_value: string): string => {
  return 'Specify Details';
};
const missingAddressValidation = (val: any) =>
  !val || !val.value || val.value < 0 ? (
    <div style={{ color: 'red' }}>Select an option from dropdown</div>
  ) : undefined;
const valid = (val: any) => undefined;

const showPreferredDeliveryDates = (_deliveryMethod: DeliveryMethodValue): boolean =>
  windowFeatureIsEnabled('area_shipping') && _deliveryMethod === DeliveryMethodValue.Courier;

const getDeliveryContext = (formValues?: any) => {
  const deliveryMethodValue = formValues.preferred_rx_delivery_method?.value;
  const isDelivery = deliveryMethodValue && deliveryMethodValue !== DeliveryMethodValue.PickUp;
  const shipDate = formValues?.ship_date ? moment(formValues.ship_date, 'MM/DD/YYYY', true) : null;

  return [shipDate, isDelivery];
};

const validateDeliveryOnOrAfterShipDate = (value: any, formValues?: any) => {
  const [shipDate, isDelivery] = getDeliveryContext(formValues);
  if (isDelivery && value && shipDate) {
    const deliveryDt = moment(value, 'MM/DD/YYYY', true);

    if (shipDate.isAfter(deliveryDt)) {
      return 'Must be on or after ship date.';
    }
  }
  return undefined;
};

const compareArrays = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  if (arr1.reduce((acc, item) => acc && arr2.includes(item), true)) {
    return false;
  }
  if (arr2.reduce((acc, item) => acc && arr1.includes(item), true)) {
    return false;
  }
  return true;
};

const validateShipBeforeDeliveryDate = (value: any, formValues?: any) => {
  const [shipDate, isDelivery] = getDeliveryContext(formValues);
  if (isDelivery && value && shipDate) {
    const deliveryDt = moment(value, 'MM/DD/YYYY', true);

    if (shipDate.isAfter(deliveryDt)) {
      return 'Must be on or after ship date.';
    }
    if (shipDate.isSame(deliveryDt)) {
      return 'The delivery date cannot be the same as the ship date.';
    }
  }
  return undefined;
};

const FillCoordinationEditForm: React.FC<Props> = (props): JSX.Element => {
  const { classes, FDCStatusReason, removedFCIds, setRemovedFCIds } = props;
  const dispatch = useDispatch();
  const [inNoteArea, setInNoteArea] = useState(false);
  const [inOrderNoteArea, setInOrderNoteArea] = useState(false);
  const numberOfGroupedFCs = (props.groupedFCIds ?? []).length;
  const task = useMemo(
    () => props.tasks[0] || props.siblingTasks[0],
    [props.tasks, props.siblingTasks],
  );
  const adherecePackageRequired = props?.therapies?.[task.therapy_id]?.adherence_packaging;

  const getQaId = (fieldName: string): string => {
    return `fill-coordination-edit-form-${fieldName}`;
  };
  const startingStatus = props.tasks[0].status_id;

  const therapiesById = useDeepDiffSelector(selectTherapies);
  const crudeFCIdsHash = [...props.allTasks]
    .map(fc => fc.id)
    .sort((a, b) => a - b)
    .join(',');
  const therapyIdsInBundle = props.allTasks.map(task => task.therapy_id);
  const patientTherapies = useTypedSelector(state => state.therapies.data || {});
  const addNewMedicationFormIsOpenAndWaiting = useTypedSelector(
    state => state.form?.AddPatientTherapy?.values?.is_documenting_only === false,
  );
  const customerPharmacies = useTypedSelector(state => state.pharmacies.pharmacies || {});
  const preferredRxDeliveryPharmacyId = useTypedSelector(
    state => state.patient.preferred_rx_delivery_pharmacy_id,
  );
  const preferredRxDeliveryPharmacy = useTypedSelector(state =>
    state.pharmacies.pharmacies.find(pharm => pharm.id === preferredRxDeliveryPharmacyId),
  );
  const showSendSecureLinkWarning = therapyIdsInBundle.every(
    id => therapiesById?.[id]?.is_specialty == false && therapiesById?.[id]?.as_needed == true,
  );
  const fcsById = props.allTasks.reduce((acc: Record<string, any>, task: any) => {
    acc[task.id] = task;
    return acc;
  }, {});
  const removedTherapyIds = removedFCIds.reduce((acc: number[], fc_id: number): number[] => {
    const { therapy_id } = fcsById[fc_id] || {};
    if (!therapy_id) {
      return acc;
    }
    acc.push(therapy_id);
    return acc;
  }, []);
  const containsAControlledSubstance = therapyIdsInBundle
    .filter(id => !removedTherapyIds.includes(id))
    .reduce(
      (acc, therapyId) =>
        acc ||
        controlledSubstanceList.includes(
          Number(patientTherapies[therapyId]?.controlled_substance_code),
        ),
      false,
    );
  const containsAClinicAdministeredTherapy = therapyIdsInBundle
    .filter(id => !removedTherapyIds.includes(id))
    .reduce(
      (acc, therapyId) => acc || Boolean(patientTherapies[therapyId]?.clinic_administered),
      false,
    );
  const containsaNonClinicAdministeredTherapy = therapyIdsInBundle
    .filter(id => !removedTherapyIds.includes(id))
    .reduce(
      (acc, therapyId) => acc || Boolean(!patientTherapies[therapyId]?.clinic_administered),
      false,
    );
  const containsCAandNonCATherapies =
    containsAClinicAdministeredTherapy && containsaNonClinicAdministeredTherapy;

  const orderContainsAMedicarePartBTherapy = therapyIdsInBundle
    .filter(id => !removedTherapyIds.includes(id))
    .some(therapyId => therapiesById[therapyId]?.medicare_part_b);

  const mostPrevelantDispensingPharmacyInBundle: Record<string, any> = useMemo(() => {
    if (preferredRxDeliveryPharmacy) {
      return {};
    }
    const bundleOnlyContainsNonSpecialtyTherapies = !therapyIdsInBundle.some(
      id => patientTherapies[id].is_specialty,
    );
    const customerPharmaciesByNPI: Record<string, any> = {};
    Object.values(customerPharmacies).forEach(item => (customerPharmaciesByNPI[item.npi] = item));
    const dispensingPharmacyCountByNPIInBundle: Record<string, any> = {};
    let mostPrevelantPharmacy = {
      name: 'Unable to determine the dispensing pharmacy from therapies in the bundle',
      npi: '0',
    };
    therapyIdsInBundle.forEach(therapy_id => {
      const is_specialty = patientTherapies[therapy_id]?.is_specialty;
      const pharmacy_npi = patientTherapies[therapy_id]?.dispensing_pharmacy_npi;
      if (!pharmacy_npi || (!is_specialty && !bundleOnlyContainsNonSpecialtyTherapies)) {
        return;
      }
      if (!dispensingPharmacyCountByNPIInBundle[pharmacy_npi]) {
        dispensingPharmacyCountByNPIInBundle[pharmacy_npi] = { count: 1 };
        dispensingPharmacyCountByNPIInBundle[pharmacy_npi].name =
          patientTherapies[therapy_id].dispensing_pharmacy_name;
        dispensingPharmacyCountByNPIInBundle[pharmacy_npi].npi = pharmacy_npi;
      } else {
        const current = dispensingPharmacyCountByNPIInBundle[pharmacy_npi].count;
        dispensingPharmacyCountByNPIInBundle[pharmacy_npi].count = current + 1;
      }
    });
    let highestCount = 0;
    Object.keys(dispensingPharmacyCountByNPIInBundle).forEach(npi => {
      if (dispensingPharmacyCountByNPIInBundle[npi].count > highestCount) {
        highestCount = dispensingPharmacyCountByNPIInBundle[npi].count;
        mostPrevelantPharmacy = dispensingPharmacyCountByNPIInBundle[npi];
      }
    });
    return mostPrevelantPharmacy;
  }, [props.allTasks]);
  const therapies = useMemo(() => Object.values(therapiesById), [therapiesById]);

  const drugNameValidation = useMemo(
    () => noDrugNameValidation(therapies.find(t => t.id === props.tasks[0].therapy_id)?.drug_name),
    [therapies, props.tasks],
  );

  const therapiesToRemove = useMemo(
    () =>
      (props.tasks[0].therapy_ids_to_remove || [])
        .map(therapyId => therapiesById[therapyId])
        .filter(Boolean),
    [props.tasks, therapiesById],
  );
  const therapiestoRemoveAsString = useMemo(
    () =>
      therapiesToRemove.length
        ? therapiesToRemove
            .map(
              therapy =>
                `  ${therapy.drug_name} ${therapy.dosage_form} ${therapy.strength} ${therapy.strength_unit_of_measure}`,
            )
            .join(',')
        : null,
    [therapiesToRemove],
  );

  const missingFCTaskIds = useMemo(() => {
    return Array.isArray(props.tasks[0]?.fc_ids_in_bundle) && props.fcTaskIds
      ? props.tasks[0].fc_ids_in_bundle
          .filter(item => !props.fcTaskIds.includes(item))
          .map(id => getTaskKey({ id, taskType: FC }))
      : [];
  }, [props.fcTaskIds, props.tasks]);

  const addSelections = useCallback(() => {
    dispatch(selectTaskKey(missingFCTaskIds));
  }, [dispatch, missingFCTaskIds]);

  const selectedNextStatus = props.selectedStatus;
  const [addressPreferenceMismatch, setAddressPreferenceMismatch] = useState(false);
  const [validationDetails, setValidationDetails] = useState<any | null>(null);
  const [callPatientReasons, setCallPatientReasons] = useState<string[]>([]);
  const [clinicalResponses, setClinicalResponses] = useState<any>({});
  const [sendLinkButtonText, setSendLinkButtonText] = useState('Send Link to Patient');
  const [resendLinkButtonText, setResendLinkButtonText] = useState(RESEND_BUTTON_TEXT);
  const [deactivateWarningConfig, setDeactivateWarningConfig] = useState({
    show: false,
    cancel: () => {},
    continue: () => {},
  });
  const [fcssFormIsSubmitting, setFCssFormIsSubmitting] = useState(false);
  const [deactivateLinkButtonText, setDeactiveLinkButtonText] = useState('Deactivate Link');
  const collectionMethodChanged = props.collection_method_changed || noOp;
  const taskStatus = getStatus(props.selectedStatus);
  const includesOnlyFirstTimeFCs = useMemo(
    () =>
      !props?.tasks?.some(item => item.fill_cycle_number !== 1) &&
      !props?.siblingTasks?.some(item => item?.task?.fill_cycle_number !== 1),
    [props?.siblingTasks, props?.tasks],
  );
  const secureLinkSenderId = props.tasks[0].secure_link_sent_by_user_id;
  const isEditMode = props.componentMode === EditComponentMode.Edit;
  const currentTherapy = props?.therapies?.[props.tasks[0].therapy_id];
  const fillCycleThisTask = props.tasks[0].fill_cycle_number;
  const isFillCycleAfterCurrent =
    (fillCycleThisTask && fillCycleThisTask === Number(currentTherapy?.fill_cycle) - 1) ||
    currentTherapy?.one_time_fill;

  // #region redux
  const internalPharmacies = useTypedSelector(state => state.pharmacies.pharmacies);
  const secureFCLinkSender = useTypedSelector(state =>
    secureLinkSenderId
      ? state.lookups.users.find(user => user.id === secureLinkSenderId)?.display_name
      : '',
  );
  const hiddenQuestions = useDeepDiffSelector(
    (state: IState) => state.patient.hidden_questions?.map(({ question }) => question) ?? [],
  );

  const contactList = useTypedSelector(state => state.contactList);
  const medications = useTypedSelector(state => state.medications);
  const allergies = useTypedSelector(state => state.allergies.allergiesList);
  const fullPhoneList = getFullPhoneList(props.patient || [], props.contactList) as any[];

  const sideEffectOptions = useTypedSelector((state: IState) => state.lookups.fdbSideEffects);
  const upsPackagingTypeOptions = useTypedSelector(state => state.lookups.upsPackagingType);
  const medicationList = useTypedSelector(state => state.medications.medicationList);
  const therapyList = useTypedSelector(state => state.therapies.data);
  const patientTasks = useTypedSelector(state => state.tasks.data);
  const medicationGroups = useTypedSelector(state => state.medications.medicationGroups);
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups);
  const patient = useTypedSelector(state => state.patient);
  const userCanOverrideCADelivery = useTypedSelector(
    state =>
      state.auth.currentUser.role === 'superuser' ||
      state.auth.currentUser.roles.some(role =>
        [
          'credentialed_pharmacist',
          'director_at',
          'manager_at',
          'non_credentialed_pharmacist',
          'non_pharmacist_com',
          'non_pharmacist_gm',
          'pharmacist_central_ops',
          'pharmacist_central_support',
          'pharmacist_clinic',
          'pharmacist_com',
          'pharmacist_dispensing',
          'site_manager',
        ].includes(role.name),
      ),
  );
  const patientPreferredDeliveryMethod = deliveryMethod(patient.preferred_rx_delivery_method);
  const patientIsSMSOptIn = patient?.filteredPhones?.some(
    phone => phone.patient_choice && phone.sms === OPT_IN,
  );
  const patientUndecided =
    patient?.filteredPhones?.some(phone => phone.use === PhoneUsage.Mobile) &&
    !patient?.filteredPhones?.some(
      phone => phone.use === PhoneUsage.Mobile && phone.sms !== 'Not Specified',
    );
  const sortRankPhones = (patient.phones || []).sort((phonea, phoneb) => phonea.rank - phoneb.rank);
  const isBIFormOpen = useTypedSelector(state => state.benefitsInvestigationSubmissionForm.open);

  const smsPhoneNumber = sortRankPhones.find(
    phone =>
      !phone.deleted &&
      phone.use === PhoneUsage.Mobile &&
      phone.patient_choice === true &&
      phone.sms === MobilePhoneSmsEnum.OptIn,
  )?.value;
  const pbmInsurances = useTypedSelector(state => state.financials.pbm_insurances);

  const isPatientMedicare = Object.values(pbmInsurances.data).some(
    insurance =>
      insurance.insurance_type_name === 'Medicare' &&
      (insurance.end_date === null || convertToArborDate(insurance.end_date).isAfterNow()),
  );

  const latestBis = useTypedSelector(state => state.therapyBenefitsInvestigationLatest);
  const patientAllergies = getAllergiesOrderedBySeverity(allergies, [
    PATIENT_ALLERGY_STATUS_ACTIVE,
  ]);
  const problems = patient.problems || [];
  const groupedMedications = getGroupedMedications(medications.medicationGroups);
  const isSecureLinkOptionEnabled =
    !includesOnlyFirstTimeFCs && patientIsSMSOptIn && patient.auto_secure_link;
  const isSecureLinkFeatureFlagEnabled = windowFeatureIsEnabled('fc_self_service');
  const methodOptionsEnabled = methodOptions.filter(
    item => !(item.name === 'Secure Link' && !isSecureLinkFeatureFlagEnabled),
  );
  const [controlledMethodIndex, setControlledMethodIndex] = useState(() => {
    if (startingStatus === 4012) {
      return -1;
    }
    if (startingStatus === 4009 || startingStatus === 4010 || startingStatus === 4011) {
      return 3;
    }
    if (props.tasks[0].method) {
      return props.tasks[0].method - 1;
    }
    return undefined;
  });
  const [secureLinkSendHistory, setSecureLinkSendHistory] = useState<null | {
    has_limit_violation?: boolean;
    fc_history?: any;
  }>(null);
  useEffect(() => {
    if (orderContainsAMedicarePartBTherapy && !formValues.signature_required) {
      props.change('signature_required', 1);
    }
  }, [props.allTasks]);
  useEffect(() => {
    if (props.tasks[0].method === null) {
      setControlledMethodIndex(undefined);
    }
  }, [props.tasks]);
  const [medicationGroupsWithTherapies, setMedicationGroupsWithTherapies] = useState(
    sortMedications(linkTherapiesMedications(medicationGroups, therapyList))?.filter(
      (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
    ) ?? [],
  );

  const [medicationsWithTherapies, setMedicationsWithTherapies] = useState(
    linkTherapiesMedications(medicationList, therapyList)
      ? linkTherapiesMedications(medicationList, therapyList).filter(
          (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
        )
      : [],
  );

  const [zipCode, setZipCode] = useState<string>();
  const [holidays, setHolidays] = useState<any>({});
  const [shipDateIsHoliday, setShipDateIsHoliday] = useState(false);
  const [preferredDeliveryDates, setPreferredDeliveryDates] = useState<Date[] | null>(null);
  const [currentMessageDialog, setCurrentMessageDialog] = useState<any>([]);
  const [messageDialogVisible, setMessageDialogVisible] = useState<boolean>(false);
  const [therapyHiddenQuestions, setTherapyHiddenQuestions] = useState<Record<number, string[]>>(
    {},
  );
  const [disabledDates, setDisabledDates] = useState<{
    ship: Date[] | undefined;
    delivery: Date[] | undefined;
  }>({ ship: undefined, delivery: undefined });

  const isColdChainDisabled = useMemo(
    () =>
      coldChainDisabled(
        props.tasks[0],
        therapies,
        props.siblingTasks.filter(({ therapy }) => !removedTherapyIds.includes(therapy?.id)),
      ),
    [removedTherapyIds],
  );

  const readdFCToBundle = (id: number) => {
    const newValue = [...removedFCIds].filter(entry => entry !== id);
    setRemovedFCIds(newValue);
  };
  const removeFCFromBundle = (id: number) => {
    const newValue = [...removedFCIds].concat([id]);
    setRemovedFCIds(newValue);
  };

  const handleCollectionMethodChange = (index: number) => {
    setControlledMethodIndex(index);
    if (index === 3) {
      props.changeSelectedStatus(4009);
      return;
    }

    if (selectedNextStatus === 4009) {
      props.changeSelectedStatus(4002);
    }
  };

  useEffect(() => {
    setMedicationGroupsWithTherapies(
      sortMedications(linkTherapiesMedications(medicationGroups, therapyList))?.filter(
        (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
      ) ?? [],
    );
    setMedicationsWithTherapies(
      linkTherapiesMedications(medicationList, therapyList)
        ? linkTherapiesMedications(medicationList, therapyList).filter(
            (m: any) => m.active === MEDICATION_THERAPY_STATUS_ACTIVE,
          )
        : [],
    );
  }, [medicationGroups, medicationList, therapyList]);

  const allTasks = useMemo(
    () =>
      props.allTasks.length === 1
        ? [...props.allTasks, ...props.siblingTasks?.map(({ task: t }) => t)]
        : props.allTasks,
    [props.siblingTasks, props.allTasks],
  );
  const prevTasks = usePrevious(allTasks);
  useEffect(() => {
    if (prevTasks?.length === allTasks.length || !patient.id) {
      return;
    }

    Promise.all(
      allTasks.map(async propTask => {
        if (
          !therapyHiddenQuestions[propTask.therapy_id] &&
          windowFeatureIsEnabled('rems_authorization_code')
        ) {
          const response = await HTTP.get(
            `/patients/${patient.id}/therapies/${propTask.therapy_id}/questions`,
          );
          return response.data.map(({ question }: { question: string }) => question);
        }

        return therapyHiddenQuestions[propTask.therapy_id];
      }),
    ).then(result => {
      const obj = allTasks.reduce((state, item, idx) => {
        state[item.therapy_id] = result[idx];
        return state;
      }, {} as Record<number, string[]>);
      setTherapyHiddenQuestions(obj);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTasks, patient, prevTasks]);

  useEffect(() => {
    dispatch(fetchHiddenQuestions());
    dispatch(refreshBiHistory());
  }, []);

  const formValues: Partial<IFillCoordinationEditFormFields> =
    useTypedSelector(state => getFormValues(props.form || props.formName)(state)) || {};

  useEffect(() => {
    formValues.cold_chain_packing_required = isColdChainDisabled;
  }, [isColdChainDisabled]);

  // #endregion redux

  // #region component state
  const [showAllergiesList, setShowAllergiesList] = React.useState<boolean>(false);
  const [showAddAllergyForm, setShowAddAllergyForm] = React.useState<boolean>(false);
  const [disableNewAllergies, setDisableNewAllergies] = React.useState<boolean>(false);
  const [showMedicationsList, setShowMedicationsList] = React.useState<boolean>(false);
  const [showProblemsList, setShowProblemsList] = React.useState<boolean>(
    props.tasks[0].status_id === 4010 && Number(props.tasks[0].new_medical_conditions) === 1,
  );

  const [showAddMedicationForm, setShowAddMedicationForm] = React.useState<boolean>(false);
  const [showAddProblemsForm, setShowAddProblemsForm] = React.useState<boolean>(false);

  const [disableChangesInMedications, setDisableChangesInMedications] =
    React.useState<boolean>(false);

  const [showNewSideEffects, setShowNewSideEffects] = React.useState<boolean>(false);
  const [showMissedDoses, setShowMissedDoses] = React.useState<boolean>(false);
  const [showErrorForCourier, setShowErrorForCourier] = React.useState<boolean>(false);

  const [selectedSupplies, setSelectedSupplies] = React.useState<string[]>([]);

  const selectedTask = props.tasks[0];

  const [courierTypeIdVal, setCourierTypeIdVal] = useState(selectedTask.courier_type);

  const fieldSettings = buildFieldSettings(
    props.selectedStatus,
    props.patient,
    { ...props.tasks[0], status: (props.tasks[0] as any).status },
    props.methodIsChartReview,
    props.deliveryMethodVal,
    props.componentMode,
    props.therapies,
    props.siblingTasks,
    props.statusReason,
  );

  const getLinkedFC = useTypedSelector(getLinkedFCTaskForId);
  const isNextLinkedTaskCompleted = React.useCallback(
    (taskId: number) => isTaskCompleted(getLinkedFC(taskId)),
    [getLinkedFC],
  );

  const dispensingPharmacyOptions: { label: any; value: any }[] = getDispensingPharmacyOptions(
    internalPharmacies,
    preferredRxDeliveryPharmacy,
  );

  const newSideEffectInterventions = filterIntsBySideEffectType(
    props.newInterventions || [],
    SideEffectType.Common,
  );
  const missingReaction = newSideEffectInterventions.some(
    intervention => intervention.int.reaction == null,
  );
  const missingSuspectedCausedBy = newSideEffectInterventions.some(
    intervention => intervention.int.suspected_caused_by == null,
  );

  const newMissedDoseInterventions = filterIntsBySideEffectType(
    props.newInterventions || [],
    SideEffectType.MissedMedicationDose,
  );
  const missingMissedDoseMedication = newMissedDoseInterventions.some(
    intervention => intervention.int.suspected_caused_by == null,
  );
  const missingMissedDoseDetails = newMissedDoseInterventions.some(intervention =>
    isEmpty(intervention.int.details),
  );

  const existingSideEffectInterventions = (props.existingInterventions ?? []).filter(
    intervention => intervention.typeId === InterventionTypeId.Adr,
  );
  const existingMissedDosesInterventions = (props.existingInterventions ?? []).filter(
    intervention => intervention.typeId === InterventionTypeId.Adherence,
  );

  const availableAddressLength = getFullAddressList(
    props.patient,
    props.contactList,
    props.deliveryMethodVal?.value,
  ).length;

  const fullAddressList = getFullAddressList(
    props.patient,
    props.contactList,
    props.deliveryMethodVal?.value,
  );

  const providerAddresses = getFullAddressList(
    props.patient,
    props.contactList,
    props.deliveryMethodVal?.value,
  ).filter(
    address =>
      address.rawAddress?.use === 'Provider' ||
      address.rawAddress?.use === 'Provider/Clinic' ||
      address?.label?.includes('(Provider)'),
  );

  const serviceGroupOptions: NumberOption[] = React.useMemo<NumberOption[]>(() => {
    return serviceGroups.map<NumberOption>(serviceGroup => {
      return {
        label: serviceGroup.display_name,
        value: serviceGroup.id,
      };
    });
  }, [serviceGroups]);
  const fdcStatusReason = FDCStatusReason === 'Picked Up' ? 'PickedUp' : FDCStatusReason;
  const isFDCCompleted =
    fdcStatusReason &&
    (FDCStatus[fdcStatusReason as FDCKeys] === FDCStatus.Shipped ||
      FDCStatus[fdcStatusReason as FDCKeys] === FDCStatus.Delivered ||
      FDCStatus[fdcStatusReason as FDCKeys] === FDCStatus.PickedUp);
  const isCompleted = FillCoordinationStatus.Completed === task.status_id;

  const isPickUp =
    formValues[nameOfFormFields('preferred_rx_delivery_method')]?.value ===
    DeliveryMethodValue.PickUp;

  const showMessageDialog = (message: string) => {
    setMessageDialogVisible(true);

    const hideMessageDialoag = () => {
      setMessageDialogVisible(false);
    };

    setCurrentMessageDialog({
      message,
      onContinue: hideMessageDialoag,
      continueText: 'OK',
    });
  };

  // #endregion component state

  // #region component methods

  const shippingAddressValidation = (
    shippingAddress: { value: IAddress; label: string } | undefined,
  ): boolean => {
    if (!shippingAddress || !patient) {
      return false;
    }

    if (patient.preferred_rx_delivery_patient) {
      const referenceAddress =
        patient.addresses && patient.addresses.length
          ? patient.addresses.find(item => item.key === patient.preferred_rx_delivery_entity_key)
          : null;
      return _.isEqual(shippingAddress.value, referenceAddress);
    }

    if (patient.preferred_rx_delivery_contact_id) {
      const contact = props.contactList[patient.preferred_rx_delivery_contact_id];
      const referenceAddress = contact?.addresses?.find(
        item => item.key === patient.preferred_rx_delivery_entity_key,
      );
      return _.isEqual(shippingAddress.value, referenceAddress);
    }
    return false;
  };

  useEffect(() => {
    const fcs_in_call_patient_or_patient_response_ready_state = props.allTasks.filter(
      task => task.status_id === 4010 || task.status_id === 4011,
    );
    if (fcs_in_call_patient_or_patient_response_ready_state.length > 0) {
      const payload = {
        task_ids: fcs_in_call_patient_or_patient_response_ready_state.map(task => task.id),
      };
      HTTP.post('tasks/fc/call-patient-reasons', payload).then((response: any) => {
        const { reasons } = response.data;
        if (reasons.length) {
          if (compareArrays(callPatientReasons, reasons)) {
            return;
          }
          setCallPatientReasons(reasons);
        } else {
          setCallPatientReasons([]);
        }
      });
      setCallPatientReasons(["Loading 'Call Patient' status reason(s)"]);
    } else if (callPatientReasons.length != 0) {
      setCallPatientReasons([]);
    }
  }, [crudeFCIdsHash]);

  useEffect(() => {
    const fcs_in_call_patient_state = props.allTasks.filter(
      task => task.status_id === 4010 || task.status_id === 4002,
    );
    if (fcs_in_call_patient_state.length > 0) {
      const { secure_link_id } = fcs_in_call_patient_state[0];
      HTTP.get(`tasks/fc/secure-link-patient-clinical-responses/${secure_link_id}`).then(
        (response: any) => {
          const details = response.data;
          if (Object.keys(details || {}).length) {
            setClinicalResponses(details);
          } else {
            setClinicalResponses([]);
          }
        },
      );
    } else if (clinicalResponses.length != 0) {
      setClinicalResponses([]);
    }
  }, [crudeFCIdsHash]);

  useEffect(() => {
    if (selectedNextStatus !== FCStatus.in_progress_waiting_for_patient_response) {
      return;
    }
    if (crudeFCIdsHash.length > 0) {
      HTTP.get(`tasks/fc/secure-link-send-history/${patient.id}?fc_ids=${crudeFCIdsHash}`).then(
        (response: any) => {
          const details = response.data;
          setSecureLinkSendHistory(details);
        },
      );
    } else {
      setSecureLinkSendHistory({});
    }
  }, [crudeFCIdsHash, selectedNextStatus]);

  useEffect(() => {
    if (
      preferredRxDeliveryPharmacy &&
      preferredRxDeliveryPharmacy?.npi ===
        formValues[nameOfFormFields('dispensing_pharmacy')]?.dispensing_pharmacy_npi
    ) {
      props &&
        props.change &&
        props.change(nameOfFormFields('apply_choice_to_therapies_and_FCs'), false);
    }
  }, [formValues[nameOfFormFields('dispensing_pharmacy')], props.selectedStatus]);

  useEffect(() => {
    if (
      startingStatus === 4009 ||
      startingStatus === 4010 ||
      startingStatus === 4011 ||
      selectedNextStatus === 4009 ||
      (props.tasks[0].secure_link_id && props.tasks[0].secure_link_id > 0)
    ) {
      setControlledMethodIndex(3);
    } else if (controlledMethodIndex === 3) {
      setControlledMethodIndex(-1);
    }
  }, [props.selectedStatus]);

  useEffect(() => {
    if (!showPreferredDeliveryDates(props.deliveryMethodVal?.value) && holidays?.Customer) {
      return;
    }

    if (zipCode) {
      AreaShippingClient.fetchShippingDates(zipCode).then(response => {
        const dates = response.data
          .map(date => moment(date).utc().toDate())
          .filter(date => !!date) as Date[];
        setPreferredDeliveryDates(dates);
      });
    }
    if (!holidays?.Customer) {
      AreaShippingClient.fetchShippingHolidays().then(response => {
        const dates: any = response.data;
        setHolidays(dates);
      });
    }
  }, [zipCode, props.deliveryMethodVal?.value]);

  const isAncillarySupplyChecked = (supply: string): boolean => selectedSupplies?.includes(supply);

  const isAncillarySuppliesRequired =
    selectedSupplies.length === 0 &&
    (formValues.cold_chain_packing_required || Boolean(props.tasks[0].cold_chain_packing_required));
  const ancillarySuppliesItemProps = ancillarySupplies.reduce((accumulator: any, supply: any) => {
    accumulator[supply.value] = {
      checked:
        adherecePackageRequired === THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_REQUIRED &&
        supply.value === AncillarySupplyValues.AdherencePackaging
          ? !!adherecePackageRequired
          : isAncillarySupplyChecked(supply.value),
      disabled:
        adherecePackageRequired === THERAPY_ADHERENCE_PACKAGE.ADHERENCE_PACKAGE_REQUIRED &&
        supply.value === AncillarySupplyValues.AdherencePackaging,
      color: 'primary',
      endAdornment: (
        <Tooltip title={OVERRIDE_PATIENT_PREFERENCES_MESSAGE} placement="top">
          <WarningIcon
            className={
              props.componentMode !== EditComponentMode.Edit &&
              defaultPreferencesAncillarySupplies.includes(supply.value) &&
              Boolean((patient as any)[supply.value]) !== isAncillarySupplyChecked(supply.value)
                ? cx(classes.warningIcon)
                : cx(classes.warningIcon, classes.hidenItem)
            }
          />
        </Tooltip>
      ),
    };
    return accumulator;
  }, {} as any);

  const getTherapySectionTitle = (): string => {
    const moreThanOneTask = props.tasks.length + props.siblingTasks.length > 1;
    if (moreThanOneTask) {
      return 'Fill Coordination - Therapy';
    }
    const task = props.tasks[0] || props.siblingTasks[0];
    return `Fill Coordination #${task.fill_cycle_number} - Therapy`;
  };

  const finishDeliveryAddressValidation = (result: any) => {
    if (result.validated) {
      props.change('address', result.updated_address);
      dispatch({ type: UPDATE_VALIDATED_ADDRESS, payload: result.updated_address });
    }
    setValidationDetails(null);
  };

  const beginDeliveryAddressValidation = async () => {
    const result = await AddressValidationClient.validateAddress(formValues?.address);
    if (result.validated) {
      finishDeliveryAddressValidation(result);
      return;
    }
    setValidationDetails(result.validation_results);
  };
  // #endregion

  // #region memos
  const patientHasPaymentMethods = React.useMemo(
    () => Boolean(props.patient.payment_methods?.length),
    [props.patient],
  );
  // #endregion

  // #region effects
  React.useEffect(() => {
    if (props.tasks[0].status_id !== 4002) {
      setShowAddAllergyForm(props.haveNewAllergies);
    }
    setShowAllergiesList(props.haveNewAllergies);
  }, [props.haveNewAllergies]);

  React.useEffect(() => {
    setShowAddMedicationForm(props.areMedicationChanges);
    setShowMedicationsList(props.areMedicationChanges);
  }, [props.areMedicationChanges]);

  React.useEffect(() => {
    setShowNewSideEffects(props.haveNewSideEffects);
  }, [props.haveNewSideEffects]);

  React.useEffect(() => {
    setShowMissedDoses(props.patientMissedDoses);
  }, [props.patientMissedDoses]);

  React.useEffect(() => {
    const selectedSuppliesArray = props.selectedAncillarySupplies?.split(',') || [];
    setSelectedSupplies(selectedSuppliesArray.filter(Boolean));
  }, [props.selectedAncillarySupplies]);

  React.useEffect(() => {
    // If the form was already saved or ancillary supplies have already been specified, don't apply patient defaults
    if (props.componentMode !== EditComponentMode.Edit && isEmpty(formValues.ancillary_supplies)) {
      const patientDefaultSupplies = new Set();

      if (patient.easy_open_caps) {
        patientDefaultSupplies.add(AncillarySupplyValues.EasyOpenCaps);
      }
      if (patient.adherence_packaging) {
        patientDefaultSupplies.add(AncillarySupplyValues.AdherencePackaging);
      }
      // Add supplies if there are already selected ancillary supplies in form
      if (props.selectedAncillarySupplies) {
        props.selectedAncillarySupplies
          .split(',')
          .forEach(item => patientDefaultSupplies.add(item));
      }

      props.changeAncillarySupplies?.(Array.from(patientDefaultSupplies).join(','));
    }
  }, [patient]);

  React.useEffect(() => {
    if (!formValues?.ship_date || !formValues?.delivery_dt) {
      return;
    }

    if (
      moment(formValues.delivery_dt.format('YYYY-MM-DD')).diff(
        moment(formValues.ship_date.format('YYYY-MM-DD')),
        'days',
      ) >= 7
    ) {
      showMessageDialog('Ship Date and Delivery Date differ by greater than 1 week');
    }
  }, [formValues?.ship_date, formValues?.delivery_dt]);

  React.useEffect(() => {
    if (props.handleExpectedDeliveryDateChange && formValues?.delivery_dt) {
      props.handleExpectedDeliveryDateChange(formValues.delivery_dt.toDate());
    }
  }, [formValues?.delivery_dt]);

  React.useEffect(() => {
    if (props.showTherapiesNdcsWarning) {
      showMessageDialog('Delivery Date is after NBD for one or more medications');
    }
  }, [props.showTherapiesNdcsWarning]);

  React.useEffect(() => {
    const y = showPreferredDeliveryDates(props.deliveryMethodVal?.value);
    if (
      showPreferredDeliveryDates(props.deliveryMethodVal?.value) &&
      Array.isArray(preferredDeliveryDates) &&
      preferredDeliveryDates.length === 0
    ) {
      setShowErrorForCourier(true);
    } else {
      setShowErrorForCourier(false);
    }
  }, [props.deliveryMethodVal?.value, preferredDeliveryDates]);

  React.useEffect(() => {
    collectionMethodChanged(formValues.method);
  }, [formValues.method]);

  // If delivery method changes such that the shipping method is no longer valid, reset the shipping method field
  React.useEffect(() => {
    if (formValues && formValues?.preferred_rx_delivery_method && formValues?.shipping_method) {
      const shippingMethodsForDeliveryMethod = deliveryShippingMethodMap[
        formValues.preferred_rx_delivery_method.value
      ]?.map(method => method.value);
      if (!shippingMethodsForDeliveryMethod?.includes(formValues.shipping_method)) {
        props.change(nameOfFormFields('shipping_method'), null);
      }
    }
    if (
      formValues?.preferred_rx_delivery_method?.value === DeliveryMethodValue.Ups &&
      formValues?.cold_chain_packing_required &&
      formValues?.shipping_method !== 'ups_next_day_air'
    ) {
      props.change(nameOfFormFields('shipping_method'), 'ups_next_day_air');
    }
    const shouldValidateAddress =
      formValues &&
      formValues?.preferred_rx_delivery_method?.value !== DeliveryMethodValue.PickUp &&
      formValues?.address?.value &&
      typeof formValues?.address?.value !== 'number';
    const shouldDisplayAddressValidationWarning =
      shouldValidateAddress &&
      !shippingAddressValidation(formValues?.address as { value: IAddress; label: string });
    if (shouldDisplayAddressValidationWarning && addressPreferenceMismatch === false) {
      setAddressPreferenceMismatch(true);
    } else if (!shouldDisplayAddressValidationWarning && addressPreferenceMismatch === true) {
      setAddressPreferenceMismatch(false);
    }
  }, [formValues?.preferred_rx_delivery_method?.value, formValues?.address]);

  React.useEffect(() => {
    const addressLabel = formValues[nameOfFormFields('address')]?.label;
    // @ts-ignore
    const address = getFullAddressList(props.patient, props.contactList).find(
      a => a.label === addressLabel,
    );
    if (address?.rawAddress?.zip) {
      setZipCode(address.rawAddress.zip);
    }
    const shouldCheckDeliveryAddress =
      formValues && formValues?.preferred_rx_delivery_method?.value !== DeliveryMethodValue.PickUp;
    const shouldDisplayAddressValidationWarning =
      shouldCheckDeliveryAddress &&
      !shippingAddressValidation(formValues?.address as { value: IAddress; label: string });
    if (shouldDisplayAddressValidationWarning && addressPreferenceMismatch === false) {
      setAddressPreferenceMismatch(true);
    } else if (!shouldDisplayAddressValidationWarning && addressPreferenceMismatch === true) {
      setAddressPreferenceMismatch(false);
    }
  }, [formValues[nameOfFormFields('address')]]);

  React.useEffect(() => {
    if (formValues?.dispense_qty) {
      const dispenseValues = formValues[nameOfFormFields('dispense_qty')];
      if (dispenseValues != null) {
        dispatch(benefitsInvestigationSubmissionFormActions.setDispenseQty(dispenseValues));
      }
    }
  }, [formValues[nameOfFormFields('dispense_qty')], isBIFormOpen]);

  useEffect(() => {
    if (
      formValues?.cold_chain_packing_required &&
      formValues?.preferred_rx_delivery_method?.value === DeliveryMethodValue.Ups &&
      formValues?.shipping_method !== 'ups_next_day_air'
    ) {
      props.change('shipping_method', 'ups_next_day_air');
    }
  }, [formValues?.cold_chain_packing_required]);

  // #endregion effects
  function getCombinedDisabledDates(
    deliveryMethod: number,
    holidays: { UPS?: string[]; Courier?: string[]; Customer?: string[] },
  ): { ship: Date[]; delivery: Date[] } {
    const excludedDates: { ship: Date[]; delivery: Date[] } = {
      ship: [],
      delivery: [],
    };
    const customer_dates = (holidays.Customer || []).map(entry => new Date(entry));
    const courier_dates = (holidays.Courier || []).map(entry => new Date(entry));
    const ups_dates = (holidays.UPS || []).map(entry => new Date(entry));
    switch (deliveryMethod) {
      case DeliveryMethodValue.Ups:
        excludedDates.ship = customer_dates.concat(ups_dates);
        excludedDates.delivery = ups_dates;
        break;
      case DeliveryMethodValue.Courier:
        excludedDates.ship = customer_dates.concat(courier_dates);
        excludedDates.delivery = courier_dates;
        break;
      case DeliveryMethodValue.PickUp:
        excludedDates.ship = [];
        excludedDates.delivery = customer_dates;
        break;
      case DeliveryMethodValue.FedEx:
        excludedDates.ship = customer_dates;
        excludedDates.delivery = [];
        break;
      case DeliveryMethodValue.Usps:
        excludedDates.ship = customer_dates;
        excludedDates.delivery = [];
        break;
    }
    return excludedDates;
  }

  useEffect(() => {
    if (formValues?.preferred_rx_delivery_method?.value) {
      const selectedMethod = formValues.preferred_rx_delivery_method.value;
      // Get the dynamically combined disabled dates based on the dropdown value
      const newDisabledDates = getCombinedDisabledDates(selectedMethod, holidays);
      setDisabledDates(newDisabledDates);
    }
  }, [formValues?.preferred_rx_delivery_method?.value, holidays]);
  // #region render
  const greaterThanZero = useRef((value: any) => {
    return (typeof value === 'string' || typeof value === 'number') &&
      parseInt(value.toString(), 10) > 0
      ? undefined
      : 'Must be greater than 0';
  });
  const requiredWhenComplete: any[] =
    props.selectedStatus === FillCoordinationStatus.Completed ? [required] : [];
  const definedWhenComplete: any[] =
    props.selectedStatus === FillCoordinationStatus.Completed ? [defined] : [];
  const daysSupplyValidation =
    props.selectedStatus === FillCoordinationStatus.Completed
      ? [required, greaterThanZero.current]
      : [];

  function renderFieldLabel(fieldName: string, labelSize: string): JSX.Element {
    // @ts-ignore
    return <FieldLabel fieldName={fieldName} labelSize={labelSize} />;
  }

  function renderDeliveryMethodSelectOption(
    deliveryMethod: DeliveryMethodLabel,
    logo: string,
  ): JSX.Element {
    return (
      <Grid container>
        <Grid item xs={6}>
          {deliveryMethod}
        </Grid>
        <Grid item xs={6} className={classes.shippingMethodOption}>
          <img
            alt={`Shipping Method - ${logo}`}
            className={classes.shippingMethodOptionIcon}
            src={logo}
          />
        </Grid>
      </Grid>
    );
  }

  function renderPillIcon(dosisRegimenText: string | undefined) {
    return dosisRegimenText ? (
      <div style={{ display: 'flex' }} data-qa-id={qaIdBuilder('grid')}>
        <PillIcon therapy={props.therapies} />
        <Typography style={{ fontSize: 12, marginLeft: 10 }}>{dosisRegimenText}</Typography>
      </div>
    ) : null;
  }

  function renderBiSection(task: IFillCoordinationTask, therapy: ITherapy): JSX.Element | null {
    const hasPbmInsurance = Object.keys(pbmInsurances.data).length !== 0;

    const hasBiData = Object.keys(latestBis.data).length !== 0;
    const hasBiForTherapy = hasBiData && latestBis.data[therapy.id];

    // Show add pbm insurance section if there are none
    if (!hasPbmInsurance) {
      return <NoPbmInsurance />;
    }

    // Show no BI information if has pbm insurance & there is no BI for this therapy
    if (hasPbmInsurance && !hasBiForTherapy) {
      return <NoBiForTherapy therapy={therapy} taskId={task.id} />;
    }

    // Show BI information if there is BI for this therapy
    if (hasBiForTherapy) {
      return <BiForTherapy therapy={therapy} taskId={task.id} />;
    }

    return null;
  }

  const renderDeactivateLinkConfirmation = () => {
    if (deactivateWarningConfig.show) {
      return (
        <ConfirmationDialogPanel
          open
          content={
            <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
              This will deactivate the Secure Link for this FC and all FCs in the same bundle.
            </Typography>
          }
          showCancelButton
          cancelText="Cancel"
          continueText="Continue"
          onCancel={deactivateWarningConfig.cancel}
          onContinue={deactivateWarningConfig.continue}
        />
      );
    }
    return null;
  };

  const renderMessageDialog = () => {
    return (
      messageDialogVisible && (
        <ConfirmationDialogPanel
          open
          content={
            <Typography gutterBottom className={(props as any).classes.shippingAddressText}>
              {currentMessageDialog.message}
            </Typography>
          }
          showCancelButton={!!currentMessageDialog?.showCancelButton}
          cancelText="Cancel"
          continueText={currentMessageDialog?.continueText || 'Continue'}
          onCancel={currentMessageDialog?.onCancel}
          onContinue={currentMessageDialog?.onContinue}
        />
      )
    );
  };

  function hideQuestion(question: keyof IFillCoordinationEditFormFields) {
    if (!hiddenQuestions.includes(question)) {
      props.change(nameOfFormFields(question), 0);
    }
  }

  function setNoToAllQuestions() {
    const questions: (keyof IFillCoordinationEditFormFields)[] = [
      'changes_in_medication',
      'hospital_visit',
      'is_notice_privacy_practices_sent',
      'new_allergies',
      'new_infections',
      'new_medical_conditions',
      'patient_missed_doses',
      'patient_questions',
      'side_effects',
      'welcome_kit_sent',
      'wk_pp_received',
    ];
    questions.forEach(question => hideQuestion(question));
  }

  const openPaymentMethodModal = React.useCallback(() => {
    dispatch(
      addPaymentMethodModalActions.setState({
        visible: true,
        formId: props.form ?? props.formName,
        field: nameOfFormFields('payment_method_ids'),
        tasks: props.allTasks,
        viewMode: ViewModeEnum.FillCoordination,
      }),
    );
  }, [dispatch, addPaymentMethodModalActions.setState, props.form, props.allTasks]);

  function renderTherapyFields(task: IFillCoordinationTask, therapy: ITherapy) {
    const therapyDrugFullName = formatTherapyNameLong(
      therapy.drug_name,
      therapy.dosage_form,
      therapy.strength,
      therapy.strength_unit_of_measure,
      therapy.dosage_form_description,
      therapy.ndc,
    );
    const currentDaysSupply = formValues.days_supply ? formValues.days_supply[task.id] : '';
    const daysSupplyChecking = TherapyUtil.checkDaysSupply(currentDaysSupply, therapy, patient);
    const dispenseQtySuffix = dosageFormToUIDisplay(therapy.dosage_form);
    const inMedSync = therapy.in_med_sync === THERAPY_MED_SYNC.IN_MED_SYNC;
    const disableDaysSupplyMedSync = windowFeatureIsEnabled('med_sync') && inMedSync;
    const disableDaysSupplyForCompletedTask = isNextLinkedTaskCompleted(task.id);
    const fcHasBeenRemoved = removedFCIds.includes(task.id);
    const patientRequestTherapyBeRemoved = therapiesToRemove
      .map(therapy => therapy.id)
      .includes(therapy.id);
    const disableDaysSupply = disableDaysSupplyMedSync || disableDaysSupplyForCompletedTask;
    const showRemoveFromBundleButton =
      props.componentMode === EditComponentMode.ChangeStatus &&
      patientRequestTherapyBeRemoved &&
      !fcHasBeenRemoved;
    const showReaddToBundleButton =
      props.componentMode === EditComponentMode.ChangeStatus &&
      patientRequestTherapyBeRemoved &&
      fcHasBeenRemoved;
    return (
      <React.Fragment key={task.id}>
        {fcHasBeenRemoved ? (
          <Grid item xs={12} className={classes.fieldContainerWithRedTextThin}>
            <div>
              <span className={classes.redCircleExclamationPoint}>!</span>
              {`This Therapy (${therapyDrugFullName}) will be removed from the bundle when this form is saved.`}
            </div>
          </Grid>
        ) : null}
        <Grid container spacing={2} className={classes.therapyFormWrapper}>
          {fieldSettings.drug_name && (
            <Grid item xs={12}>
              <div className={classes.therapyLevelHeader} data-fc-id={task.id}>
                <Box
                  component="h3"
                  sx={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    gap: '12px',
                    alignItems: 'baseline',
                  }}
                >
                  {therapy.adherence_packaging ? <APIcon className={classes.prnIcon} /> : ''}
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {therapyDrugFullName} #{task.fill_cycle_number}
                  </span>
                  {therapy.as_needed ? <PRNIcon className={classes.prnIcon} /> : ''}
                  {therapy.clinic_administered ? <CAIcon className={classes.prnIcon} /> : ''}
                  {therapy.medicare_part_b ? (
                    <Tooltip title="Medicare Part B">
                      <MedicarePartBIcon />
                    </Tooltip>
                  ) : null}
                  {therapy?.drop_ship_med ? (
                    <Tooltip title="Drop-Ship Med">
                      <span>
                        <DSIcon className={classes.prnIcon} />
                      </span>
                    </Tooltip>
                  ) : null}
                  {therapy.controlled_substance_code
                    ? getCMIcon(therapy?.controlled_substance_code, classes.prnIcon)
                    : ''}
                  {therapy.one_time_fill ? (
                    <Tooltip title="One-Time Fill">
                      <Typography component="span" className={classes.otfIcon}>
                        OTF
                      </Typography>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                  {inMedSync ? (
                    <Box sx={{ opacity: 0.58 }}>
                      <MedSyncIcon size="sm" />
                    </Box>
                  ) : null}
                  {renderPillIcon(therapy?.dosis_regimen)}
                  {showReaddToBundleButton ? (
                    <Button
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #C4C4C4',
                        color: '#B5302C',
                      }}
                      onClick={() => readdFCToBundle(task.id)}
                    >
                      Re-Add to Bundle
                    </Button>
                  ) : null}
                  {showRemoveFromBundleButton ? (
                    <Button
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #C4C4C4',
                        color: '#B5302C',
                      }}
                      onClick={() => removeFCFromBundle(task.id)}
                    >
                      Remove from Bundle
                    </Button>
                  ) : null}
                </Box>
              </div>
              {props.componentMode === EditComponentMode.ChangeStatus
                ? renderBiSection(task, therapy)
                : null}
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('on_hand_qty')] && (
            <Grid item xs={2}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('on_hand_qty'), task)}
                label="On-Hand Quantity (days)"
                component={DebouncedNumericField}
                qaId={nameOfFormFields('on_hand_qty')}
                width="100%"
                disabled={isEditMode}
              />
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('needsby_date')] && (
            <Grid item xs={2}>
              <Tooltip
                title={
                  inMedSync
                    ? 'Therapy is part of med sync, change the Anchor Date to move the NBD'
                    : ''
                }
                placement="top"
              >
                <Box>
                  <Field
                    name={getFieldNamePerTask(nameOfFormFields('needsby_date'), task)}
                    label="Needs by Date *"
                    component={renderDatePicker}
                    validate={fcHasBeenRemoved ? [] : [validateDateTime, required]}
                    data-qa-id={getQaId(nameOfFormFields('needsby_date'))}
                    width="100%"
                    disabled={isEditMode}
                    missing_value_id={{ 'data-fc-mv-id': `needsby_date.${task.id}` }}
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('days_supply')] && (
            <Grid
              item
              xs={2}
              className={
                windowFeatureIsEnabled('med_sync') && daysSupplyChecking.daysSupplyInConflict
                  ? classes.daySupplyConflict
                  : ''
              }
            >
              <Field
                name={getFieldNamePerTask(nameOfFormFields('days_supply'), task)}
                label="Days Supply *"
                component={DebouncedNumericField}
                validate={fcHasBeenRemoved ? [] : daysSupplyValidation}
                qaId={nameOfFormFields('days_supply')}
                width="100%"
                disabled={disableDaysSupply || (!isFillCycleAfterCurrent && isEditMode)}
                missing_value_id={{ 'data-fc-mv-id': `days_supply.${task.id}` }}
              />
              {isPatientMedicare ? <NinetyDaysBanner /> : null}
              {windowFeatureIsEnabled('med_sync') &&
                daysSupplyChecking.daysSupplyToolTip.length > 0 && (
                  <Tooltip title={daysSupplyChecking.daysSupplyToolTip} placement="top-start">
                    <Box
                      className={classes.daysSupplyInfo}
                      sx={{
                        opacity: daysSupplyChecking.daysSupplyInConflict ? 1 : 0.48,
                        paddingTop: '2px',
                      }}
                    >
                      <MedSyncStatusIcon
                        size="xs"
                        displayStatus={
                          daysSupplyChecking.daysSupplyInConflict
                            ? MedSyncDisplayStatus.InConflict
                            : MedSyncDisplayStatus.Default
                        }
                      />
                    </Box>
                  </Tooltip>
                )}
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('dispense_qty')] && (
            <Grid item xs={2}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('dispense_qty'), task)}
                label={`Dispense Quantity${isEditMode ? '' : ' *'}`}
                component={DebouncedNumericField}
                validate={isEditMode || fcHasBeenRemoved ? [] : requiredWhenComplete}
                qaId={nameOfFormFields('dispense_qty')}
                width="100%"
                decimal
                suffix={dispenseQtySuffix ?? undefined}
                disabled={isEditMode && !isFillCycleAfterCurrent}
                missing_value_id={{ 'data-fc-mv-id': `dispense_qty.${task.id}` }}
              />
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('next_needsby_date')] && (
            <Grid item xs={3}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('next_needsby_date'), task)}
                label="Next Needs by Date"
                component={renderDatePicker}
                disabled
                validate={fcHasBeenRemoved ? [] : [validateDateTime].concat(requiredWhenComplete)}
                data-qa-id={getQaId(nameOfFormFields('next_needsby_date'))}
                fullWidth
              />
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('copay_amount')] && (
            <Grid item xs={3}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('copay_amount'), task)}
                label="Copay Amount"
                component={renderMoneyTextField}
                data-qa-id={getQaId(nameOfFormFields('copay_amount'))}
                width="100%"
                disabled={isEditMode && !isFillCycleAfterCurrent}
              />
            </Grid>
          )}
          {/* Service Group selection */}
          {fieldSettings[nameOfFormFields('service_group_id')] && (
            <Grid item xs={3}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('service_group_id'), task)}
                label="Service Group *"
                validate={fcHasBeenRemoved ? [] : requiredWhenComplete}
                data-qa-id={getQaId(nameOfFormFields('service_group_id'))}
                component={renderDropdown}
                fields={serviceGroupOptions}
                disabled={isEditMode}
              />
            </Grid>
          )}
          {!therapyHiddenQuestions[therapy.id]?.includes('rems_authorization_code') &&
            windowFeatureIsEnabled('rems_authorization_code') &&
            fieldSettings[nameOfFormFields('authorization_code')] && (
              <Grid item xs={3}>
                <Field
                  name={getFieldNamePerTask(nameOfFormFields('authorization_code'), task)}
                  label="REMS Dispense Authorization Code *"
                  component={renderDebouncedTextField}
                  validate={[required]}
                  data-qa-id={getQaId(nameOfFormFields('authorization_code'))}
                  missing_value_id={{ 'data-fc-mv-id': `authorization_code.${task.id}` }}
                />
              </Grid>
            )}
          {windowFeatureIsEnabled('rx_number') && fieldSettings[nameOfFormFields('rx_number')] && (
            <Grid item xs={3}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('rx_number'), task)}
                label="Rx Number *"
                component={renderTextField}
                validate={fcHasBeenRemoved ? [] : requiredWhenComplete}
                data-qa-id={getQaId(nameOfFormFields('rx_number'))}
                width="100%"
                disabled={isEditMode && !isFillCycleAfterCurrent}
                missing_value_id={{ 'data-fc-mv-id': `rx_number.${task.id}` }}
              />
            </Grid>
          )}
          {fieldSettings[nameOfFormFields('drop_ship_med')] && (
            <Grid item xs={3}>
              <Field
                name={getFieldNamePerTask(nameOfFormFields('drop_ship_med'), task)}
                label="Drop-Ship Medication"
                labelClassName={classes.checkboxMediumLabel}
                component={therapy.drop_ship_med ? renderCheckboxInline : () => {}}
                width="100%"
                disabled
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }

  const getWorkedAsGroupText = (): string => {
    const groupedFCIds = props.groupedFCIds ?? [];
    const numberOfGroupedFCs = groupedFCIds.length;
    const groupedTherapiesPluralOrSingular = numberOfGroupedFCs === 1 ? 'therapy' : 'therapies';
    return `This therapy was worked as a group with ${numberOfGroupedFCs} other ${groupedTherapiesPluralOrSingular}.`;
  };

  const getGroupedTherapyNames = (): string[] => {
    const groupedFCIds = props.groupedFCIds ?? [];
    const patientTaskDetails = Object.values(patientTasks) ?? [];
    return patientTaskDetails
      .filter(task => groupedFCIds.includes(task.id) && task.taskType === FC)
      .map(task => task.therapy_id)
      .map(therapyId => therapyList?.[therapyId])
      .filter(therapy => therapy != null)
      .map(therapy =>
        formatTherapyNameLong(
          therapy.drug_name,
          therapy.dosage_form,
          therapy.strength,
          therapy.strength_unit_of_measure,
          therapy.dosage_form_description,
          therapy.ndc,
        ),
      )
      .sort();
  };

  const hasQuestions =
    fieldSettings[nameOfFormFields('welcome_kit_sent')] ||
    fieldSettings[nameOfFormFields('is_notice_privacy_practices_sent')] ||
    fieldSettings[nameOfFormFields('new_allergies')] ||
    fieldSettings[nameOfFormFields('new_infections')] ||
    fieldSettings[nameOfFormFields('new_medical_conditions')] ||
    fieldSettings[nameOfFormFields('hospital_visit')] ||
    fieldSettings[nameOfFormFields('side_effects')] ||
    fieldSettings[nameOfFormFields('patient_questions')] ||
    fieldSettings[nameOfFormFields('changes_in_medication')] ||
    fieldSettings[nameOfFormFields('patient_missed_doses')];

  const showDispensingPharmacyWarning =
    !!preferredRxDeliveryPharmacy?.name &&
    (formValues?.dispensing_pharmacy?.dispensing_pharmacy_name !==
      preferredRxDeliveryPharmacy?.name ||
      formValues?.dispensing_pharmacy?.dispensing_pharmacy_npi !==
        preferredRxDeliveryPharmacy?.npi);

  function renderDispPharmSyncCheckbox() {
    return null;
    // const hasDispPharmField = fieldSettings[nameOfFormFields('dispensing_pharmacy')];
    // return hasDispPharmField && !isEditMode ? (
    //   <Grid item xs={11} style={{ paddingLeft: '0px' }}>
    //     <Field
    //       data-qa-id="from-fc-apply-choice"
    //       styleOverride={{ alignItems: 'flex-end', height: '56px' }}
    //       labelOverride={{ fontSize: '14px', fontWeight: 500, order: 1 }}
    //       name="apply_choice_to_therapies_and_FCs"
    //       label="Make this the Patient default and update active Therapies and FCs"
    //       component={renderCheckboxHorizontal}
    //     />
    //   </Grid>
    // ) : null;
  }
  const displaySendSecureLinkWarning = async () => {
    return new Promise(fn_continue => {
      props.setShowSendSecureLinkWarning(() => ({
        show: true,
        dialogCancel: () => {
          props.setShowSendSecureLinkWarning({ show: false });
          fn_continue(false);
        },
        dialogContinue: () => {
          props.setShowSendSecureLinkWarning({ show: false });
          fn_continue(true);
        },
      }));
    });
  };

  async function handleSendSecureLink() {
    if (showSendSecureLinkWarning) {
      const sendLink = await displaySendSecureLinkWarning();
      if (!sendLink) {
        return;
      }
    }
    setFCssFormIsSubmitting(true);
    setSendLinkButtonText('...Sending Link');
    const payload = {
      patient_id: patient.id,
      fc_task_ids: props.fcTaskIds,
      patient_sms_phone_number: smsPhoneNumber,
    };
    try {
      const response = await SecureFCClient.sendSecureLink(payload);
      setFCssFormIsSubmitting(false);
      const { updatedTasks } = response;
      dispatch(integrateUpdatedTasks(updatedTasks));
      dispatch(fetchNotes({ patient_id: patient.id }));
      dispatch(notifySuccess('Secure link was sent successfully'));
      dispatch({ type: SET_SHOW_SET_SELECTED_TASKS, payload: false });
    } catch (err: any) {
      if (err.response?.data?.message) {
        dispatch(notifyError(err.response.data.message));
      } else {
        dispatch(notifyError('Error sending secure link'));
      }
    } finally {
      setFCssFormIsSubmitting(false);
      setSendLinkButtonText('Send Link To Patient');
    }
  }

  async function handleResendSecureLink() {
    setFCssFormIsSubmitting(true);
    setResendLinkButtonText('...Resending Link');
    const payload = {
      patient_id: patient.id,
      secure_link_id: props.tasks[0].secure_link_id,
      patient_sms_phone_number: smsPhoneNumber,
    };
    try {
      const response = await SecureFCClient.resendSecureLink(payload);
      dispatch(response);
      dispatch({ type: SET_SHOW_SET_SELECTED_TASKS, payload: false });
      // dispatch(fetchNotes({ patient_id: patient.id }));
    } catch (error) {
      dispatch(notifyError('Error resending secure link'));
    } finally {
      setFCssFormIsSubmitting(false);
      setResendLinkButtonText(RESEND_BUTTON_TEXT);
    }
  }

  async function handleDeactivateSecureLink() {
    const user_response = await new Promise((a, _) => {
      setDeactivateWarningConfig({ show: true, cancel: () => a(false), continue: () => a(true) });
    });
    setDeactivateWarningConfig({ show: false, cancel: () => {}, continue: () => {} });
    if (!user_response) {
      return;
    }
    setFCssFormIsSubmitting(true);
    setDeactiveLinkButtonText('...Processing');
    const payload = {
      secure_link_id: props.tasks[0].secure_link_id,
    };
    try {
      const response = await SecureFCClient.invalidateSecureLink(payload);
      const { updatedTasks } = response;
      dispatch(integrateUpdatedTasks(updatedTasks));
      dispatch(notifySuccess('Secure Link Deactivated'));
      dispatch({ type: SET_SHOW_SET_SELECTED_TASKS, payload: false });
    } catch (err) {
      dispatch(notifyError('Error Deactivating the Link'));
    } finally {
      setDeactiveLinkButtonText('Deactivate Link');
      setDeactivateWarningConfig({ show: false, cancel: () => {}, continue: () => {} });
      setFCssFormIsSubmitting(false);
    }
  }

  const render_normal_fc_form =
    props.componentMode === 'edit' ||
    (startingStatus === FillCoordinationStatus.Required &&
      selectedNextStatus === FillCoordinationStatus.Completed) ||
    [
      FillCoordinationStatus.Canceled_MedicationDiscontinued,
      FillCoordinationStatus.Canceled_MedicationPlacedOnHold,
      FillCoordinationStatus.Canceled_PharmacyNoLongerFilling,
      FillCoordinationStatus.Canceled_PatientUnreachable,
      FillCoordinationStatus.In_Progress,
      FillCoordinationStatus.NeedsReview,
      FillCoordinationStatus.Canceled_PatientDeceased,
      FillCoordinationStatus.Canceled_TherapyComplete,
      FillCoordinationStatus.Canceled,
      FillCoordinationStatus.Canceled_Other,
    ].includes(selectedNextStatus) ||
    [
      FillCoordinationStatus.In_Progress,
      FillCoordinationStatus.NeedsReview,
      FillCoordinationStatus.In_Progress_Call_Patient,
      FillCoordinationStatus.In_Progress_Patient_Response_Ready,
      FillCoordinationStatus.In_Progress_Secure_Link_Expired,
      FillCoordinationStatus.In_Progress_Left_Vm,
    ].includes(startingStatus);

  const renderNormalFCForm = () => {
    const secure_link_sent_dt = new ArborDate(
      props.tasks[0].secure_link_sent_dt,
      true,
    ).getCustomerDatetime(true);
    const patient_responded_dt = new ArborDate(
      props.tasks[0].secure_link_completed_dt,
      true,
    ).getCustomerDatetime(true);
    const secure_link_expired_dt = new ArborDate(
      props.tasks[0].secure_link_expiration_dt,
      true,
    ).getCustomerDatetime(true);
    // eslint-disable-next-line no-return-assign

    const isAProviderAddress =
      (formValues?.address?.value as IAddress)?.use === 'Provider/Clinic' ||
      (formValues?.address?.value as IAddress)?.use === 'Provider' ||
      formValues?.address?.label?.includes('(Provider)') ||
      false;

    const showProviderAddressWarning =
      containsAClinicAdministeredTherapy &&
      formValues?.preferred_rx_delivery_method?.value !== undefined &&
      formValues.preferred_rx_delivery_method.value > 0 &&
      formValues.preferred_rx_delivery_method.value !== DeliveryMethodValue.PickUp &&
      typeof formValues?.address?.value === 'object' &&
      !isAProviderAddress;

    return (
      <>
        {validationDetails ? (
          <AddressValidationModal
            details={validationDetails}
            addressObject={formValues.address}
            callback={finishDeliveryAddressValidation}
          />
        ) : null}
        {startingStatus !== 4011 &&
          controlledMethodIndex !== null &&
          fieldSettings[nameOfFormFields('spoke_to_patient_dt')] && (
            <Grid item xs={6} className={classes.fieldContainer}>
              <Grid container justifyContent="flex-end">
                <Grid item xs={4}>
                  <Field
                    name={nameOfFormFields('spoke_to_patient_dt')}
                    label={props.methodIsChartReview ? 'Complete Date' : 'Spoke to Patient Date *'}
                    component={renderDatePicker}
                    validate={[validateDate].concat(requiredWhenComplete)}
                    data-qa-id={getQaId(nameOfFormFields('spoke_to_patient_dt'))}
                    disabled={props.componentMode === EditComponentMode.Edit}
                    missing_value_id={{ 'data-fc-mv-id': 'spoke_to_patient_dt' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {startingStatus === 4010 ||
        startingStatus === 4011 ||
        (props.tasks[0].secure_link_id &&
          props.tasks[0].secure_link_id > 0 &&
          startingStatus !== 4012) ? (
          <Grid item xs={12} className={classes.fieldContainer}>
            <div data-secure-link-id={props.tasks[0].secure_link_id}>
              {`Secure link sent to patient by ${secureFCLinkSender} on ${secure_link_sent_dt}.`}
            </div>
            <div>{`Patient responded on ${patient_responded_dt}`}</div>
          </Grid>
        ) : null}
        {startingStatus === 4012 && (
          <Grid item xs={12} className={classes.fieldContainer}>
            <div>
              {`Secure link sent to patient by ${secureFCLinkSender} on ${secure_link_sent_dt}.`}
            </div>
            <div>{`Secure Link Expired on ${secure_link_expired_dt}`}</div>
          </Grid>
        )}
        {patientUndecided && (
          <Grid item xs={12} className={classes.fieldContainerWithRedTextThin}>
            <div>
              <span className={classes.redSquareWithTextInside}>!SMS </span>
              Patient SMS preferences need to be addressed before completing FC
            </div>
          </Grid>
        )}
        {callPatientReasons.map((item: string) => (
          <Grid item xs={12} className={classes.fieldContainerWithRedTextThin}>
            <div>
              <span className={classes.redCircleExclamationPoint}>!</span>
              {item}
            </div>
          </Grid>
        ))}
        {missingFCTaskIds.length ? (
          <Grid item xs={12} className={classes.fieldContainerWithRedText}>
            <div>
              <span className={classes.redCircleExclamationPoint}>!</span>
              {missingFCTaskIds.length == 1
                ? 'There is 1 missing FC that is included in the same secure link bundle.'
                : `There are ${missingFCTaskIds.length} missing FCs that are included in the same secure link bundle.`}
            </div>
            <Button
              style={{ color: '#44484C', backgroundColor: 'white', border: '1px solid #C4C4C4' }}
              onClick={addSelections}
            >
              Add Missing FCs
            </Button>
          </Grid>
        ) : null}
        {props.tasks[0].patient_requested_therapy_list_changes && therapiestoRemoveAsString ? (
          <Grid item xs={12} className={classes.fieldContainerWithRedText}>
            <div>
              <span className={classes.redCircleExclamationPoint}>!</span>
              Patient requested removal of the following therapies from this Fill Coordination:{' '}
              {therapiestoRemoveAsString}.
            </div>
          </Grid>
        ) : null}
        {fieldSettings[nameOfFormFields('notes')] && (
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.fieldContainer}
              data-qa-id={getQaId(nameOfFormFields('notes'))}
            >
              <Field
                name={nameOfFormFields('notes')}
                data={props.userData}
                component={NoteArea}
                disabled={props.componentMode === EditComponentMode.Edit}
                // validate={drugNameValidation} <- TODO: Need to verify why this is causing the debounce error
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item className={classes.noteButtonSpacing}>
                  <Communication
                    id={`FC_${props.tasks[0].id}_therapy_task_contact`}
                    task={props.tasks[0]}
                    tagTypeId={
                      noteTagTypes.find(tag => tag.label === props.tasks[0]?.taskType)?.value
                    }
                    tagResourceId={props.tasks[0]?.id}
                    formId={props.formName}
                  />
                </Grid>
                <Grid item className={classes.noteButtonSpacing}>
                  <Intervention
                    therapy={therapies.find(t => t.id === props.tasks[0].therapy_id)} // eslint-disable-line
                    defaultServiceGroup={props.tasks[0]?.service_group_id} // eslint-disable-line
                    tagTypeId={
                      noteTagTypes.find(tag => tag.label === props.tasks[0]?.taskType)?.value
                    }
                    tagResourceId={props.tasks[0]?.id}
                    serviceGroups={serviceGroups}
                    taskType={`task_${
                      props.tasks[0] && props.tasks[0]?.type
                        ? props.tasks[0]?.type.toLowerCase().split(' ').join('_')
                        : ''
                    }`}
                  />
                </Grid>
                <Grid item className={classes.noteButtonSpacing}>
                  <Incident
                    therapy={therapies.find(t => t.id === props.tasks[0].therapy_id)} // eslint-disable-line
                    defaultServiceGroup={props.tasks[0]?.service_group_id} // eslint-disable-line
                    tagTypeId={
                      noteTagTypes.find(tag => tag.label === props.tasks[0]?.taskType)?.value
                    }
                    tagResourceId={props.tasks[0]?.id}
                    serviceGroups={serviceGroups}
                    taskType={`task_${
                      props.tasks[0] && props.tasks[0]?.type
                        ? props.tasks[0]?.type.toLowerCase().split(' ').join('_')
                        : ''
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {hasQuestions && (
          <Grid item xs={12}>
            <FieldTitle
              title="Update Patient Status"
              data-qa-id={getQaId('patient-status-title')}
            />
          </Grid>
        )}
        {!hiddenQuestions.includes('welcome_kit_sent') &&
          fieldSettings[nameOfFormFields('welcome_kit_sent')] && (
            <Grid
              data-fc-mv-id="welcome_kit_sent"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                {renderFieldLabel('Welcome kit required? *', 'medium')}
              </Grid>

              <Grid
                item
                xs={3}
                data-qa-id={getQaId(nameOfFormFields('welcome_kit_sent'))}
                className={classes.yesNoQuestionText}
              >
                <Field
                  name={nameOfFormFields('welcome_kit_sent')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  validate={requiredWhenComplete}
                  disabled={props.componentMode === EditComponentMode.Edit}
                />
              </Grid>
            </Grid>
          )}
        {!hiddenQuestions.includes('wk_pp_received') &&
          fieldSettings[nameOfFormFields('wk_pp_received')] && (
            <>
              <Grid
                data-fc-mv-id="wk_pp_received"
                container
                item
                xs={12}
                className={classes.yesNoQuestionContainer}
              >
                <Grid item xs={9} className={classes.yesNoQuestionText}>
                  {renderFieldLabel('Welcome kit and Privacy Policy received? *', 'medium')}
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.yesNoQuestionText}
                  data-qa-id={getQaId(nameOfFormFields('wk_pp_received'))}
                >
                  <Field
                    name={nameOfFormFields('wk_pp_received')}
                    component={RenderRadioGroup}
                    radioMap={staticProviders.yesNo01.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={requiredWhenComplete}
                    disabled={props.componentMode === EditComponentMode.Edit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        {!hiddenQuestions.includes('is_notice_privacy_practices_sent') &&
          fieldSettings[nameOfFormFields('is_notice_privacy_practices_sent')] && (
            <>
              <Grid
                data-fc-mv-id="is_notice_privacy_practices_sent"
                container
                item
                xs={12}
                className={classes.yesNoQuestionContainer}
              >
                <Grid item xs={9} className={classes.yesNoQuestionText}>
                  <FieldLabel fieldName="Privacy notice required? *" labelSize="medium" />
                </Grid>

                <Grid
                  item
                  xs={3}
                  className={classes.yesNoQuestionText}
                  data-qa-id={getQaId(nameOfFormFields('is_notice_privacy_practices_sent'))}
                >
                  <Field
                    name={nameOfFormFields('is_notice_privacy_practices_sent')}
                    component={RenderRadioGroup}
                    radioMap={staticProviders.yesNo01.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={definedWhenComplete.concat(requiredWhenComplete)}
                    disabled={props.componentMode === EditComponentMode.Edit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        {!hiddenQuestions.includes('new_allergies') &&
          fieldSettings[nameOfFormFields('new_allergies')] && (
            <Grid
              data-fc-mv-id="new_allergies"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <div data-fc-mv-start="new_allergies" />
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                <Grid item xs={12}>
                  {renderFieldLabel('Does the Patient have any new Allergies? *', 'medium')}
                </Grid>
                <Grid item xs={12}>
                  <Link
                    className={classes.toggleLabel}
                    style={{ textDecoration: 'none' }}
                    onClick={() => {
                      setShowAllergiesList(!showAllergiesList);
                    }}
                  >
                    {showAllergiesList ? 'HIDE ALLERGIES LIST' : 'SHOW ALLERGIES LIST'}
                  </Link>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.yesNoQuestionText}
                data-qa-id={getQaId(nameOfFormFields('new_allergies'))}
              >
                <Field
                  name={nameOfFormFields('new_allergies')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  disabled={props.componentMode === EditComponentMode.Edit || disableNewAllergies}
                  validate={concatWithDefinedValidator(
                    props.selectedStatus === FillCoordinationStatus.Completed &&
                      !definedBoolean(props.tasks[0].new_allergies),
                    requiredWhenComplete.concat(showAddAllergyForm ? [missing] : []),
                  )}
                />
              </Grid>
              <SecureLinkClinicalResponse comments={clinicalResponses} key_name="new_allergies" />
              {(showAllergiesList || showAddAllergyForm) && (
                <Grid container spacing={2} marginTop={1} className={classes.paddedContainer}>
                  <Grid item xs={12}>
                    <PatientAllergiesList
                      minimal
                      options={{
                        showAllergyStatus: false,
                        showVerificationPanel: true,
                        showTopActionsBar: false,
                        renderingOrder: [
                          RENDERING_SECTIONS.ALLERGIES_LIST,
                          RENDERING_SECTIONS.ADD_ALLERGY_FORM,
                        ],
                        showAddAllergyForm:
                          showAddAllergyForm && props.componentMode !== EditComponentMode.Edit,
                        showAllergiesList,
                      }}
                      allergies={patientAllergies}
                      cancelAddForm={() => setShowAddAllergyForm(false)}
                      postSave={() => setDisableNewAllergies(true)}
                    />
                  </Grid>
                  {props.haveNewAllergies &&
                    !showAddAllergyForm &&
                    props.componentMode !== EditComponentMode.Edit && (
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          disableRipple
                          disableElevation
                          className={classes.newButton}
                          onClick={() => setShowAddAllergyForm(true)}
                        >
                          Add New Allergy
                        </Button>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
          )}
        {!hiddenQuestions.includes('side_effects') &&
          fieldSettings[nameOfFormFields('side_effects')] && (
            <Grid
              data-fc-mv-id="side_effects"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <div data-fc-mv-start="side_effects" />
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                {renderFieldLabel(
                  'Has the patient experienced any side effects since last time we spoke? *',
                  'medium',
                )}
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.yesNoQuestionText}
                data-qa-id={getQaId(nameOfFormFields('side_effects'))}
              >
                <Field
                  name={nameOfFormFields('side_effects')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                    disabled: existingSideEffectInterventions.length > 0 && item.id === 0,
                  }))}
                  disabled={props.componentMode === EditComponentMode.Edit}
                  onChange={(v: unknown) => {
                    if (props.newInterventions && props.changeNewInterventionsHandler) {
                      if (v === 0) {
                        const newInterventionsWithoutSideEffects = props.newInterventions.filter(
                          intervention => intervention.sideeffect_type !== SideEffectType.Common,
                        );
                        props.changeNewInterventionsHandler(newInterventionsWithoutSideEffects);
                      } else if (v === 1 && existingSideEffectInterventions.length === 0) {
                        props.changeNewInterventionsHandler([
                          ...props.newInterventions,
                          getDefaultSideEffectsInt(SideEffectType.Common),
                        ]);
                      }
                    }
                  }}
                  validate={concatWithDefinedValidator(
                    props.selectedStatus === FillCoordinationStatus.Completed &&
                      !definedBoolean(props.tasks[0].side_effects),
                    requiredWhenComplete,
                  )
                    .concat(
                      showNewSideEffects &&
                        props.componentMode !== EditComponentMode.Edit &&
                        missingReaction &&
                        existingSideEffectInterventions.length === 0 &&
                        props.selectedStatus === FCStatus.completed
                        ? [missingReactionValidation]
                        : [],
                    )
                    .concat(
                      showNewSideEffects &&
                        props.componentMode !== EditComponentMode.Edit &&
                        existingSideEffectInterventions.length === 0 &&
                        missingSuspectedCausedBy &&
                        props.selectedStatus === FCStatus.completed
                        ? [missingSuspectedCausedByValidation]
                        : [],
                    )}
                />
              </Grid>
              <SecureLinkClinicalResponse comments={clinicalResponses} key_name="side_effects" />
              {showNewSideEffects && props.componentMode !== EditComponentMode.Edit && (
                <Grid
                  container
                  spacing={1}
                  className={classes.paddedContainer}
                  data-qa-id={getQaId('side-effect-container')}
                >
                  {existingSideEffectInterventions.map(intervention => (
                    <Grid item xs={9}>
                      <Typography className={classes.existingInterventionsText}>
                        {intervention.details}
                      </Typography>
                    </Grid>
                  ))}
                  {newSideEffectInterventions.map(intervention => (
                    <React.Fragment key={`fc_sideeffect_${intervention.index}`}>
                      <Grid item xs={4}>
                        <RenderDropdown
                          label="Side Effects *"
                          meta={{}}
                          fields={sideEffectOptions
                            .sort((x, y) => x.description.localeCompare(y.description))
                            .map(x => ({
                              label: StringUtils.upperCaseFirstLetter(x.description),
                              value: x.description,
                            }))}
                          width="100%"
                          input={{
                            value: intervention.int.reaction,
                            onChange: (value: string) => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const newIntStateCopy = [...props.newInterventions];
                                const newInt = {
                                  ...newIntStateCopy[intervention.index],
                                  reaction: value,
                                };

                                if (
                                  newInt.suspected_caused_by &&
                                  newInt.suspected_caused_by.text &&
                                  newInt.reaction
                                ) {
                                  newInt.details = buildDetails(
                                    newInt.reaction,
                                    newInt.suspected_caused_by.text,
                                  );
                                }
                                newIntStateCopy[intervention.index] = newInt;

                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <RenderDropdown
                          label="Suspected Caused By *"
                          meta={{}}
                          fields={Object.entries(
                            buildCausedByOptions(therapies, groupedMedications),
                          ).map(([key, _value]) => {
                            return {
                              label: key,
                              value: key,
                            };
                          })}
                          width="100%"
                          input={{
                            value: intervention.int.suspected_caused_by?.text,
                            onChange: (value: string) => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const causedByOptions = buildCausedByOptions(
                                  therapies,
                                  groupedMedications,
                                );
                                const newIntStateCopy = [...props.newInterventions];
                                const newInt = {
                                  ...newIntStateCopy[intervention.index],
                                  suspected_caused_by: {
                                    ...causedByOptions[value],
                                    text: value,
                                  },
                                };

                                if (
                                  newInt.suspected_caused_by &&
                                  newInt.suspected_caused_by.text &&
                                  newInt.reaction
                                ) {
                                  newInt.details = buildDetails(
                                    newInt.reaction,
                                    newInt.suspected_caused_by.text,
                                  );
                                }

                                newIntStateCopy[intervention.index] = newInt;
                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {(newSideEffectInterventions.length > 1 ||
                          existingSideEffectInterventions.length > 0) && (
                          <CloseIcon
                            fontSize="small"
                            className={classes.closeIcon}
                            onClick={() => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const newIntStateCopy = [...props.newInterventions];
                                newIntStateCopy.splice(intervention.index, 1);
                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            }}
                          />
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      disableRipple
                      disableElevation
                      className={classes.newButton}
                      onClick={() => {
                        if (props.newInterventions && props.changeNewInterventionsHandler) {
                          props.changeNewInterventionsHandler([
                            ...props.newInterventions,
                            getDefaultSideEffectsInt(SideEffectType.Common),
                          ]);
                        }
                      }}
                    >
                      Add New Side Effect
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        {fieldSettings[nameOfFormFields('changes_in_medication')] &&
          !hiddenQuestions.includes('changes_in_medication') && (
            <Grid
              data-fc-mv-id="changes_in_medication"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <div data-fc-mv-start="changes_in_medication" />
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                <Grid item xs={12}>
                  {renderFieldLabel('Are there any Changes to Patient Medications? *', 'medium')}
                </Grid>
                <Grid item xs={12}>
                  <Link
                    className={classes.toggleLabel}
                    style={{ textDecoration: 'none' }}
                    onClick={() => setShowMedicationsList(!showMedicationsList)}
                  >
                    {showMedicationsList ? 'HIDE MEDICATIONS LIST' : 'SHOW MEDICATIONS LIST'}
                  </Link>
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.yesNoQuestionText}
                data-qa-id={getQaId(nameOfFormFields('changes_in_medication'))}
              >
                <Field
                  name={nameOfFormFields('changes_in_medication')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  disabled={
                    props.componentMode === EditComponentMode.Edit || disableChangesInMedications
                  }
                  validate={concatWithDefinedValidator(
                    props.selectedStatus === FillCoordinationStatus.Completed &&
                      !definedBoolean(props.tasks[0].changes_in_medication),
                    requiredWhenComplete.concat(
                      addNewMedicationFormIsOpenAndWaiting ? [missing] : [],
                    ),
                  )}
                />
              </Grid>
              <SecureLinkClinicalResponse
                comments={clinicalResponses}
                key_name="changes_in_medication"
              />
              {(showMedicationsList || showAddMedicationForm) && (
                <Grid container spacing={3} marginTop={1} className={classes.paddedContainer}>
                  <Grid item xs={12}>
                    <MedicationList
                      patient={patient}
                      showMedicationList
                      medicationList={medicationsWithTherapies}
                      overwriteGroups={medicationGroupsWithTherapies}
                      isLoading={false}
                      minimal
                      options={{
                        showTableViewToggle: false,
                        showMedicationStatus: false,
                        showVerificationPanel: true,
                        showTopActionsBar: false,
                        showMedicationsList,
                        showAddMedicationForm:
                          showAddMedicationForm && props.componentMode !== EditComponentMode.Edit,
                      }}
                      readOnly={false}
                      cancelAddForm={() => setShowAddMedicationForm(false)}
                      postSave={() => setDisableChangesInMedications(true)}
                    />
                  </Grid>
                  {props.areMedicationChanges &&
                    !showAddMedicationForm &&
                    props.componentMode !== EditComponentMode.Edit && (
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          disableRipple
                          disableElevation
                          className={classes.newButton}
                          onClick={() => setShowAddMedicationForm(true)}
                        >
                          Add New Medication
                        </Button>
                      </Grid>
                    )}
                </Grid>
              )}
            </Grid>
          )}
        {fieldSettings[nameOfFormFields('patient_missed_doses')] &&
          !hiddenQuestions.includes('patient_missed_doses') && (
            <Grid
              data-fc-mv-id="patient_missed_doses"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                {renderFieldLabel('Has the patient missed any doses of medication? *', 'medium')}
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.yesNoQuestionText}
                data-qa-id={getQaId(nameOfFormFields('patient_missed_doses'))}
              >
                <Field
                  name={nameOfFormFields('patient_missed_doses')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                    disabled: existingMissedDosesInterventions.length > 0 && item.id === 0,
                  }))}
                  onChange={(v: unknown) => {
                    if (props.newInterventions && props.changeNewInterventionsHandler) {
                      if (v === 0) {
                        const newInterventionsWithoutMissedDoses = props.newInterventions.filter(
                          intervention =>
                            intervention.sideeffect_type !== SideEffectType.MissedMedicationDose,
                        );
                        props.changeNewInterventionsHandler(newInterventionsWithoutMissedDoses);
                      } else if (v === 1 && existingMissedDosesInterventions.length === 0) {
                        props.changeNewInterventionsHandler([
                          ...props.newInterventions,
                          getDefaultSideEffectsInt(SideEffectType.MissedMedicationDose),
                        ]);
                      }
                    }
                  }}
                  validate={requiredWhenComplete
                    .concat(
                      showMissedDoses &&
                        props.componentMode !== EditComponentMode.Edit &&
                        existingMissedDosesInterventions.length === 0 &&
                        missingMissedDoseMedication &&
                        props.selectedStatus === FCStatus.completed
                        ? [missingMedicationValidation]
                        : [],
                    )
                    .concat(
                      showMissedDoses &&
                        props.componentMode !== EditComponentMode.Edit &&
                        existingMissedDosesInterventions.length === 0 &&
                        missingMissedDoseDetails &&
                        props.selectedStatus === FCStatus.completed
                        ? [missingDetailsValidation]
                        : [],
                    )}
                  data-qa-id={getQaId(nameOfFormFields('patient_missed_doses'))}
                  disabled={props.componentMode === EditComponentMode.Edit}
                />
              </Grid>
              <SecureLinkClinicalResponse
                comments={clinicalResponses}
                key_name="patient_missed_doses"
              />
              {showMissedDoses && props.componentMode !== EditComponentMode.Edit && (
                <Grid container spacing={1} className={classes.paddedContainer}>
                  {existingMissedDosesInterventions.map(intervention => (
                    <Grid item xs={9}>
                      <Typography className={classes.existingInterventionsText}>
                        {intervention.details}
                      </Typography>
                    </Grid>
                  ))}
                  {filterIntsBySideEffectType(
                    props.newInterventions || [],
                    SideEffectType.MissedMedicationDose,
                  ).map(intervention => (
                    <React.Fragment key={`fc_missed_dose_sideeffect_${intervention.index}`}>
                      <Grid item xs={4}>
                        <RenderDropdown
                          label="Medication *"
                          meta={{}}
                          fields={Object.entries(
                            buildCausedByOptions(therapies, groupedMedications, false),
                          ).map(([key, _value]) => {
                            return {
                              label: key,
                              value: key,
                            };
                          })}
                          width="100%"
                          input={{
                            value: intervention.int.suspected_caused_by?.text,
                            onChange: (value: string) => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const causedByOptions = buildCausedByOptions(
                                  therapies,
                                  groupedMedications,
                                  false,
                                );
                                const newIntStateCopy = [...props.newInterventions];
                                const newInt = {
                                  ...newIntStateCopy[intervention.index],
                                  suspected_caused_by: {
                                    ...causedByOptions[value],
                                    text: value,
                                  },
                                };
                                newIntStateCopy[intervention.index] = newInt;
                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <RenderTextField
                          label="Details *"
                          meta={{}}
                          disabled={false}
                          width="100%"
                          multiline
                          input={{
                            value: intervention.int.details,
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const newIntStateCopy = [...props.newInterventions];
                                const newInt = {
                                  ...newIntStateCopy[intervention.index],
                                  details: event.target.value,
                                };
                                newIntStateCopy[intervention.index] = newInt;
                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            },
                          }}
                          labelSize="small"
                          qaId={getQaId(
                            `${nameOfFormFields('patient_missed_doses')}_sideeffect_details`,
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {(newMissedDoseInterventions.length > 1 ||
                          existingMissedDosesInterventions.length > 0) && (
                          <CloseIcon
                            fontSize="small"
                            className={classes.closeIcon}
                            onClick={() => {
                              if (props.newInterventions && props.changeNewInterventionsHandler) {
                                const newIntStateCopy = [...props.newInterventions];
                                newIntStateCopy.splice(intervention.index, 1);
                                props.changeNewInterventionsHandler(newIntStateCopy);
                              }
                            }}
                          />
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      disableRipple
                      disableElevation
                      className={classes.newButton}
                      onClick={() => {
                        if (props.newInterventions && props.changeNewInterventionsHandler) {
                          props.changeNewInterventionsHandler([
                            ...props.newInterventions,
                            getDefaultSideEffectsInt(SideEffectType.MissedMedicationDose),
                          ]);
                        }
                      }}
                    >
                      Add Missing Dose For Other Medication
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        {fieldSettings[nameOfFormFields('patient_questions')] &&
          !hiddenQuestions.includes('patient_questions') && (
            <>
              <Grid
                data-fc-mv-id="patient_questions"
                container
                item
                xs={12}
                className={classes.yesNoQuestionContainer}
              >
                <Grid item xs={9} className={classes.yesNoQuestionText}>
                  {renderFieldLabel(
                    'Does the patient have any questions for the pharmacist? *',
                    'medium',
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.yesNoQuestionText}
                  data-qa-id={getQaId(nameOfFormFields('patient_questions'))}
                >
                  <Field
                    name={nameOfFormFields('patient_questions')}
                    component={RenderRadioGroup}
                    radioMap={staticProviders.yesNo01.map(item => ({
                      value: item.id,
                      label: item.name,
                      disabled: props.patientQuestionsDisableNo && item.id === 0,
                    }))}
                    disabled={props.componentMode === EditComponentMode.Edit}
                    validate={concatWithDefinedValidator(
                      props.selectedStatus === FillCoordinationStatus.Completed &&
                        !definedBoolean(props.tasks[0].patient_questions),
                      requiredWhenComplete,
                    )}
                  />
                </Grid>
                <SecureLinkClinicalResponse
                  comments={clinicalResponses}
                  key_name="patient_questions"
                />
              </Grid>
            </>
          )}
        {fieldSettings[nameOfFormFields('new_medical_conditions')] &&
          !hiddenQuestions.includes('new_medical_conditions') && (
            <Grid
              data-fc-mv-id="new_medical_conditions"
              container
              item
              xs={12}
              className={classes.yesNoQuestionContainer}
            >
              <div data-fc-mv-start="new_medical_conditions" />
              <Grid item xs={9} className={classes.yesNoQuestionText}>
                <Grid item xs={12}>
                  {renderFieldLabel(
                    'Does the patient have any new medical conditions? *',
                    'medium',
                  )}
                </Grid>
                <Grid item xs={12}>
                  {props.componentMode !== EditComponentMode.Edit && (
                    <Link
                      component="button"
                      className={classes.toggleLabel}
                      style={{ textDecoration: 'none' }}
                      onClick={() => {
                        setShowAddProblemsForm(false);
                        setShowProblemsList(
                          prevShowProblemsListState => !prevShowProblemsListState,
                        );
                      }}
                    >
                      {showProblemsList ? 'HIDE PROBLEMS LIST' : 'SHOW PROBLEMS LIST'}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={3}
                className={classes.yesNoQuestionText}
                data-qa-id={getQaId(nameOfFormFields('new_medical_conditions'))}
              >
                <Field
                  name={nameOfFormFields('new_medical_conditions')}
                  component={RenderRadioGroup}
                  radioMap={staticProviders.yesNo01.map(item => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  disabled={props.componentMode === EditComponentMode.Edit}
                  onChange={(v: unknown) => {
                    if (v === 1) {
                      setShowProblemsList(true);
                      setShowAddProblemsForm(true);
                    } else {
                      setShowProblemsList(false);
                      setShowAddProblemsForm(false);
                    }
                  }}
                  validate={concatWithDefinedValidator(
                    props.selectedStatus === FillCoordinationStatus.Completed &&
                      !definedBoolean(props.tasks[0].new_medical_conditions),
                    requiredWhenComplete.concat(showProblemsList ? [missing] : []),
                  )}
                />
              </Grid>
              <SecureLinkClinicalResponse
                comments={clinicalResponses}
                key_name="new_medical_conditions"
              />
              {showProblemsList && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ProblemsList
                      problems={problems}
                      showTitle={false}
                      readOnly={false}
                      formOpenByDefault={showAddProblemsForm}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        <HospitalVisit
          fieldSettings={fieldSettings}
          classes={classes}
          fc_details={props}
          clinicalResponses={clinicalResponses}
          hiddenQuestions={hiddenQuestions}
        />
        {fieldSettings[nameOfFormFields('new_infections')] &&
          !hiddenQuestions.includes('new_infections') && (
            <>
              <Grid
                data-fc-mv-id="new_infections"
                container
                item
                xs={12}
                className={classes.yesNoQuestionContainer}
              >
                <Grid item xs={9} className={classes.yesNoQuestionText}>
                  {renderFieldLabel(
                    'Has the patient experienced any infections since we last spoke? *',
                    'medium',
                  )}
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={classes.yesNoQuestionText}
                  data-qa-id={getQaId(nameOfFormFields('new_infections'))}
                >
                  <Field
                    name={nameOfFormFields('new_infections')}
                    component={RenderRadioGroup}
                    radioMap={staticProviders.yesNo01.map(item => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    validate={
                      props.selectedStatus === FillCoordinationStatus.Completed &&
                      props.componentMode !== EditComponentMode.Edit &&
                      concatWithDefinedValidator(
                        !definedBoolean(props.tasks[0].new_infections),
                        requiredWhenComplete,
                      )
                    }
                    disabled={props.componentMode === EditComponentMode.Edit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        {hasQuestions && (
          <>
            <Grid
              item
              xs={9}
              className={cx(
                classes.noToAllOption,
                classes.bottomBorderedRow,
                classes.topBorderedRow,
              )}
            >
              Patient responded No to all questions
            </Grid>
            <Grid
              item
              xs={3}
              className={cx(
                classes.noToAllOption,
                classes.bottomBorderedRow,
                classes.topBorderedRow,
              )}
              data-qa-id="no-to-all-button"
            >
              <Button
                variant="outlined"
                disableRipple
                disableElevation
                disableFocusRipple
                className={classes.noToAllButton}
                onClick={() => setNoToAllQuestions()}
              >
                No to all
              </Button>
            </Grid>
          </>
        )}
        {fieldSettings.titles.fillCoordination && (
          <Grid item xs={12}>
            <FieldTitle title={getTherapySectionTitle()} />
          </Grid>
        )}
        {props.tasks.map(task => {
          if (props.therapies && props.therapies[task.therapy_id]) {
            return renderTherapyFields(task, props.therapies[task.therapy_id]);
          }

          return null;
        })}
        {props.siblingTasks.map(({ task, therapy }) => {
          return renderTherapyFields(task, therapy);
        })}
        {fieldSettings[nameOfFormFields('additional_medications')] && (
          <Grid item xs={12} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('additional_medications')}
              label="Add OTC to the order"
              labelSize="large"
              component={renderMultipleDropdownAsync}
              provider={props.otcProvider}
              placeholder="Search..."
              isMulti
              disabled={props.componentMode === EditComponentMode.Edit}
            />
          </Grid>
        )}

        <Grid container alignItems="center">
          {!patientHasPaymentMethods && !formValues.payment_method_ids?.length ? (
            <Grid item xs={4}>
              <Button
                variant="outlined"
                onClick={openPaymentMethodModal}
                data-qa-id={PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.ADD_BUTTON}
              >
                <EditAdd />
                <Typography className={classes.addPaymentMethodButtonText}>
                  {PATIENT_RX_DELIVERY_PREFERENCES.PAYMENT_METHODS.LABELS.ADD_BUTTON}
                </Typography>
              </Button>
            </Grid>
          ) : null}
          {fieldSettings[nameOfFormFields('payment_method_on_file')] && (
            <Grid
              item
              xs={patientHasPaymentMethods ? 6 : 4}
              className={cx(classes.fieldContainer, classes.paymentMethodWrapper)}
            >
              <Field
                name={nameOfFormFields('payment_method_on_file')}
                label="Payment method on file"
                labelClassName={classes.checkboxMediumLabel}
                component={renderCheckboxInline}
                data-qa-id={getQaId(nameOfFormFields('payment_method_on_file'))}
                disabled={isEditMode || !patientHasPaymentMethods}
              />
              <Tooltip
                title="No payment methods configured in patient demographics"
                placement="bottom-start"
                className={
                  patient.payment_methods?.length || isEditMode ? classes.hidenItem : undefined
                }
              >
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </Grid>
          )}

          {fieldSettings[nameOfFormFields('medicaid_only')] && (
            <Grid item xs={patientHasPaymentMethods ? 6 : 4} className={classes.fieldContainer}>
              <Field
                name={nameOfFormFields('medicaid_only')}
                label="Patient is NOT able to afford Copay (Medicaid)"
                labelClassName={classes.checkboxMediumLabel}
                component={renderCheckboxInline}
                data-qa-id={getQaId(nameOfFormFields('medicaid_only'))}
                disabled={props.componentMode === EditComponentMode.Edit}
              />
            </Grid>
          )}
        </Grid>

        {fieldSettings.payment_method_ids &&
        (formValues.payment_method_ids?.length || patientHasPaymentMethods) &&
        (props.form || props.formName) ? (
          <PaymentMethodsSelectSection
            formName={props.form || props.formName}
            task={selectedTask}
            tasks={props.allTasks}
          />
        ) : null}

        {fieldSettings[nameOfFormFields('ancillary_supplies')] && (
          <Grid container className={classes.fieldContainer}>
            <Grid
              item
              xs={12}
              data-fc-mv-id="ancillary_supplies"
              data-fc-mv-start="ancillary_supplies"
            >
              <Field
                name={nameOfFormFields('ancillary_supplies')}
                component={SingleValueCheckboxGroup}
                validate={isAncillarySuppliesRequired ? requiredWhenComplete : []}
                label="Ancillary Supplies Needed"
                options={ancillarySupplies}
                layoutProps={{ item: true, xs: 4 }}
                formGroupProps={{ 'data-qa-id': getQaId(nameOfFormFields('ancillary_supplies')) }}
                itemProps={ancillarySuppliesItemProps}
              />
            </Grid>
          </Grid>
        )}

        {fieldSettings[nameOfFormFields('order_notes')] && (
          <Grid item xs={12} className={classes.fieldContainer}>
            <Grid item xs={12} data-fc-mv-id="order_notes" data-fc-mv-start="order_notes">
              <Field
                name={nameOfFormFields('order_notes')}
                component={NoteArea}
                defaultPlaceholder="Order Notes"
                data-qa-id={getQaId(nameOfFormFields('order_notes'))}
                disabled={isCompleted && (!isFillCycleAfterCurrent || isFDCCompleted)}
                maxLength={ORDER_NOTES_LIMIT}
                debounceOverride={50}
                inputProps={{
                  onFocus: () => setInOrderNoteArea(true),
                  onBlur: () => setInOrderNoteArea(false),
                }}
              />
            </Grid>
          </Grid>
        )}
        <NoteFieldCharacterLimitMessage
          note={formValues.order_notes}
          active={
            !!(
              inOrderNoteArea ||
              (formValues.order_notes && formValues.order_notes?.length > ORDER_NOTES_LIMIT)
            )
          }
          type="fc"
        />
        {fieldSettings.titles.delivery && (
          <Grid item xs={12}>
            <FieldTitle title="Delivery" />
          </Grid>
        )}
        <Grid item xs={12} className={classes.fieldContainer}>
          <Grid container spacing={4} alignItems="flex-end">
            {/* Dispensing Pharmacy Dropdown */}
            {fieldSettings[nameOfFormFields('dispensing_pharmacy')] &&
              dispensingPharmacyOptions.length > 0 && (
                <Grid item xs={5}>
                  <Field
                    name={nameOfFormFields('dispensing_pharmacy')}
                    label="Dispensing Pharmacy *"
                    validate={requiredWhenComplete}
                    isSearchable={false}
                    component={renderDropdown}
                    compareObject
                    fields={dispensingPharmacyOptions}
                    data-qa-id={getQaId(nameOfFormFields('dispensing_pharmacy'))}
                    disabled={isEditMode && !isFillCycleAfterCurrent}
                    missing_value_id={{ 'data-fc-mv-id': 'dispensing_pharmacy' }}
                  />
                </Grid>
              )}
            {showDispensingPharmacyWarning ? (
              <Grid container item xs={6} style={{ paddingLeft: '5px' }}>
                <Grid
                  item
                  xs={1}
                  style={{
                    paddingLeft: '5px',
                    display: 'flex',
                    paddingTop: '22px',
                  }}
                >
                  <IconWithTooltip
                    type="warning"
                    tooltip="This selection does not match the patient's preferred Delivery Pharmacy"
                  />
                </Grid>
                {renderDispPharmSyncCheckbox()}
              </Grid>
            ) : (
              <Grid container item xs={6} style={{ paddingLeft: '5px' }}>
                {renderDispPharmSyncCheckbox()}
              </Grid>
            )}
            {/* No Dispensing Pharmacy Msg */}
            {fieldSettings[nameOfFormFields('dispensing_pharmacy')] &&
              dispensingPharmacyOptions.length === 0 && (
                <Grid item xs={4}>
                  {/* @ts-ignore */}
                  <FieldLabel
                    fieldName="No dispensing pharmacy defined at therapy level"
                    labelSize="medium"
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
        {fieldSettings[nameOfFormFields('preferred_rx_delivery_method')] && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={5} className={classes.fieldContainer}>
                <Field
                  name={nameOfFormFields('preferred_rx_delivery_method')}
                  label="Method of Delivery *"
                  highlightSingleValue={
                    formValues.preferred_rx_delivery_method &&
                    formValues.preferred_rx_delivery_method.value < 0
                  }
                  validate={
                    props.selectedStatus === FCStatus.completed ? optionSelected : undefined
                  }
                  isSearchable={false}
                  component={ReactSelectForReduxSingle}
                  disabled={isEditMode && !isFillCycleAfterCurrent}
                  fields={methodOfDelivery.map(deliveryMethod => {
                    if (isLocalhost) {
                      return deliveryMethod;
                    }
                    const logo = getDeliveryMethodLogo(deliveryMethod.label);
                    if (logo) {
                      return {
                        label: renderDeliveryMethodSelectOption(deliveryMethod.label, logo),
                        labelText: deliveryMethod.label,
                        dataQaId: deliveryMethod.label,
                        value: deliveryMethod.value,
                        isDisabled: deliveryMethod.disabled,
                      };
                    }
                    return deliveryMethod;
                  })}
                  data-qa-id={getQaId(nameOfFormFields('preferred_rx_delivery_method'))}
                  missing_value_id={{ 'data-fc-mv-id': 'preferred_rx_delivery_method' }}
                />
                <Grid
                  item
                  xs={12}
                  style={{ fontSize: '14px', marginLeft: '-8px', marginTop: '2px' }}
                  className={classes.fieldContainer}
                >
                  {`Patient's Preferred Rx Delivery Method: ${patientPreferredDeliveryMethod}`}
                </Grid>
                {showPreferredDeliveryDates(props.deliveryMethodVal?.value) &&
                  showErrorForCourier && (
                    <div
                      className={cx(classes.shippingInputFooter, classes.shippingInputFooterError)}
                    >
                      <span
                        className={cx(
                          classes.shippingInputFooterOuterSpan,
                          classes.shippingInputFooterOuterSpanError,
                        )}
                      >
                        The selected zip code is outside the range for courier delivery, please use
                        UPS as method of delivery instead.
                      </span>
                    </div>
                  )}
                <DeliveryIntegrationWarning
                  classes={[
                    cx(classes.shippingInputFooter, classes.shippingInputFooterError),
                    cx(
                      classes.shippingInputFooterOuterSpan,
                      classes.shippingInputFooterOuterSpanError,
                    ),
                  ]}
                  formValues={formValues}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('ups_packaging_type')] &&
          props.deliveryMethodVal?.value === DeliveryMethodValue.Ups && (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={3} className={classes.fieldContainer}>
                  <Field
                    name={nameOfFormFields('ups_packaging_type')}
                    label="UPS Package Type *"
                    validate={
                      props.deliveryMethodVal?.value === DeliveryMethodValue.Ups
                        ? requiredWhenComplete
                        : []
                    }
                    isSearchable={false}
                    component={ReactSelectForReduxSingle}
                    fields={(upsPackagingTypeOptions || []).map(method => ({
                      value: method.id,
                      label: method.packaging_type,
                    }))}
                    value={
                      (formValues.ups_packaging_type = {
                        value: props.upsPackagingTypeSelected?.value,
                        label: props.upsPackagingTypeSelected?.label,
                      })
                    }
                    data-qa-id={getQaId(nameOfFormFields('ups_packaging_type'))}
                    id="ups_packaging_type"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {fieldSettings[nameOfFormFields('preferred_phone_number')] && (
          <Grid item xs={6} className={classes.fieldContainer}>
            <Field
              id="phone"
              name={nameOfFormFields('preferred_phone_number')}
              label={`Preferred Phone # ${!isPickUp ? '*' : ''}`}
              validate={!isPickUp ? [required] : []}
              component={ReactSelectForReduxSingle}
              fields={fullPhoneList
                .reduce(
                  (arr, p: any) => [
                    ...arr,
                    ...(p.value !== null ? [{ value: p.label, label: p.label }] : []),
                  ],
                  [],
                )
                .concat({
                  value: -1,
                  label: '+ Add New Phone',
                  highlight: true,
                  onSelect: () => {
                    dispatch(
                      addPhoneModalActions.setState({
                        visible: true,
                        formId: props.form,
                        field: nameOfFormFields('preferred_phone_number'),
                        mode: 'therapies',
                      }),
                    );
                  },
                } as any)}
              data-qa-id={getQaId(nameOfFormFields('preferred_phone_number'))}
              missing_value_id={{ 'data-fc-mv-id': 'preferred_phone_number' }}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('email')] && (
          <Grid item xs={6} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('email')}
              label="Patient Email"
              component={ReactSelectForReduxSingle}
              fields={(getFullEmailList(props.patient, props.contactList) as any)
                .map((p: any) => ({
                  value: p.label,
                  label: p.label,
                }))
                .concat({
                  value: -1,
                  label: '+ Add New Email',
                  highlight: true,
                  onSelect: () => {
                    dispatch(
                      addEmailModalActions.setState({
                        visible: true,
                        formId: props.form,
                        field: nameOfFormFields('email'),
                        mode: 'therapies',
                      }),
                    );
                  },
                })}
              data-qa-id={getQaId(nameOfFormFields('email'))}
              disabled={props.componentMode === EditComponentMode.Edit}
            />
          </Grid>
        )}

        {fieldSettings[nameOfFormFields('address')] && (
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={9} className={classes.fieldContainer}>
              <Field
                name={nameOfFormFields('address')}
                label={
                  <>
                    {fieldSettings.fieldLabels[nameOfFormFields('address')] ||
                      'Preferred Shipping Address'}
                    {isAddressRequired(props.deliveryMethodVal?.value, props?.selectedStatus)
                      ? ' * '
                      : ' '}
                    {availableAddressLength > 1 && (
                      <span className={classes.redSpan}>
                        (from {availableAddressLength} available)
                      </span>
                    )}
                  </>
                }
                component={ReactSelectForReduxSingle}
                blurInputOnSelect
                fields={getFullAddressList(
                  props.patient,
                  props.contactList,
                  props.deliveryMethodVal?.value,
                )
                  .map((address: any, index: number) => ({
                    isPreferred: index === 0,
                    value: address.rawAddress,
                    label: address.label,
                    source: address.source,
                    ...(address.contactId ? { contact_id: address.contactId } : {}),
                  }))
                  .concat({
                    value: -1,
                    label: '+ Add New Address',
                    highlight: true,
                    onSelect: () => {
                      dispatch(
                        addAddressModalActions.setState({
                          visible: true,
                          formId: props.form,
                          field: nameOfFormFields('address'),
                          mode: 'therapies',
                        }),
                      );
                    },
                  } as any)}
                data-qa-id={getQaId(nameOfFormFields('address'))}
                immediateValidation
                highlightSingleValue={
                  formValues?.address?.value && Number(formValues.address.value) < 0
                }
                validate={
                  isAddressRequired(props.deliveryMethodVal?.value, props.selectedStatus)
                    ? [missingAddressValidation]
                    : [valid]
                }
                missing_value_id={{ 'data-fc-mv-id': 'address' }}
              />
            </Grid>
            {windowFeatureIsEnabled('address_validation') ? (
              <Grid item xs={1}>
                <AddressVaildationIcon
                  formValues={formValues}
                  nameOfFormFields={nameOfFormFields}
                />
              </Grid>
            ) : null}
            {formValues?.address?.value && windowFeatureIsEnabled('address_validation') ? (
              <Grid item xs={2}>
                <Button
                  disabled={
                    !formValues?.address?.value || typeof formValues?.address?.value === 'string'
                  }
                  style={{ backgroundColor: '#547096' }}
                  data-qa-id="validate_address_in_fc_form_button"
                  onClick={beginDeliveryAddressValidation}
                  variant="contained"
                >
                  Validate Address
                </Button>
              </Grid>
            ) : null}
          </Grid>
        )}
        {showProviderAddressWarning ? (
          <Grid
            item
            xs={12}
            style={{
              fontSize: '14px',
              marginTop: '-2px',
              color: 'red',
            }}
            className={classes.fieldContainer}
            data-qa-id="patient_rx_delivery_address_note_1"
          >
            <li>
              The order contains a Clinic-Administered medication but the address selected is not a
              Provider. Provide a reason in the field below.
            </li>
          </Grid>
        ) : null}
        {providerAddresses.length === 0 &&
        containsAClinicAdministeredTherapy &&
        formValues?.preferred_rx_delivery_method?.value &&
        formValues.preferred_rx_delivery_method.value !== DeliveryMethodValue.PickUp &&
        formValues.preferred_rx_delivery_method.value > 0 ? (
          <Grid
            item
            xs={12}
            style={{
              fontSize: '14px',
              marginTop: '-2px',
              color: 'red',
            }}
            className={classes.fieldContainer}
            data-qa-id="patient_rx_delivery_address_note_2"
          >
            <li>
              This order contains a Clinic-Administered medication and the patient does not have a
              Provider address in their profile. Please add a Provider address.
            </li>
          </Grid>
        ) : null}
        {providerAddresses.length > 1 &&
        containsAClinicAdministeredTherapy &&
        formValues?.preferred_rx_delivery_method?.value &&
        formValues.preferred_rx_delivery_method.value !== DeliveryMethodValue.PickUp &&
        formValues.preferred_rx_delivery_method.value > 0 ? (
          <Grid
            item
            xs={12}
            style={{
              fontSize: '14px',
              marginTop: '-2px',
              color: 'red',
            }}
            className={classes.fieldContainer}
            data-qa-id="patient_rx_delivery_address_note_3"
          >
            <li>
              This order contains a Clinic-Administered medication and the patient has multiple
              Provider addresses. Choose the correct address.
            </li>
          </Grid>
        ) : null}
        {containsCAandNonCATherapies &&
        formValues?.preferred_rx_delivery_method?.value &&
        formValues.preferred_rx_delivery_method.value !== DeliveryMethodValue.PickUp &&
        formValues.preferred_rx_delivery_method.value > 0 ? (
          <Grid
            item
            xs={12}
            style={{
              fontSize: '14px',
              marginTop: '-2px',
              color: 'red',
            }}
            className={classes.fieldContainer}
            data-qa-id="patient_rx_delivery_address_note_4"
          >
            <li>
              This order contains both Clinic-Administered and Non-Clinic-Administered medications
              and likely needs to be split into two separate orders. Please review.
            </li>
          </Grid>
        ) : null}
        {!containsAClinicAdministeredTherapy &&
        formValues?.preferred_rx_delivery_method?.value &&
        formValues.preferred_rx_delivery_method.value !== DeliveryMethodValue.PickUp &&
        formValues.preferred_rx_delivery_method.value > 0 ? (
          <Grid
            item
            xs={12}
            style={{
              fontSize: '14px',
              marginTop: '-2px',
              color: addressPreferenceMismatch ? 'red' : 'green',
            }}
            className={classes.fieldContainer}
            data-qa-id="patient_rx_delivery_address_note_5"
          >
            <li>
              {"Patient's Preferred Rx Delivery Address: "}{' '}
              {getPreferredRxDeliveryAddress(patient, contactList)}
            </li>
          </Grid>
        ) : null}
        {fieldSettings[nameOfFormFields('ship_date')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('ship_date')}
              label="Ship Date *"
              component={renderDatePicker}
              textFieldInputProps={{ autoComplete: 'off' }}
              renderFooter={() => {
                if (!showPreferredDeliveryDates(props.deliveryMethodVal?.value)) {
                  return null;
                }

                if (shipDateIsHoliday) {
                  return (
                    <div className={classes.holidayInputFooter}>
                      <span className={classes.holidayInputFooterOuterSpan}>This is a holiday</span>
                    </div>
                  );
                }

                const preferredDeliveryDate = preferredDeliveryDates?.[0];
                if (!preferredDeliveryDate) {
                  return null;
                }

                return (
                  <div className={classes.shippingInputFooter}>
                    <span className={classes.shippingInputFooterOuterSpan}>
                      Next Preferred Date
                      <span className={classes.shippingInputFooterInnerSpan}>
                        {` ${moment(preferredDeliveryDate).utc().format('MM/DD/YYYY')}`}
                      </span>
                    </span>
                  </div>
                );
              }}
              minDate={new Date()}
              highlightDates={[
                {
                  'react-datepicker__day--disabled-holiday': getCombinedDisabledDates(
                    props.deliveryMethodVal?.label,
                    holidays,
                  )
                    ? disabledDates.ship || []
                    : [],
                },
                {
                  'react-datepicker__day--highlighted': showPreferredDeliveryDates(
                    props.deliveryMethodVal?.value,
                  )
                    ? preferredDeliveryDates || []
                    : [],
                },
              ]}
              data-qa-id={getQaId(nameOfFormFields('ship_date'))}
              id="ship_dt"
              validate={[validateDate].concat(requiredWhenComplete)}
              missing_value_id={{ 'data-fc-mv-id': 'ship_date' }}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('delivery_dt')] &&
          formValues?.preferred_rx_delivery_method?.value &&
          formValues?.preferred_rx_delivery_method?.value > -1 && (
            <Grid
              item
              xs={3}
              className={classes.fieldContainer}
              data-qa-id={getQaId(nameOfFormFields('delivery_dt'))}
            >
              <Field
                name={nameOfFormFields('delivery_dt')}
                label={
                  fieldSettings.deliveryMethod.isPickup
                    ? 'Expected Pick Up Date *'
                    : 'Expected Delivery Date *'
                }
                minDate={new Date()}
                highlightDates={[
                  {
                    'react-datepicker__day--disabled-holiday': getCombinedDisabledDates(
                      props.deliveryMethodVal?.label,
                      holidays,
                    )
                      ? disabledDates.delivery || []
                      : [],
                  },
                  {
                    'react-datepicker__day--highlighted': showPreferredDeliveryDates(
                      props.deliveryMethodVal?.value,
                    )
                      ? preferredDeliveryDates || []
                      : [],
                  },
                ]}
                component={renderDatePicker}
                textFieldInputProps={{ autoComplete: 'off' }}
                missing_value_id={{ 'data-fc-mv-id': 'delivery_dt' }}
                validate={[
                  validateDate,
                  [
                    DeliveryMethodValue.Courier,
                    DeliveryMethodValue.PickUp,
                    DeliveryMethodValue.InternalDelivery,
                  ].includes(props.deliveryMethodVal?.value)
                    ? validateDeliveryOnOrAfterShipDate
                    : validateShipBeforeDeliveryDate,
                ].concat(requiredWhenComplete)}
                id="delivery_dt"
              />
            </Grid>
          )}
        {showProviderAddressWarning ? (
          <Grid item xs={9} className={classes.fieldContainer}>
            <Field
              name="non_provider_address_reason"
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>
                    Reason for not sending to a Provider Office *
                  </span>{' '}
                  {!userCanOverrideCADelivery ? (
                    <Tooltip title="You need proper permission to enter an override reason">
                      <ErrorIcon />
                    </Tooltip>
                  ) : null}
                </div>
              }
              component={renderDebouncedTextField}
              data-qa-id="non_provider_address__reason"
              data-fc-mv-id="non_provider_address_reason"
              disabled={!userCanOverrideCADelivery}
              validate={showProviderAddressWarning ? requiredWhenComplete : undefined}
              maxLength={50}
            />
          </Grid>
        ) : null}
        {fieldSettings.order_type_address_pickup && (
          <Grid item xs={6} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('order_type_address')}
              label="Pick-up Location *"
              component={renderDebouncedTextField}
              validate={requiredWhenComplete}
              data-qa-id={getQaId(nameOfFormFields('order_type_address'))}
              data-fc-mv-id="order_type_address"
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('patient_advised_pickup')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('patient_advised_pickup')}
              label="Patient advised of pickup location?"
              component={renderCheckboxInline}
              data-qa-id={getQaId(nameOfFormFields('patient_advised_pickup'))}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('special_instructions')] && (
          <Grid
            item
            xs={12}
            className={classes.fieldContainer}
            data-fc-mv-id="special_instructions"
          >
            <Field
              name={nameOfFormFields('special_instructions')}
              component={NoteArea}
              defaultPlaceholder="Rx Delivery Instructions"
              data-qa-id={getQaId(nameOfFormFields('special_instructions'))}
              maxLength={ORDER_NOTES_LIMIT}
              debounceOverride={50}
              inputProps={{
                onFocus: () => setInNoteArea(true),
                onBlur: () => setInNoteArea(false),
              }}
            />
          </Grid>
        )}
        <NoteFieldCharacterLimitMessage
          note={formValues.special_instructions}
          active={
            !!(
              inNoteArea ||
              (formValues.special_instructions &&
                formValues.special_instructions?.length > ORDER_NOTES_LIMIT)
            )
          }
          type="fc"
        />
        {fieldSettings[nameOfFormFields('signature_required')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('signature_required')}
              label="Signature Required *"
              validate={requiredWhenComplete}
              component={renderDropdown}
              disabled={orderContainsAMedicarePartBTherapy}
              fields={staticProviders.yesNo01.map(item => ({
                value: item.id,
                label: item.name,
              }))}
              data-qa-id={getQaId(nameOfFormFields('signature_required'))}
              missing_value_id={{ 'data-fc-mv-id': 'signature_required' }}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('urgent')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('urgent')}
              label="Urgent"
              component={renderCheckbox}
              data-qa-id={getQaId(nameOfFormFields('urgent'))}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('to_patient')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('to_patient')}
              label="To Patient"
              component={renderCheckbox}
              data-qa-id={getQaId(nameOfFormFields('to_patient'))}
              disabled={
                props.componentMode === EditComponentMode.Edit &&
                props.deliveryMethodVal?.value !== DeliveryMethodValue.Courier
              }
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('to_md')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('to_md')}
              label="To MD Office"
              component={renderCheckbox}
              data-qa-id={getQaId(nameOfFormFields('to_md'))}
              disabled={
                props.componentMode === EditComponentMode.Edit &&
                props.deliveryMethodVal?.value !== DeliveryMethodValue.Courier
              }
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('patient_acknowledges')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('patient_acknowledges')}
              label="Patient acknowledges that package will be left without signature"
              component={renderCheckbox}
              data-qa-id={getQaId(nameOfFormFields('patient_acknowledges'))}
              disabled={props.componentMode === EditComponentMode.Edit}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('shipping_method')] && (
          <Grid item xs={3} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('shipping_method')}
              label="Shipping Method *"
              component={renderDropdown}
              fields={(deliveryShippingMethodMap[props.deliveryMethodVal.value] || []).map(
                method => {
                  return {
                    label: method.label,
                    value: method.value,
                  };
                },
              )}
              validate={requiredWhenComplete}
              data-qa-id={getQaId(nameOfFormFields('shipping_method'))}
              id="shipping_method"
              missing_value_id={{ 'data-fc-mv-id': 'shipping_method' }}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('canceled_status_id')] && (
          <Grid
            item
            lg={9}
            className={classes.fieldContainer}
            data-qa-id={getQaId(nameOfFormFields('canceled_status_id'))}
          >
            <Field
              id="canceled_status_id"
              name={nameOfFormFields('canceled_status_id')}
              label={`Reason for ${taskStatus?.status?.toLowerCase()} status? *`}
              component={renderDropdown}
              fields={getReasonsByStatus(props.statuses, taskStatus?.status)}
              data-qa-id={getQaId(nameOfFormFields('canceled_status_id'))}
              validate={[required]}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('additional_reason')] && (
          <Grid item lg={9} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('additional_reason')}
              label="Additional Reason"
              component={NoteArea}
              defaultPlaceholder="Add cancel reason"
              data-qa-id={getQaId(nameOfFormFields('additional_reason'))}
            />
          </Grid>
        )}
        {containsAClinicAdministeredTherapy ? (
          <Grid item lg={12} className={classes.fieldContainerYellowWithText}>
            <span className={classes.yellowCircleExclamationPoint}>!</span>
            This order contains a Clinic Administered medication and may require a signature for
            delivery.
          </Grid>
        ) : null}
        {orderContainsAMedicarePartBTherapy ? (
          <Grid item lg={12} className={classes.fieldContainerYellowWithText}>
            <span className={classes.yellowCircleExclamationPoint}>!</span>
            This order contains a therapy covered by Medicare Part B. A Delivery signature is
            required.
          </Grid>
        ) : null}
        {containsAControlledSubstance ? (
          <Grid item lg={12} className={classes.fieldContainerYellowWithText}>
            <span className={classes.yellowCircleExclamationPoint}>!</span>
            This order contains a controlled substance and may require a signature for delivery.
          </Grid>
        ) : null}
        {fieldSettings[nameOfFormFields('cold_chain_packing_required')] && (
          <Grid
            item
            xs={10}
            className={classes.fieldContainer}
            data-qa-id={getQaId(nameOfFormFields('cold_chain_packing_required'))}
          >
            <Field
              name={nameOfFormFields('cold_chain_packing_required')}
              label="Cold Chain Packing Required"
              component={renderCheckbox}
              disabled={props.componentMode === EditComponentMode.Edit || isColdChainDisabled}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('location')] && (
          <Grid item lg={2} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('location')}
              component={renderLocation}
              data-qa-id={getQaId(nameOfFormFields('location'))}
            />
          </Grid>
        )}
        {fieldSettings[nameOfFormFields('weather')] && (
          <Grid item lg={12} className={classes.fieldContainer}>
            <Field
              name={nameOfFormFields('weather')}
              label="Weather"
              component={renderWeather}
              data-qa-id={getQaId(nameOfFormFields('weather'))}
            />
          </Grid>
        )}
        {props.selectedStatus === FillCoordinationStatus.In_Progress && (
          <Grid item xs={12}>
            <FieldTitle title="In Progress Details" />
          </Grid>
        )}
        {props.selectedStatus === FillCoordinationStatus.In_Progress &&
          fieldSettings[nameOfFormFields('followup_dt')] && (
            <Grid item xs={3} className={classes.fieldContainer}>
              <Field
                name={nameOfFormFields('followup_dt')}
                label="Follow up Date *"
                component={renderDatePicker}
                validate={[validateDate, required]}
                data-qa-id={getQaId(nameOfFormFields('followup_dt'))}
                missing_value_id={{ 'data-fc-mv-id': 'followup_dt' }}
              />
            </Grid>
          )}
        {fieldSettings[nameOfFormFields('in_progress_reason')] &&
          fcInProgressReasons.length > 0 && (
            <Grid item xs={9} className={classes.fieldContainer}>
              <Field
                name={nameOfFormFields('in_progress_reason')}
                label="In Progress Reason *"
                component={renderDropdown}
                fields={fcInProgressReasons}
                validate={[required]}
                data-qa-id={getQaId(nameOfFormFields('in_progress_reason'))}
                missing_value_id={{ 'data-fc-mv-id': 'in_progress_reason' }}
              />
            </Grid>
          )}
      </>
    );
  };

  const renderSecureFCForm = () => {
    const statusId = props.tasks[0].status_id;
    const secureLinkNotYetSent = statusId === 4001 || statusId === 4007 || statusId === 4012;
    const secureLinkHasBeenSent = statusId === 4009;
    let tooltipText = '';
    let warningText = '';
    if (!patientIsSMSOptIn) {
      tooltipText = 'Patient must opt-in for SMS messages. ';
    }
    if (includesOnlyFirstTimeFCs) {
      tooltipText += 'FC Selection cannot contain only first-time fills.';
    }
    if (secureLinkSendHistory?.has_limit_violation) {
      warningText =
        'Secure Link unavailable - One or more FCs in this bundle has already had 3 unanswered secure links in the 14 day period of its fill cycle. Please call the patient to complete this bundle. ';

      const over_limit_therapies: any[] = [];
      Object.keys(secureLinkSendHistory.fc_history).forEach(id => {
        if (secureLinkSendHistory.fc_history[id].violation) {
          const therapy = therapiesById[fcsById[id]?.therapy_id];
          if (therapy?.drug_name) {
            over_limit_therapies.push(therapy?.drug_name);
          }
        }
      });
      warningText += `Specific therapies: ${over_limit_therapies.join(', ')}`;
    }

    if (secureLinkNotYetSent) {
      const dispPharm = preferredRxDeliveryPharmacy || mostPrevelantDispensingPharmacyInBundle;
      const dispPharmFull = customerPharmacies.find(pharm => pharm.npi === dispPharm.npi);
      let deliveryConfigNote = 'This pharmacy is configured for courier and UPS delivery';
      if (!dispPharmFull?.is_courier_integrated && !dispPharmFull?.is_ups_integrated) {
        deliveryConfigNote = 'This pharmacy is not configured for UPS or courier delivery';
      } else if (!dispPharmFull?.is_courier_integrated) {
        deliveryConfigNote = 'This pharmacy is not configured for courier delivery';
      } else if (!dispPharmFull?.is_ups_integrated) {
        deliveryConfigNote = 'This pharmacy is not configured for UPS delivery.';
      }
      const needsByDateofTomorrow: any[] = [];
      props.allTasks.forEach(fc => {
        const a = moment(fc.due_date).format('YYYY-MM-DD');
        const b = moment().add(1, 'days').format('YYYY-MM-DD');
        if ((fc.due_date || '').split('T')[0] === moment().add(1, 'days').format('YYYY-MM-DD')) {
          const therapy = therapiesById[fc.therapy_id];
          if (therapy?.drug_name) {
            needsByDateofTomorrow.push(therapy.drug_name);
          }
        }
      });

      return (
        <>
          {containsAClinicAdministeredTherapy ? (
            <Grid item xs={12} className={classes.fieldContainerWithRedTextThin}>
              <div>
                <span className={classes.redCircleExclamationPoint}>!</span>
                This order contains a Clinic Administered medication. Patient will not be able to
                choose pickup or delivery, dates, or signature required status in the secure link
                questionnaire.
              </div>
            </Grid>
          ) : null}
          <Grid container item xs={12} className={classes.secureLinkContainerThin}>
            Dispensing Pharmacy for this bundle: {dispPharm.name}
          </Grid>
          <Grid container item xs={12} className={classes.secureLinkContainerThin}>
            {deliveryConfigNote}
          </Grid>
          <Grid container item xs={12} className={classes.secureLinkContainer}>
            {secureLinkSendHistory?.has_limit_violation ? (
              <Grid
                item
                xs={12}
                className={classes.fieldContainerWithRedTextThin}
                style={{ height: 'auto', paddingTop: '5px', paddingBottom: '5px' }}
              >
                <div>
                  <span className={classes.redCircleExclamationPoint}>!</span>
                  {warningText}
                </div>
              </Grid>
            ) : null}
            {needsByDateofTomorrow.length > 0 ? (
              <Grid item xs={12} className={classes.fieldContainerWithRedTextThin}>
                <div>
                  <span className={classes.redCircleExclamationPoint}>!</span>
                  One or more therapies in this bundle has a needs-by-date of tomorrow. Please call
                  the patient to complete this bundle. Specific Therapies:{' '}
                  {needsByDateofTomorrow.join(', ')}
                </div>
              </Grid>
            ) : null}
            <Grid item xs={7}>
              <div>Send a SMS to the patient with a secure link to complete the form.</div>
              <div>This link will be valid until 11:59 pm tomorrow night.</div>
            </Grid>
            <Grid item xs={3} className={classes.secureLinkButton}>
              {!isSecureLinkOptionEnabled ? (
                <IconWithTooltip
                  inlineStyle={{ fontSize: '26px' }}
                  tooltip={tooltipText}
                  type="warning"
                />
              ) : null}
              <Button
                data-qa-id="send-secure-link"
                onClick={handleSendSecureLink}
                variant="contained"
                disabled={
                  !isSecureLinkOptionEnabled ||
                  fcssFormIsSubmitting ||
                  secureLinkSendHistory?.has_limit_violation ||
                  needsByDateofTomorrow.length > 0
                }
              >
                {sendLinkButtonText}
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    if (secureLinkHasBeenSent) {
      const link_expiration_dt = new ArborDate(
        props.tasks[0].secure_link_expiration_dt,
        true,
      ).getCustomerDatetime(true);
      return (
        <>
          {renderDeactivateLinkConfirmation()}
          {missingFCTaskIds.length ? (
            <Grid item xs={12} className={classes.fieldContainerWithRedText}>
              <div>
                <span className={classes.redCircleExclamationPoint}>!</span>
                {missingFCTaskIds.length == 1
                  ? 'There is 1 missing FC that is included in the same secure link bundle.'
                  : `There are ${missingFCTaskIds.length} missing FCs that are included in the same secure link bundle.`}
              </div>
              <Button
                style={{ color: '#44484C', backgroundColor: 'white', border: '1px solid #C4C4C4' }}
                onClick={addSelections}
              >
                Add Missing FCs
              </Button>
            </Grid>
          ) : null}
          <Grid container item xs={12} className={classes.secureLinkContainer}>
            <Grid item xs={4}>
              <div data-secure-link-id={props.tasks[0].secure_link_id}>
                {`Secure link was sent to the patient by ${secureFCLinkSender}.`}
              </div>
              <div>{`This link will be available until ${link_expiration_dt}.`}</div>
            </Grid>
            <Grid item container xs={3} style={{ justifyContent: 'space-between' }}>
              <Button
                data-qa-id="resend-secure-link"
                onClick={handleResendSecureLink}
                variant="contained"
                disabled={fcssFormIsSubmitting}
              >
                {resendLinkButtonText}
              </Button>
            </Grid>
            <Grid item xs={2} className={classes.secureLinkButton}>
              <Button
                data-qa-id="deactivate-secure-link"
                onClick={handleDeactivateSecureLink}
                disabled={fcssFormIsSubmitting}
                variant="contained"
              >
                {deactivateLinkButtonText}
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    const link_expiration_dt = new ArborDate(
      props.tasks[0].secure_link_expiration_dt,
      true,
    ).getCustomerDatetime(true);
    return (
      <Grid container item xs={12} className={classes.secureLinkContainer}>
        <Grid item xs={7}>
          <div>{`Secure link was sent to the patient by ${secureFCLinkSender}.`}</div>
          <div>{`Secure link was completed by patient ${link_expiration_dt}.`}</div>
        </Grid>
      </Grid>
    );
  };
  const methodButtonDisabled = (item: any) => {
    if (item.name !== 'Secure Link') {
      return (
        [4009, 4010, 4011].includes(startingStatus) ||
        (props.tasks[0].secure_link_id && props.tasks[0].secure_link_id > 0)
      );
    }
    if (startingStatus === 4008) {
      return true;
    }
    if (
      [4009, 4010, 4011, 4012].includes(startingStatus) ||
      (startingStatus === 4007 && !props.tasks[0].secure_link_id)
    ) {
      return true;
    }
    return !isSecureLinkOptionEnabled;
  };

  return (
    <>
      {renderMessageDialog()}
      {props.siblingTasks?.length === 0 && props.onSelectTaskIds && numberOfGroupedFCs > 0 && (
        <Grid
          container
          border={1}
          alignItems="center"
          justifyContent="space-between"
          className={classes.workAsGroupContainer}
        >
          <Grid item xs={8}>
            <Typography className={classes.workAsGroupText}>
              {getWorkedAsGroupText()}
              <Tooltip placement="right-start" title={<>{getGroupedTherapyNames().join('\n')}</>}>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              data-qa-id="work-as-group"
              onClick={() =>
                props.onSelectTaskIds(
                  props.groupedFCIds.map(groupedFCId => ({
                    newSelectedTaskId: groupedFCId,
                    newSelectedTaskType: taskAbbreviationMap.task_fill_coordination,
                  })),
                )
              }
              variant="contained"
            >
              Work as Group
            </Button>
          </Grid>
        </Grid>
      )}
      {fieldSettings[nameOfFormFields('method')] && (
        <Grid item xs={6} className={classes.fieldContainer}>
          <Field
            name={nameOfFormFields('method')}
            label="Method *"
            radioMap={methodOptionsEnabled.map((item: any) => ({
              value: item.id,
              label: item.name,
              disabled: methodButtonDisabled(item),
            }))}
            methodButtonCallback={handleCollectionMethodChange}
            indexOverride={controlledMethodIndex}
            component={renderReactRadioGroupFC}
            validate={requiredWhenComplete}
            qaId={getQaId(nameOfFormFields('method'))}
            disabled={props.componentMode === EditComponentMode.Edit}
            missing_value_id="method"
          />
        </Grid>
      )}
      {render_normal_fc_form ? renderNormalFCForm() : renderSecureFCForm()}
    </>
  );
  // #endregion render
};

export default withStyles(styles)(FillCoordinationEditForm);
export const FillCoordinationEditReduxForm = compose<Props, any>(
  withStyles(styles),
  reduxForm({}),
)(FillCoordinationEditForm);
