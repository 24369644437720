import React, { useState, useEffect, useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Modal,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { accordianStyles } from 'components/accordian/accordian-styles';
import moment from 'moment-timezone';
import DetailField from 'components/form/field/field';
import FetchText from 'components/form/field/fetch-text';
import AccordionHeader from 'components/form/header/accordion-header';
import { getUserById } from 'services/utils/users-service';
import {
  CAIcon,
  DSIcon,
  PerProtocolIcon,
  EditPencilMd,
  EditAdd,
  PRNIcon,
  getCMIcon,
  PatientOverviewCopy,
} from 'components/icons/icons';
import ActionRow from 'components/note/action-row';
import { LargeSpacer } from 'components/spacer/spacer';
import History from 'containers/common/history';
import { get } from 'lodash';
import { connect, useSelector, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { getDisplayNotes } from 'services/utils/note-service';
import { getDocumentsForResource } from 'services/utils/document-service';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import {
  getInProgressStatus,
  getStatusCategoryIdByStatusId,
  getCombinedTasks,
} from 'services/utils/task-service';
import {
  getTherapyStatusById,
  fetchGpis,
  isTherapyArchived,
  getInitialPayerLockout,
  formatTherapyNameLong,
  fetchPharmacies,
} from 'services/utils/therapy-service';
import {
  noteTagTypes,
  therapyClinicalDispensingReasons,
  taskListOrder,
  auditContext,
  therapyAudits,
} from 'constants/lists';
import {
  MR,
  DC,
  PA,
  FA,
  CSL,
  FC,
  FDC,
  INT,
  OR,
  TPR,
  QRE,
  AUDIT_NOT_RESOLVED,
  PATIENT_INACTIVE,
  CDM,
  RS,
} from 'constants/index';
import {
  isDispensingUndecided,
  isClinicalSupportUndecided,
  TherapyWorkflowLabel,
  isDispensingOptInOrOptOut,
  SpecialtyTherapiesWf,
} from 'constants/enums';
import Audit from 'components/display/audit';
import NoteDisplay from 'components/note/note-display';
import DocumentDisplay from 'components/document/document-display';
import TaskLinks from 'containers/tasks/task-links';
import {
  shouldRenderDcInIntervention,
  getIdOfDcOrRsInterventionId,
} from 'components/dynamic-form/helpers';
import HTTP from 'services/http';
import { convertToArborDate } from 'models/time/arbor-date';
import { convertMapToList } from 'reducers/helper-reducer';
import { useFillCycleNumber } from 'hooks/useFillCycleNumber';
import { goToAnchor } from 'utils/react-scrollable-anchor';
import { groupBy } from 'helpers/misc';
import { buildQaId } from 'utils/build-qa-id';
import { windowFeatureIsEnabled } from 'config/window-features';
import { notifyError } from 'actions/action-notifications';
import { updateNoteBar, fetchNotesSidebar } from '../../../actions/action-notes';
import TherapyTaskList from '../../tasks/therapy-task-list';
import EditPatientTherapy from './edit-patient-therapy/edit-patient-therapy';
import { styles } from './therapy-detail.styles';
import EditEnrollment from './edit-therapy-enrollment';
import EditAdministration from './edit-therapy-administration';
import TherapyStatus from './therapy-status';
import TherapyEnrollmentStatus from './therapy-enrollment-status';
import TherapyAdministration from './therapy-administration';
import TherapyEnrollment from './therapy-enrollment';

// eslint-disable-next-line max-len
import TherapyBenefitsInvestigation from './therapy-benefits-investigation/therapy-benefits-investigation';
import TherapyFillCycle from './therapy-fill-cycle';

import { PatientTherapiesClient } from '../../../clients/patient-therapies';
import { logger } from '../../../winston-logger';
import { MedSyncTooltipIcon } from '../med-sync/components/medSyncIcon';
import { ClinicalDataClient } from '../../../clients/clinical-data';
import { fetchClinicalData } from '../../../actions/action-clinical-data';
import { useMedSyncIconState } from '../med-sync/med-sync-state';
import createLazyOnscreenRender from '../tasks-new/create-lazy-onscreen-render';
import CopyTherapy from './copy-therapy';
import { AdministrationStatus } from '../../../interfaces/redux/ITherapy';

const MedSyncIcon = () => <MedSyncTooltipIcon {...useMedSyncIconState()} size="lg" />;

function stopPropagation(e) {
  e.stopPropagation(); // prevent therapy summary panel expanding
}

const qaIdBuilder = buildQaId('dosing-regimen', '.');

function hasOutstandingTask(taskArray, links, taskStatuses, allTasks) {
  if (!taskArray || taskArray.length === 0 || !taskStatuses || taskStatuses.length === 0) {
    return false;
  }
  let tasks = taskArray;

  if (typeof taskArray === 'object') {
    tasks = Object.keys(tasks).map(id => tasks[id]);
  }
  const outstandingTaskStatus = tasks
    .map(t => {
      if (t.taskType === INT && shouldRenderDcInIntervention(t, links, allTasks)) {
        const dcId = getIdOfDcOrRsInterventionId(t, links);
        return {
          id: dcId,
          status: getStatusCategoryIdByStatusId(t.status_id, taskStatuses),
          type: DC,
        };
      }
      return {
        id: t.id,
        status: getStatusCategoryIdByStatusId(t.status_id, taskStatuses),
        type: t.taskType,
      };
    })
    .filter(t => getInProgressStatus().includes(t.status));
  return outstandingTaskStatus.length !== 0
    ? {
        id: outstandingTaskStatus[0].id,
        type: outstandingTaskStatus[0].type,
      }
    : false;
}

const TherapyDetail = props => {
  const {
    expanded,
    classes,
    patient,
    therapy,
    taskStatuses,
    selectedTask,
    selectedTaskType,
    users,
    serviceGroups,
    isTaskSidebar,
    selectedServiceGroup,
    taskStatusCategoriesToDisplay,
    taskCounts,
    therapyStatuses,
    addTherapyHandler,
    enrollmentStatuses,
    financials,
    index,
    allPinnedNotes,
    allNonPinnedNotes,
    medication,
    documents,
    pbmInsurances,
    latestBenefitInvestigation,
    links,
    audit,
    medicationCategoryCodes,
  } = props;

  const {
    administration_status_id: administrationStatusId,
    enrollment_status_id: enrollmentStatusId,
    clinical_support_status_id: clinicalSupportStatusId,
    clinical_support_additional_reason: clinicalSupportAdditionalReason,
    dispensing_status_id: dispensingStatusId,
    external_dispensing_reason: dispensingStatusReason,
    diagnosis_code: diagnosisCode,
    diagnosis_description: diagnosisName,
    secondary_diagnosis_code: secondaryDiagnosisCode,
    secondary_diagnosis_description: diagnosisSecondaryName,
    tertiary_diagnosis_code: tertiaryDiagnosisCode,
    tertiary_diagnosis_description: diagnosisTertiaryName,
    referral_dt: referralDt,
    fill_cycle: fillCycle,
    days_supply: daysSupply,
    therapy_duration: therapyDuration,
    drug_name: drugName,
    service_groups_display_name: serviceGroupsDisplayName,
    dispensing_pharmacy_name: dispensingPharmacyName,
    dispensing_pharmacy_doing_business_as: dispensingPharmacyDoingBusinessAs,
    transfer_pharmacy_name: transferPharmacyName,
    transfer_pharmacy_doing_business_as: transferPharmacyDoingBusinessAs,
    managing_clinic_name: managingClinicName,
    referring_clinic_name: referringClinicName,
    start_date: startDate,
    discontinued_date: discontinuedDate,
    dispensing_status: dispensingStatus,
    clinical_support_status: clinicalSupportStatus,
    medical_insurance: medicalInsurance,
    pharmacy_insurance: pbmInsurance,
    recheck_date: recheckDate,
    restart_date: restartDate,
    no_go_date: noGoDate,
    dosis_regimen: dosisRegimen,
  } = therapy;
  const clinicalSupportStatusReason = therapy.clinical_support_status_reason?.toString() ?? '';
  const tasks = {
    [PA]: therapy.prior_authorization,
    [FA]: therapy.financial_assistance,
    [TPR]: therapy.thirdPartyReferralList,
    [FC]: therapy.fill_coordination,
    [FDC]: therapy.fillDeliveryConfirmationList,
    [DC]: therapy.dataCollectList,
    [INT]: therapy.intervention_list,
    [CSL]: therapy.counseling_list,
    [MR]: therapy.medicationReviewList,
    [OR]: therapy.outreach_list,
    [QRE]: therapy.incidentList,
  };

  const [displayDetail, setDisplayDetail] = useState(expanded);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [editHistoryModalOpen, setEditHistoryModalOpen] = useState(false);
  const [editEnrollService, setEditEnrollService] = useState(false);
  const [editAdministration, setEditAdministration] = useState(false);
  const [initialEditTherapy, setInitialEditTherapy] = useState('Loading Therapy...');
  const [displayAddForm, setDisplayAddForm] = useState(false);
  const [archivedExpanded, setArchivedExpanded] = useState(false);
  const [prescriberName, setPrescriberName] = useState('-');
  const [therapyLabel, setTherapyLabel] = useState('');
  const [copyTherapy, setCopyTherapy] = useState(false);
  const showIcdIndicator = useMemo(() => {
    return therapy.specialty_type.includes(CDM) && therapyLabel !== RS;
  }, [therapy.specialty_type, therapyLabel]);
  const dispatch = useDispatch();

  const therapyDispensingPharmacyReasons = useSelector(
    state => state.lookups.therapyDispensingPharmacyReasons,
  );

  const pharmacies = useSelector(state => state?.pharmacies?.pharmacies);
  const dispensingPharmacyTherapy =
    pharmacies && pharmacies.length > 0
      ? pharmacies.find(p => p.npi === therapy.dispensing_pharmacy_npi)
      : null;
  const groupedPbmInsurances = groupBy(
    convertMapToList(pbmInsurances).filter(ins => ins.valid),
    'insurance_type',
  );

  const fetchPhysician = npi => {
    setPrescriberName(
      npi ? (
        <FetchText
          promise={() => HTTP.get(`providers/physician/${npi}`, {})}
          // eslint-disable-next-line no-confusing-arrow
          parse={result => {
            const credential = result.data.credential ? `, ${result.data.credential}` : '';
            return result && result.data && typeof result.data.full_name === 'string'
              ? `${result.data.full_name.toLowerCase()}${credential} - NPI (${npi})`
              : '-';
          }}
        />
      ) : (
        '-'
      ),
    );
  };

  useEffect(() => {
    const {
      prescribing_physician_credential,
      prescribing_physician_name,
      prescribing_physician_npi,
    } = therapy;
    const credential = prescribing_physician_credential
      ? `, ${prescribing_physician_credential}`
      : '';
    const prescriber = prescribing_physician_name
      ? `${prescribing_physician_name.toLowerCase()}${credential} - NPI (${prescribing_physician_npi})`
      : '-';
    setPrescriberName(prescriber);
  }, [therapy.prescribing_physician_npi]);

  useEffect(() => {
    setDisplayDetail(expanded);
  }, [expanded]);

  useEffect(() => {
    const getTherapyLabel = async () => {
      if (!therapy.diagnosis_code) {
        return;
      }

      setTherapyLabel('Loading...');
      try {
        const therapyTypeToUse = therapy.specialty_type ?? null;
        const res = await PatientTherapiesClient.getTherapyWorkflow(
          therapy.diagnosis_code,
          therapyTypeToUse,
        );
        const workflowLabel = TherapyWorkflowLabel[res.data?.therapyWorkflow] ?? '';
        setTherapyLabel(workflowLabel);
      } catch (err) {
        logger.error(err);
        dispatch(notifyError('Error when fetching therapy label'));
        setTherapyLabel('');
      }
    };
    getTherapyLabel();
  }, [therapy.diagnosis_code, therapy.specialty_type]);

  const [needsbyDate, perProtocol] = useFillCycleNumber(therapy.id, fillCycle);

  const toggleEditEnrollService = () => {
    setEditEnrollService(!editEnrollService);
  };

  const toggleEditAdministration = () => {
    setEditAdministration(!editAdministration);
  };

  const handleClick = e => {
    if (e && !editHistoryModalOpen) {
      setDisplayDetail(!displayDetail);
    }
  };

  const handleEdit = e => {
    if (e) {
      e.stopPropagation();
    }
    fetchGpis(therapy.ndc, true, true)
      .then(result => {
        setInitialEditTherapy(get(result.data, 'drugs[0].drug_info', null));
      })
      .then(() => setDisplayEdit(!displayEdit));
  };

  const handleCancel = e => {
    if (e) {
      e.stopPropagation();
    }
    setDisplayEdit(false);
  };

  const handleEditHistoryModal = e => {
    e.stopPropagation();
    setEditHistoryModalOpen(!editHistoryModalOpen);
  };

  const handleAddClick = () => {
    setDisplayAddForm(true);
  };

  const handleCancelClick = () => {
    setDisplayAddForm(false);
  };

  const handleArchivedClick = () => {
    setArchivedExpanded(!archivedExpanded);
  };

  const isAncillaryMedTherapyOptInOutDispense = () => {
    return therapy.is_specialty == 0 && isDispensingOptInOrOptOut(dispensingStatusId);
  };

  const { therapyAdministrationStatuses, therapyEnrollmentStatuses } = therapyStatuses;

  if (!therapyAdministrationStatuses) {
    return false;
  }

  let adminStatusDisplay;
  const adminStatus = getTherapyStatusById(therapyAdministrationStatuses, administrationStatusId);
  if (adminStatus) {
    adminStatusDisplay = adminStatus.status;
    if (adminStatus.reason) {
      adminStatusDisplay += ` - ${adminStatus.reason}`;
    }
  }

  const therapyStatusCategories = {
    'Pre-Therapy': 1,
    'On Therapy': 2,
    'On Hold': 3,
    'Discontinued': 4,
    'No-Go': 5,
  };

  const therapyEnrollmentStatusCategories = {
    Outstanding: 3,
    Partial: 6,
    Complete: 1,
  };

  let therapyEnrollmentStatusCategory;
  if (isAncillaryMedTherapyOptInOutDispense()) {
    therapyEnrollmentStatusCategory = 'Complete';
  } else if (
    isDispensingUndecided(dispensingStatusId) &&
    isClinicalSupportUndecided(clinicalSupportStatusId)
  ) {
    therapyEnrollmentStatusCategory = 'Not Started';
  } else if (
    isDispensingUndecided(dispensingStatusId) ||
    isClinicalSupportUndecided(clinicalSupportStatusId)
  ) {
    therapyEnrollmentStatusCategory = 'Partial';
  } else {
    therapyEnrollmentStatusCategory = 'Complete';
  }

  let enrollmentStatusDisplay;
  const enrollmentStatus = getTherapyStatusById(therapyEnrollmentStatuses, enrollmentStatusId);
  if (enrollmentStatus) {
    enrollmentStatusDisplay = `${enrollmentStatus.status}`;
  }
  const taskInactive = patient.status_id === PATIENT_INACTIVE;

  const getHistoryURL = (patientId, therapyId) => `/patients/${patientId}/therapies/${therapyId}`;

  const { value: tagTypeId, displayLabel: tagTypeLabel } = noteTagTypes.find(
    tag => tag.label === 'Therapy',
  );

  const displayNotes = getDisplayNotes(
    allPinnedNotes,
    allNonPinnedNotes,
    tagTypeId,
    therapy.id,
    users,
    serviceGroups,
    2,
  );

  const taskIdsResourceIds = () => {
    const results = [];
    const flatTasks = Object.values(tasks).flat(1);
    flatTasks.forEach(ft => {
      const tagValue = noteTagTypes.find(tag => tag.label === ft.taskType).value;
      results.push({ tagTypeId: tagValue, resourceId: ft.id });
    });
    return [...results, { tagTypeId, resourceId: therapy.id }];
  };

  const displayDocuments = getDocumentsForResource(documents, taskIdsResourceIds(), 1);

  const editMode = editAdministration || editEnrollService;

  const jumpToOutstandingTask = (taskType, id, e) => {
    if (taskType === DC) {
      if (patient.id) {
        ClinicalDataClient.fetch(patient.id).then(response =>
          dispatch(fetchClinicalData(response.data)),
        );
      }
    }
    e.stopPropagation();
    setDisplayDetail(true);
    setTimeout(() => goToAnchor(`${taskType}_${id}`));
  };

  // eslint-disable-next-line arrow-body-style
  const renderOutstandingTasks = () => {
    return (
      <Grid container>
        {taskListOrder.map(taskType => {
          if (tasks[taskType] && tasks[taskType].length) {
            const outstandingTask = hasOutstandingTask(tasks[taskType], links, taskStatuses, tasks);
            if (outstandingTask) {
              return (
                <Tooltip
                  title={taskCounts[taskType].task_description}
                  placement="top-start"
                  key={taskType}
                >
                  <Button
                    onFocus={event => event.stopPropagation()}
                    className={classes.taskIconSmall}
                    aria-label={`${therapy.id}_${taskType}`}
                    onClick={e => {
                      jumpToOutstandingTask(outstandingTask.type, outstandingTask.id, e);
                    }}
                  >
                    {taskType}
                  </Button>
                </Tooltip>
              );
            }
          }

          return null;
        })}
      </Grid>
    );
  };

  const getDispensingOptOutReasons = servicesRequested =>
    `${dispensingStatus}: ${servicesRequested
      .split(',')
      .map(optOutReason => {
        const reason = therapyDispensingPharmacyReasons.find(
          e => e.value === parseInt(optOutReason, 10),
        ).label;
        if (optOutReason === '3') {
          const medicalInsurancesList = getInitialPayerLockout(
            medicalInsurance,
            'medical',
            convertMapToList(financials.medical_insurances.data),
            true,
          );
          const pbmInsurancesList = getInitialPayerLockout(
            pbmInsurance,
            'pbm',
            convertMapToList(financials.pbm_insurances.data),
            true,
          );
          return `${reason} - ${
            medicalInsurancesList ? `Medical Insurances: ${medicalInsurancesList}` : ''
          } ${pbmInsurancesList ? `PBM Insurances: ${pbmInsurancesList}` : ''}`;
        }
        return reason;
      })
      .join(', ')}`;

  const getClinicalSupportOptOutReasons = servicesRequested =>
    `${clinicalSupportStatus}: ${servicesRequested
      .split(',')
      .map(optOutReason => {
        const optOutReasonInt = parseInt(optOutReason, 10);
        const otherReasonId = therapyClinicalDispensingReasons.find(e => e.label === 'Other').value;
        if (optOutReasonInt === otherReasonId) {
          let otherReason = 'Other';
          if (clinicalSupportAdditionalReason) {
            otherReason += ` (${clinicalSupportAdditionalReason})`;
          }
          return otherReason;
        }
        return therapyClinicalDispensingReasons.find(e => e.value === optOutReasonInt)?.label; // eslint-disable-line
      })
      .join(', ')}`;

  const renderEditAdministration = () => (
    <Grid container>
      <Grid item xs={2}>
        <Typography className={classes.statusTitle} variant="h6" color="primary">
          Administration
        </Typography>
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        <Typography variant="caption">
          <Button
            name="edit_administration_button"
            onClick={toggleEditAdministration}
            className={classes.toggleEdit}
          >
            <EditPencilMd id="editAdministrationDetailPencil" />
          </Button>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EditAdministration
          therapy={therapy}
          medication={medication}
          tagTypeId={tagTypeId}
          tagResourceId={therapy.id}
          adminStatus={adminStatus}
          toggleCallback={toggleEditAdministration}
        />
      </Grid>
    </Grid>
  );

  const renderEditEnrollService = () => (
    <Grid container>
      <Grid item xs={2}>
        <Typography className={classes.statusTitle} variant="h6" color="primary">
          Service Enrollment
        </Typography>
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        <Typography variant="caption">
          <Button
            onClick={toggleEditEnrollService}
            name="edit_service_button"
            className={classes.toggleEdit}
          >
            <EditPencilMd id={`editServiceEnrollmentDetailPencil_${index}`} />
          </Button>
        </Typography>
      </Grid>
      <EditEnrollment
        therapy={therapy}
        toggleCallback={toggleEditEnrollService}
        therapyLabel={therapyLabel}
      />
    </Grid>
  );
  const combinedTasks = getCombinedTasks(therapy, true);
  const isArchived = isTherapyArchived(therapy);
  const isSpecialty = Boolean(therapy.is_specialty);
  const inMedSync = Boolean(therapy.in_med_sync);
  const categorySource = medicationCategoryCodes.find(
    category => category.code === (therapy.source || 'patientspecified'),
  ).category;

  let formattedDispensingPharmacyName = dispensingPharmacyName;
  if (dispensingPharmacyDoingBusinessAs) {
    formattedDispensingPharmacyName += ` - ${dispensingPharmacyDoingBusinessAs}`;
  }

  let formattedTransferPharmacyName = transferPharmacyName;
  if (transferPharmacyDoingBusinessAs) {
    formattedTransferPharmacyName += ` - ${transferPharmacyDoingBusinessAs}`;
  }
  let anticipatedDuration;
  if (therapyDuration && startDate) {
    anticipatedDuration = moment(startDate).add(therapyDuration, 'days').format('MM/DD/YYYY');
  }

  let therapyDurationDisplayValue;
  if (therapyDuration) {
    therapyDurationDisplayValue =
      therapyDuration % 7 === 0 ? `${therapyDuration / 7} weeks` : `${therapyDuration} days`;
  } else {
    therapyDurationDisplayValue = '-';
  }

  const renderTherapyDetailFields = () => (
    <Grid container>
      <Grid item xs={12} className={classes.dataContainer}>
        <Grid container>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <DetailField
                  fieldName="Prescribing Clinic"
                  field={`${referringClinicName || '-'}`}
                />
              </Grid>
              <Grid item xs={4}>
                <DetailField fieldName="Prescriber" field={prescriberName} variant="element" />
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="row" columnSpacing={1}>
                  {windowFeatureIsEnabled('med_sync') && inMedSync ? (
                    <Grid
                      item
                      xs="auto"
                      alignItems="flex-end"
                      className={classes.medSyncIconPadding}
                    >
                      <MedSyncIcon />
                    </Grid>
                  ) : null}
                  <Grid item data-qa-id="therapy_needs_by_date">
                    <DetailField
                      fieldName="Needs By Date"
                      variant="element"
                      component="div"
                      field={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Grid container alignItems="center">
                          {convertToArborDate(needsbyDate, true).getUtcDate(true)}
                          {!!perProtocol && <PerProtocolIcon />}
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isSpecialty && (
                <Grid item xs={4}>
                  <DetailField fieldName="Source" field={`${categorySource}`} />
                </Grid>
              )}
              {isSpecialty && (
                <Grid item xs={4}>
                  <DetailField fieldName="Managing Clinic" field={`${managingClinicName || '-'}`} />
                </Grid>
              )}
              <Grid item xs={4}>
                <DetailField
                  fieldName="Service Group"
                  field={`${serviceGroupsDisplayName || '-'}`}
                />
              </Grid>
              {isSpecialty && (
                <Grid item xs={4}>
                  <DetailField
                    fieldName="Referral Date"
                    field={convertToArborDate(referralDt, true).getCustomerDate(true) || '-'}
                  />
                </Grid>
              )}
              <Grid item xs={4} className={showIcdIndicator ? classes.icdValidator : ''}>
                <DetailField
                  fieldName="Primary ICD-10 Code"
                  field={`${diagnosisCode || ''} - ${diagnosisName || ''}`}
                />
                {showIcdIndicator && (
                  <Tooltip
                    title={
                      <>
                        <Typography>This therapy is paired with a</Typography>
                        <Typography>NON RS ICD code</Typography>
                      </>
                    }
                    placement="right-start"
                  >
                    <InfoIcon className={classes.icdValidatorIcon} />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={4}>
                <DetailField
                  fieldName="Secondary ICD-10 Code"
                  field={`${secondaryDiagnosisCode || ''} - ${diagnosisSecondaryName || ''}`}
                />
              </Grid>
              <Grid item xs={4}>
                <DetailField
                  fieldName="Tertiary ICD-10 Code"
                  field={`${tertiaryDiagnosisCode || ''} - ${diagnosisTertiaryName || ''}`}
                />
              </Grid>
              <Grid item xs={4}>
                <DetailField
                  fieldName="Transfer From Pharmacy"
                  field={formattedTransferPharmacyName}
                />
              </Grid>
              <Grid item xs={4}>
                <DetailField
                  fieldName="Dispensing Pharmacy"
                  field={formattedDispensingPharmacyName}
                  tooltipComponentFront={
                    dispensingPharmacyTherapy ? (
                      <Tooltip
                        placement="bottom"
                        title={
                          <>
                            <Grid item xs={12}>
                              <Grid container>
                                <Typography variant="caption">Phone:</Typography>
                              </Grid>
                              <Grid container>
                                <Typography variant="body2">
                                  {dispensingPharmacyTherapy.phone_number || '-'}
                                </Typography>
                              </Grid>
                            </Grid>
                            <hr />
                            <Grid item xs={12}>
                              <Grid container>
                                <Typography variant="caption">Address:</Typography>
                              </Grid>
                              <Grid container>
                                <Typography variant="body2">
                                  {dispensingPharmacyTherapy.address || '-'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        }
                      >
                        <InfoIcon className={classes.dispensingPharmacyInfoIcon} />
                      </Tooltip>
                    ) : null
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <DetailField fieldName="Days Supply" field={`${daysSupply || '-'}`} />
              </Grid>
              <Grid item xs={4}>
                <DetailField fieldName="Therapy Duration" field={therapyDurationDisplayValue} />
              </Grid>
              {anticipatedDuration && (
                <Grid item xs={4}>
                  <DetailField
                    fieldName="Anticipated Completion Date"
                    field={`${anticipatedDuration || '-'}`}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item xs={12}>
              <Grid container className={classes.note}>
                <NoteDisplay
                  patientId={patient.id}
                  tagName={therapy.drug_name}
                  tagTypeId={tagTypeId}
                  tagResourceId={therapy.id}
                  notes={displayNotes}
                  containerClasses={classes}
                  therapy={therapy}
                  isInContext
                  patient
                />
              </Grid>
              <DocumentDisplay
                taskIdResourceIds={taskIdsResourceIds()}
                tagTypeId={tagTypeId}
                resourceId={therapy.id}
                drugName={drugName}
                tagTypeLabel={tagTypeLabel}
                documents={displayDocuments}
              />
              <ActionRow
                idPrefix={`therapy_${therapy.id}`}
                therapy={therapy}
                tagTypeId={tagTypeId}
                tagResourceId={therapy.id}
                cancel={handleCancel}
                defaultServiceGroup={selectedServiceGroup}
                serviceGroups={serviceGroups}
                taskType="therapies"
              />
            </Grid>
            {windowFeatureIsEnabled('copy_therapy') &&
              therapyLabel === SpecialtyTherapiesWf.SP &&
              ![AdministrationStatus.Discontinued, AdministrationStatus.NoGo].includes(
                therapy.administration_status,
              ) && (
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      setCopyTherapy(true);
                    }}
                    variant="outlined"
                    className={classes.therapyFilterMedsButton}
                  >
                    <PatientOverviewCopy size={20} className={classes.copyIcon} />
                    <span className={classes.therapyFilterButtonText}>copy therapy</span>
                  </Button>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const categoryId = therapyEnrollmentStatusCategories[therapyEnrollmentStatusCategory];

  const getTherapyTypeTag = () => {
    if (!therapyLabel) {
      return <></>;
    }
    return (
      <Typography
        component="span"
        key={`${therapy?.id}-type`}
        className={cx(classes.specialtyTag, therapyLabel)}
      >
        {therapyLabel}
      </Typography>
    );
  };

  const getIndicatorIfPRN = () => {
    if (!therapy?.as_needed) {
      return <></>;
    }
    return <PRNIcon className={classes.prnIcon} />;
  };

  const getIndicatorIfCM = () => {
    if (!therapy.controlled_substance_code) {
      return <></>;
    }

    return getCMIcon(therapy?.controlled_substance_code, classes.prnIcon);
  };

  const getIndicatorIfCA = () => {
    if (!therapy?.clinic_administered) {
      return <></>;
    }
    return <CAIcon className={classes.prnIcon} />;
  };

  const getIndicatorIfDS = () => {
    if (!therapy?.drop_ship_med) {
      return <></>;
    }
    return <DSIcon className={classes.prnIcon} />;
  };

  const tags = [];
  if (window.FEATURES.therapy_show_non_specialty) {
    tags.push(getTherapyTypeTag());
  }
  tags.push(getIndicatorIfPRN());
  tags.push(getIndicatorIfCA());
  tags.push(getIndicatorIfCM());
  tags.push(getIndicatorIfDS());
  if (
    audit &&
    audit.therapies &&
    audit.therapies.some(
      auditVar =>
        auditVar.resources.therapy_id === therapy.id &&
        auditVar.resolution_state.name === AUDIT_NOT_RESOLVED,
    )
  ) {
    tags.push(
      <Audit
        iconType="warning"
        auditRules={therapyAudits}
        context={auditContext.Warning}
        resource="therapy_id"
        resourceValue={therapy.id}
      />,
    );
  }
  const subheader = formatTherapyNameLong(
    drugName,
    therapy.dosage_form,
    therapy.strength,
    therapy.strength_unit_of_measure,
    therapy.dosage_form_description,
    medication && medication.is_active ? therapy.ndc : '',
  );

  return (
    <div>
      <Accordion
        className={classes.accordianPanel}
        elevation={1}
        expanded={!!displayDetail}
        data-qa-id={`therapy-card-${therapy?.ndc}`}
        id={`therapy-${therapy.id}`}
      >
        <AccordionSummary
          className={classes.therapySummary}
          classes={{
            content: classes.therapySummaryContent,
            expandIconWrapper: displayDetail
              ? classes.expandIconExpanded
              : classes.expandIconCollapsed,
            focusVisible: classes.focused,
            root: classes.focusedBackground,
          }}
          onClick={handleClick}
          expandIcon={!displayEdit ? <ExpandMoreIcon id={`therapy-expand-${therapy.id}`} /> : null}
        >
          <div className={classes.summaryContainer}>
            {!displayEdit && (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={cx(
                    classes.headerContainer,
                    isArchived ? classes.headerContainerArchived : null,
                  )}
                >
                  <AccordionHeader
                    name={drugName}
                    updatedDt={therapy.updated}
                    updatedBy={getUserById(therapy.updated_user_id, users)}
                    editHandler={handleEdit}
                    editID={`therapyEditPencil_${index}`}
                    id={`therapy_${therapy.id}`}
                    subheader={subheader}
                    tooltip={
                      medication && !medication.is_active && therapy.is_specialty && therapy.ndc
                    }
                    withHistory
                    historyHandler={handleEditHistoryModal}
                    index={index}
                    tags={tags}
                  />
                  <Grid container>
                    <Grid
                      item
                      xs
                      className={classes.dosisRegimenField}
                      data-qa-id={qaIdBuilder('grid')}
                    >
                      <DetailField
                        fieldName="Dosing Regimen"
                        field={`${dosisRegimen || '-'}`}
                        variant="raw"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ ml: 2.5 }} justifyContent="center" alignItems="center">
                    <Grid item xs>
                      {renderOutstandingTasks()}
                    </Grid>
                    <Grid item xs className={classes.taskLinkContainer}>
                      <TaskLinks isTherapy links={therapy.links} />
                    </Grid>
                    <Grid item className={classes.statusContainer}>
                      {adminStatus && (
                        <TherapyStatus
                          categoryId={therapyStatusCategories[adminStatus.status]}
                          status={adminStatus.status}
                          reason={adminStatus.reason}
                          id={`therapyStatus_${index}`}
                        />
                      )}
                    </Grid>
                    <Grid item className={classes.statusContainer}>
                      {enrollmentStatus && (
                        <TherapyEnrollmentStatus
                          categoryId={categoryId}
                          dispensingStatusId={dispensingStatusId}
                          clinicalSupportStatusId={clinicalSupportStatusId}
                          status={therapyEnrollmentStatusCategory}
                          isAncillaryMedTherapyOptInOutDispense={isAncillaryMedTherapyOptInOutDispense()}
                          id={`therapyEnrollmentStatus_${index}`}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Modal open={editHistoryModalOpen} onClose={handleEditHistoryModal}>
                  <div style={getModalStyle()} className={classes.Modal}>
                    <History url={getHistoryURL(patient.id, therapy.id)} />
                  </div>
                </Modal>
                {!isArchived && renderTherapyDetailFields()}
                {copyTherapy && (
                  <CopyTherapy
                    therapyIds={[therapy.id]}
                    filterTasks={undefined}
                    onClose={() => {
                      setCopyTherapy(false);
                    }}
                    classes={classes}
                  />
                )}
              </Grid>
            )}
            {displayEdit && (
              <Grid container className={classes.editTherapyContainer} onClick={stopPropagation}>
                <Grid item xs={12} onClick={stopPropagation}>
                  <AccordionHeader
                    name={drugName}
                    updatedDt={therapy.updated}
                    updatedBy={getUserById(therapy.updated_user_id, users)}
                    editHandler={handleEdit}
                    id={`therapy_${therapy.id}_edit_mode`}
                    tags={tags}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditPatientTherapy
                    editable={displayEdit}
                    therapy={therapy}
                    medication={medication}
                    cancel={handleCancel}
                    isTaskSidebar={isTaskSidebar}
                    initialTherapyName={initialEditTherapy}
                    addTherapyHandler={addTherapyHandler}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </AccordionSummary>

        {!displayEdit && displayDetail && (
          <AccordionDetails className={classes.therapyDetails}>
            {isArchived && renderTherapyDetailFields()}
            <Grid container>
              <Grid
                item
                xs={12}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <Divider className={classes.dividerStrech} />
                {editMode ? (
                  <div className={classes.actionFormContainer}>
                    {editAdministration && renderEditAdministration()}
                    {editEnrollService && renderEditEnrollService()}
                  </div>
                ) : (
                  <Grid container>
                    <Grid item xs className={cx(classes.actionContainer, classes.dividerRight)}>
                      <TherapyAdministration
                        adminStatus={adminStatus}
                        adminStatusDisplay={adminStatusDisplay}
                        startDate={startDate}
                        discontinuedDate={discontinuedDate}
                        recheckDate={recheckDate}
                        restartDate={restartDate}
                        noGoDate={noGoDate}
                        toggleEditAdministration={toggleEditAdministration}
                        therapy={therapy}
                        medication={medication}
                        index={index}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={window.FEATURES.benefits_investigations ? 4 : 5}
                      className={cx(classes.actionContainer, classes.dividerRight)}
                    >
                      <TherapyEnrollment
                        enrollmentStatusDisplay={enrollmentStatusDisplay}
                        dispensingStatus={dispensingStatus}
                        dispensingStatusId={dispensingStatusId}
                        dispensingStatusReason={dispensingStatusReason}
                        getDispensingOptOutReasons={getDispensingOptOutReasons}
                        clinicalSupportStatus={clinicalSupportStatus}
                        clinicalSupportStatusId={clinicalSupportStatusId}
                        clinicalSupportStatusReason={clinicalSupportStatusReason}
                        getClinicalSupportOptOutReasons={getClinicalSupportOptOutReasons}
                        toggleEditEnrollService={toggleEditEnrollService}
                        enrollmentStatuses={enrollmentStatuses}
                        isSpecialty={isSpecialty}
                        therapy={therapy}
                        index={index}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={window.FEATURES.benefits_investigations ? true : 4}
                      className={cx(classes.actionContainer, {
                        [classes.dividerRight]: window.FEATURES.benefits_investigations,
                      })}
                    >
                      <TherapyFillCycle therapy={therapy} idPrefix={`therapyFillCycle_${index}`} />
                    </Grid>
                    {window.FEATURES.benefits_investigations && (
                      <Grid item xs className={classes.actionContainer}>
                        <TherapyBenefitsInvestigation
                          benefitInvestigation={latestBenefitInvestigation}
                          patient={patient}
                          therapy={therapy}
                          pbmInsurances={groupedPbmInsurances}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} align="right" className={classes.editTherapyContainer}>
                <Button
                  className={classes.newTaskBtn}
                  name="add_task_button"
                  id={`AddTaskTherapyDetailButton_${index}`}
                  variant="contained"
                  onClick={handleAddClick}
                  disabled={taskInactive}
                >
                  {EditAdd()}
                  <span className={classes.newTaskBtnLabel}>Task</span>
                </Button>
              </Grid>
              {displayDetail && (
                <Grid item xs={12}>
                  <TherapyTaskList
                    drugName={drugName}
                    className={classes.therapyTaskList}
                    therapy={therapy}
                    combinedTasks={combinedTasks}
                    therapyId={therapy.id}
                    update={handleClick}
                    selectedTaskType={selectedTaskType}
                    selectedTask={selectedTask}
                    isTaskSidebar={isTaskSidebar}
                    defaultServiceGroup={selectedServiceGroup}
                    taskStatusCategoriesToDisplay={taskStatusCategoriesToDisplay}
                    displayAddForm={displayAddForm}
                    handleCancelClick={handleCancelClick}
                    archivedExpanded={archivedExpanded}
                    therapyIndex={index}
                    handleArchivedClick={handleArchivedClick}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
      <LargeSpacer />
    </div>
  );
};

function mapStateToProps(state, props) {
  // Convert tasks array to object
  const {
    patient,
    notes,
    uploadedDocuments,
    therapyStatuses,
    lookups,
    financials,
    medications,
    therapyBenefitsInvestigationLatest,
    links,
    audit,
  } = state;
  const { therapy } = props;
  const { medicationGroups } = medications;
  return {
    patient,
    audit,
    medication: Array.isArray(medicationGroups)
      ? medicationGroups.find(m => m.id === therapy.medication_id)
      : null,
    notes: notes && notes.notes ? notes.notes.allNotes : [],
    documents: uploadedDocuments.documents || [],
    allPinnedNotes: notes && notes.notes ? notes.notes.allPinnedNotes : [],
    allNonPinnedNotes: notes && notes.notes ? notes.notes.allNonPinnedNotes : [],
    therapyStatuses,
    financials,
    taskStatuses: state.taskStatuses.statuses,
    taskCounts: state.taskCounts.data,
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
    enrollmentStatuses: lookups.enrollmentStatuses,
    pbmInsurances: financials.pbm_insurances.data,
    latestBenefitInvestigation: therapyBenefitsInvestigationLatest.data[therapy.id],
    links: links.data,
    medicationCategoryCodes: state.lookups.medicationCategoryCodes,
  };
}

const combinedStyles = combineStyles(styles, accordianStyles);

export default compose(
  withStyles(combinedStyles, { withTheme: true }),
  connect(mapStateToProps, {
    updateNoteBar,
    fetchNotesSidebar,
    fetchGpis,
  }),
  createLazyOnscreenRender,
)(TherapyDetail);
