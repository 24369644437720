import { IPatientMergeInput } from 'actions/action-patient-merge';
import { LookupResourceTypes } from 'constants/enums';
import { ISection, ISectionDetailsProps } from '../section/interfaces';

export interface IPatientMergeModalRef {
  setOpen(value: boolean): void;
}

export interface IPatientProfile {
  patientId: number;
  source: {
    id: string;
    name: string;
  };
  sourcePatientId: string;
  active: boolean;
  status: number;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  gender?: string;
  dob?: Date;
  pronouns?: string;
  primary?: boolean;
  duplicate?: boolean;
  is_manually_created: boolean;
}

export interface IPatientMergeModalProps {
  primaryProfile?: number;
  profiles: IPatientProfile[];
  defaultInputs: IPatientMergeInput[];
  mergeId: number;
}

export interface IPatientMergeProps {
  mergeId: number;
  profiles: IPatientProfile[];
}

export type IPatientMergeSectionDetailsProps = ISectionDetailsProps<IPatientMergeProps> & {
  sectionName?: string;
  patientDocuments?: IDocumentInfo[];
};
export interface IStepProps extends IPatientMergeProps {
  onSectionChange?: (section: ISection<IPatientMergeProps>) => Promise<void>;
  setNextButtonEnabled: (enabled: boolean) => void;
}

export interface IStep {
  title: string;
  render: (props: IStepProps) => JSX.Element;
  renderNextButtonText: () => string;
}

export interface IAddress {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  zip?: string;
  use?: string;
  preferred?: boolean;
}

export interface IPhone {
  period?: string;
  value?: string;
  use?: string;
  preferred?: boolean;
  key?: number;
  rank?: number;
}

export interface IEmail {
  use?: string;
  value?: string;
  enrollment?: string;
  preferred?: boolean;
  key?: number;
  rank?: number;
  deleted?: boolean;
}

export interface IContact {
  contactId: number;
  isResponsible?: boolean;
  isEmergency?: boolean;
  isAdditional?: boolean;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  nickname?: string;
  relationship?: string;
  languages: { label: string; value: string }[];
  dob: Date;
  addresses?: IAddress[];
  phones: IPhone[];
  emails: IEmail[];
}

export interface IPatientContact {
  id: number;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  nickname?: string;
  dob: Date;
  addresses?: IAddress[];
  languages: { label: string; value: string }[];
  phones: IPhone[];
  emails: IEmail[];
}

export interface IPaymentMethod {
  id: number;
  cardNum: string;
  paymentType: number;
}

export interface IPreference {
  id: number;
  preferenceId: number;
  preferredContactPatient: boolean;
  preferredContactId?: number;
  preferredContactMethod?: number;
  contactMethodNote?: string;
  preferredRxDeliveryPatient: boolean;
  preferredRxDeliveryContactId?: number;
  preferredRxDeliveryMethod?: number;
  rxDeliveryNote?: string;
  easyOpenCaps: boolean;
  adherencePackaging: boolean;
  paymentMethod: IPaymentMethod;
}

export interface IProfileDemographicInfo {
  patientId: number;
  patient: IPatientContact;
  contacts: IContact[];
  preference: IPreference;
}

export interface IPatientMerge {
  id: number;
  status: string;
  profiles: IPatientProfile[];
  inputs: IPatientMergeInput[];
}

export interface IPreferenceDemographicInfoProps {
  preference: IPreference;
  patient: IPatientContact;
  contacts: IContact[];
}

export interface IProfileTherapy {
  id: number;
  patientId: number;
  customerId: number;
  leadSourceId: number;
  rxTypeId: number;
  referringClinicNp: string;
  referringClinicName: string;
  referringClinicInternalId: number;
  managingClinicId: number;
  externalDispensingReason: number;
  externalDispensingAdditionalReason: string;
  ndc: string;
  followupDt: Date;
  additionalReason: string;
  startDate: Date;
  referralDt: Date;
  dosisRegimen: string;
  lastFilledDate: Date;
  discontinuedDate: Date;
  serviceGroupId: number;
  dispensingPharmacyNpi: string;
  dispensingPharmacyName: string;
  dispensingPharmacyDoingBusinessAs: string;
  transferPharmacyNpi: string;
  transferPharmacyName: string;
  transferPharmacyDoingBusinessAs: string;
  recheckDate: Date;
  restartDate: Date;
  noGoDate: Date;
  diagnosisCode: string;
  secondaryDiagnosisCode: string;
  tertiaryDiagnosisCode: string;
  prescribingPhysicianNpi: string;
  prescribingPhysicianName: string;
  enrollmentStatusId: number;
  clinicalSupportStatusId: number;
  clinicalSupportStatusReason: number;
  clinicalSupportAdditionalReason: string;
  administrationStatusAdditionalReason: string;
  dispensingStatusId: number;
  administrationStatusId: number;
  medicalInsurance: boolean;
  pharmacyInsurance: boolean;
  medicationId: number;
  isSpecialty: boolean;
  therapyType: number;
  therapyDuration: number;
  inMedSync: boolean;
  hideFromUi: boolean;
  created: Date;
  createdBy: number;
  updated: Date;
  updatedBy: number;
  revision: number;
  managingClinicNpi: string;
  managingClinicName: string;
  enrollmentStatus: string;
  enrollmentStatusReason: string;
  dispensingStatus: string;
  clinicalSupportStatus: string;
  administrationStatus: string;
  administrationStatusCategoryId: number;
  administrationStatusReason: string;
  diagnosisDescription: string;
  secondaryDiagnosisDescription: string;
  tertiaryDiagnosisDescription: string;
  gpi: string;
  drugName: string;
  dosageForm: string;
  dosageFormDescription: string;
  strength: string;
  strengthUnitOfMeasure: string;
  prescribingPhysicianBusinessPracticeNumber: string;
  prescribingPhysicianAuthorizedNumber: string;
  prescribingPhysicianBusinessMailingNumber: string;
  prescribingPhysicianCredential: string;
  serviceGroupsName: string;
  serviceGroupsDisplayName: string;
  needsbyDate: Date;
  fillCycle: number;
  isNeedsbyPerProtocol: boolean;
  daysSupply: number;
  source: string;
  updatedUserId: number;
  coldChainPackingRequired: boolean;
  specialtyType: string;
}

export interface IAllergy {
  allergyId: number;
  allergyName: string | null;
  allergyUpdatedAt: Date | null;
  allergyStatus: string | null;
}

export interface IProblem {
  problemId: number;
  problemName: string | null;
  problemUpdatedAt: Date | null;
  isProblemActive: boolean | null;
}

export interface ISpecialPopulation {
  specialPopulationId: number;
  specialPopulationName: string | null;
  specialPopulationStartDate: Date | null;
  specialPopulationEndDate: Date | null;
  isSpecialPopulationActive: boolean | null;
}

export interface IProfileClinicalInfo {
  patientId: number;
  allergies: IAllergy[];
  problems: IProblem[];
  specialPopulations: ISpecialPopulation[];
}

export interface IDocumentInfo {
  id: number;
  patientId: number;
  filename: string;
  createdByUser: string;
  labels: string[];
  tagDisplay: string[];
  tags: { id: number; tag_type_id: number }[];
  created: Date;
}

export interface IAddressDemographicInfoProps {
  address: IAddress;
}

export interface IEmailDemographicInfoProps {
  email: IEmail;
}

export interface IPhoneDemographicInfoProps {
  phone: IPhone;
}

export interface IContactDemographicInfoProps {
  contact: IContact;
  keyPrefix: string;
}

export interface ITherapyInfoProps {
  therapy: IProfileTherapy;
}

export interface IDocumentInfoProps {
  document: IDocumentInfo;
}

export interface IPatientGenderLabelProps {
  profile: IPatientProfile;
}

export interface ILis {
  lisLevel?: string | null;
  lisMedicareBeneficiaryId?: number | null;
  lisDateOfService?: Date | null;
  lisCmsLowIncomeCostSharing?: string | null;
  lisEffectiveDate?: Date | null;
  lisEffectiveDateForMbi?: Date | null;
  lisContractNumber?: string | null;
  lisTransactionResponseStatus?: string | null;
  lisTerminationDate?: Date | null;
  lisBenefitId?: string | null;
  lisMedicarePartDCode?: string | null;
  lisNextMedicarePartDCode?: string | null;
  lisMedicarePlanType?: string | null;
  lisFormularyId?: string | null;
  lisOtherPayorIdCount?: string | null;
  lisNextMedicarePartDEffectiveStart?: Date | null;
}

export interface IPolicyHolder {
  policeHolderName?: string | null;
  planSponsor?: number | null;
  relationshipToPatient?: string | null;
  policeHolderDateOfBirth?: Date | null;
}

export interface IUnemployment {
  unemploymentIncome?: number | null;
  unemploymentIncomePeriod?: string | null;
  spouseSSI?: number | null;
  unemploymentStartDate?: Date | null;
  unemploymentEndDate?: Date | null;
  patientSsi?: number | null;
  patientSsiIncomePeriod?: string | null;
  dependentSsi?: number | null;
  dependentSsiIncomePeriod?: string | null;
}

export interface IRetirement {
  pension401kRetirementIncome?: number | null;
}

export interface ILongTermDisability {
  longTermDisabilityIncome?: number | null;
  longTermDisabilityCompany?: string | null;
  longTermDisabilityIncomePeriod?: string | null;
  numberOfDependants: number;
  familySize: number;
}

interface IGenericInsurance {
  name: string | null;
  startDate: Date | null;
  endDate: Date | null;
  type: string | null;
}

export interface IPharmacyBenefit extends IGenericInsurance {
  id: number;
  processorControlNumber?: string | null;
  pbmPhoneNumber?: number | null;
  pbmPatientId?: number | null;
  pbmBinNumber?: number | null;
  pbmRxGroupNumber?: string | null;
  pbmRelationCode?: string | null;
  lis: ILis;
}

export interface IMedicalBenefit extends IGenericInsurance {
  id: number;
  medicalPlanPhoneNumber?: number | null;
  groupId?: string | null;
  memberId?: string | null;
  policyHolder: IPolicyHolder;
}

export interface IFinancialAssistance {
  id: number;
  drugName: string | null;
  expirationDate: Date | null;
  remainingBalance: number | null;
  status: boolean;
}

export interface IIncome {
  id: number;
  taxableIncome?: number | null;
  grossBusinessIncome?: number | null;
  extensionFiled?: number | null;
  transcriptTaxFormCompleted?: number | null;
  year: number;
  isIncomeVerified: boolean;
  unemployment: IUnemployment;
  retirement: IRetirement;
  longTermDisability: ILongTermDisability;
  family_size?: number | null;
  income_year?: number | null;
  taxable_income?: number | null;
}

export interface IProfileIncomingBenefitsInfo {
  patientId: number;
  pbmInsurances: IPharmacyBenefit[];
  medicalInsurances: IMedicalBenefit[];
  financialAssistances: IFinancialAssistance[] | null;
  incomes: IIncome[] | null;
}

export interface ITask {
  id: number;
  taskType: {
    name: string;
    id: number;
  };
  status: {
    name: string;
    id: number;
  };
  specialty_diagnosis_id?: number;
  managing_clinic_id?: number;
  followUpDate: Date;
  createdDate: number;
  typeName?: string;
}

export interface IPatientMergeTask {
  patientId: number;
  tasks: ITask[];
}
export type ISectionWithIdentifier<T> = ISection<T> & {
  identifier?: string;
};

export enum PatientMergeSectionIdentifiers {
  Demographics = 'Demographics',
  IncomeAndBenefitsSection = 'IncomeAndBenefitsSection',
  Clinical = 'Clinical',
  Therapies = 'Therapies',
  PatientLevelTasks = 'PatientLevelTasks',
  Documents = 'Documents',
}

export const PatientMergeSectionItems: { [key: string]: number[] } = {
  [PatientMergeSectionIdentifiers.Demographics]: [
    LookupResourceTypes.PatientEmail,
    LookupResourceTypes.PatientPhone,
    LookupResourceTypes.PatientContactPhone,
    LookupResourceTypes.PatientContactEmail,
    LookupResourceTypes.PatientContactAddress,
    LookupResourceTypes.PatientAddress,
  ],

  [PatientMergeSectionIdentifiers.IncomeAndBenefitsSection]: [
    LookupResourceTypes.PharmacyInsurance,
    LookupResourceTypes.MedicalInsurance,
    LookupResourceTypes.FinancialAssistance,
    LookupResourceTypes.Income,
  ],

  [PatientMergeSectionIdentifiers.Clinical]: [
    LookupResourceTypes.Allergy,
    LookupResourceTypes.PatientProblem,
    LookupResourceTypes.PatientSpecialPopulation,
  ],
  [PatientMergeSectionIdentifiers.PatientLevelTasks]: [
    LookupResourceTypes.Ar,
    LookupResourceTypes.Csl,
    LookupResourceTypes.Dc,
    LookupResourceTypes.Dur,
    LookupResourceTypes.Fa,
    LookupResourceTypes.Fc,
    LookupResourceTypes.Fdc,
    LookupResourceTypes.Int,
    LookupResourceTypes.Mr,
    LookupResourceTypes.Or,
    LookupResourceTypes.Pa,
    LookupResourceTypes.Qre,
    LookupResourceTypes.Tpr,
    LookupResourceTypes.Rs,
  ],
  [PatientMergeSectionIdentifiers.Therapies]: [LookupResourceTypes.Therapy],
  [PatientMergeSectionIdentifiers.Documents]: [LookupResourceTypes.Document],
};

export function findSectionByTagType(value: number) {
  for (const key in PatientMergeSectionItems) {
    if (PatientMergeSectionItems[key].includes(value)) {
      return key;
    }
  }
  return undefined;
}
