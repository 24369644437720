import { takeLatest, select, call, put } from 'redux-saga/effects';
import {
  SELECTED_PATIENT_ID,
  DATA_LINKS_REQUEST,
  DATA_LINKS_SUCCESS,
  DATA_LINKS_FAILURE,
  BULK_TASKS_UPDATED,
  FETCH_RESOURCE_LINK,
  BULK_TASKS_UPDATE_REQUEST,
} from '../constants';
import HTTP from '../services/http';

function* workerFetchLinks() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/links`;
    const request = HTTP.get(url);
    yield put({
      type: DATA_LINKS_REQUEST,
      payload: request,
    });
    try {
      const response = yield call(() => request);
      yield put({ type: DATA_LINKS_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: DATA_LINKS_FAILURE, payload: e });
    }
  }
}

export function* watcherLinksSaga() {
  yield takeLatest(
    [SELECTED_PATIENT_ID, BULK_TASKS_UPDATED, FETCH_RESOURCE_LINK, BULK_TASKS_UPDATE_REQUEST],
    workerFetchLinks,
  );
}
